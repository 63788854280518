<template>
  <ApolloQuery :query="GET_TIMESHEET" :variables="{ id: id }">
    <template slot-scope="{ result: { data }, isLoading }">
      <div class="layout has-sidebar">
        <div class="layout-header">
          <h1>Timesheet</h1>
        </div>
        <div class="layout-content">
          <div class="loader full" v-if="isLoading"></div>
          <div v-else class="columns">
            <div class="column is-narrow is-max-full">
              <div class="data-table-scroll">
                <table class="data-table">
                  <tr>
                    <th>User</th>
                    <td>
                      <router-link
                        :to="{
                          name: 'view-user',
                          params: {
                            id: data.timesheet.participation.user.id,
                          },
                        }"
                        class="font-medium hover:text-blue"
                      >
                        {{ data.timesheet.participation.user.first_name }}
                        {{ data.timesheet.participation.user.last_name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>Project</th>
                    <td>
                      <router-link
                        :to="{
                          name: 'view-project',
                          params: {
                            id: data.timesheet.participation.project.id,
                          },
                        }"
                        class="font-medium hover:text-blue"
                      >
                        <strong>{{ data.timesheet.participation.project.display_code }}</strong>
                        {{ data.timesheet.participation.project.name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <td>{{ data.timesheet.date | date }}</td>
                  </tr>
                  <tr>
                    <th>Hours</th>
                    <td>{{ data.timesheet.hours | hours(2) }}</td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td>
                      <blockquote class="description">
                        {{ data.timesheet.description }}
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <th>Editable</th>
                    <td>
                      <boolean :value="data.timesheet.editable"></boolean>
                    </td>
                  </tr>
                  <tr>
                    <th>Worked from home</th>
                    <td>
                      <boolean :value="data.timesheet.fromHome"></boolean>
                    </td>
                  </tr>

                  <tr>
                    <th>Created at</th>
                    <td>{{ data.timesheet.created_at | datetime }}</td>
                  </tr>

                  <tr>
                    <th>Last updated</th>
                    <td>{{ data.timesheet.updated_at | datetime }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="layout-sidebar" v-if="!isLoading && data.timesheet.editable">
          <ul>
            <li v-if="!isLoading && data.timesheet.editable">
              <router-link
                class="button is-primary"
                :to="{ name: 'edit-timesheet', params: { id: id } }"
              >
                Edit
              </router-link>
            </li>
            <li v-if="!isLoading && data.timesheet.editable">
              <delete-timesheet-button
                :timesheet="data.timesheet"
                class="button is-danger"
                @done="deleted()"
              />
            </li>
          </ul>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import Boolean from '@/components/Boolean.vue';
import DeleteTimesheetButton from '@/components/DeleteTimesheetButton';
import { GET_TIMESHEET } from '@/graphql/queries/timesheet';

export default {
  name: 'Timesheet',
  components: {
    Boolean,
    DeleteTimesheetButton,
  },
  props: ['id'],
  data() {
    return {
      GET_TIMESHEET,
    };
  },
  methods: {
    async deleted() {
      await this.$router.push('/timesheets');
    },
  },
};
</script>

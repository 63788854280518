<template>
  <div class="not-found">
    <h1>This page is not found</h1>
    <router-link :to="{ name: 'dashboard' }">Go back home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
.not-found {
  padding-top: 20vh;
  text-align: center;
}
</style>

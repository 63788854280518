import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    me: false,
  },
  mutations: {
    logged_in_user(state, me) {
      state.me = me;
    },
  },
  getters: {
    me: (state) => state.me,
    havePermission: (state) => (permission) => {
      let capabilities = state.me.capabilities;
      if (!capabilities) return false;

      let capability = capabilities.find((c) => c.name == permission);
      if (!capability) return false;

      return capability.granted;
    },
  },
  actions: {
    async logout({ commit }) {
      commit('logged_in_user', {});
    },
  },
  modules: {},
});

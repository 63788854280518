<template>
  <div class="card mb-6">
    <div class="card-body">
      <slot name="header">
        <h2 class="mb-5">
          <slot name="title" />
        </h2>
      </slot>

      <slot name="body" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
  border: 1px solid #384961;
  border-radius: 4px;
  background: #243143;
}

.card-body {
  padding: 2rem;
}
</style>

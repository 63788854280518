<template>
  <div class="layout has-sidebar">
    <div class="layout-header">
      <h1>Holiday requests</h1>
    </div>
    <div class="layout-content">
      <b-field>
        <b-radio-button v-model="filter" native-value="pending">
          Pending
        </b-radio-button>
        <b-radio-button v-model="filter" native-value="approved">
          Approved
        </b-radio-button>
        <b-radio-button v-model="filter" native-value="archived">
          Archived
        </b-radio-button>
        <b-radio-button v-model="filter" :native-value="null">
          All
        </b-radio-button>
      </b-field>

      <div class="columns">
        <div class="column is-narrow is-max-full is-full">
          <div class="sticky-column-wrapper" v-if="holiday_requests">
            <div class="sticky-column-scroller">
              <table class="sticky-column-table data-table">
                <thead>
                  <th v-if="canApproveHolidays">Requested by</th>
                  <order-column
                    :order.sync="order"
                    @update:order="$apollo.queries.holiday_requests.refetch()"
                    column-name="APPROVED_AT"
                  >
                    Status
                  </order-column>
                  <order-column
                    :order.sync="order"
                    @update:order="$apollo.queries.holiday_requests.refetch()"
                    column-name="START_AT"
                  >
                    Start at
                  </order-column>
                  <order-column
                    :order.sync="order"
                    @update:order="$apollo.queries.holiday_requests.refetch()"
                    column-name="END_AT"
                  >
                    End at
                  </order-column>
                  <order-column
                    :order.sync="order"
                    @update:order="$apollo.queries.holiday_requests.refetch()"
                    column-name="HOUR_SUM"
                  >
                    Hours
                  </order-column>
                  <th>Explanation</th>
                </thead>
                <tr v-for="holidayRequest of holiday_requests.data" :key="holidayRequest.id">
                  <td v-if="canApproveHolidays">
                    <router-link
                      :to="{
                        name: 'view-user',
                        params: { id: holidayRequest.requester.id },
                      }"
                      class="font-medium hover:text-blue"
                    >
                      {{ holidayRequest.requester.first_name }}
                      {{ holidayRequest.requester.last_name }}
                    </router-link>
                  </td>
                  <td>
                    <status
                      :value="holidayRequest.approved_at === null ? 'Pending' : 'Approved'"
                      :options="[
                        { value: 'Pending', color: 'gray' },
                        { value: 'Approved', color: '#05fe79' },
                      ]"
                    />
                  </td>
                  <td>{{ holidayRequest.start_at | date }}</td>
                  <td>{{ holidayRequest.end_at | date }}</td>
                  <td>{{ holidayRequest.hour_sum | hours }}</td>
                  <td class="column-max-width">
                    <span :title="holidayRequest.explanation">
                      {{ holidayRequest.explanation }}
                    </span>
                  </td>
                  <td class="sticky-column">
                    <router-link
                      :to="{
                        name: 'view-holiday-request',
                        params: { id: holidayRequest.id },
                      }"
                    >
                      <open class="icon icon-inline" />
                    </router-link>
                    <approve-holiday-request-button
                      v-if="!holidayRequest.approved_at && canApproveHolidays"
                      :holiday-request="holidayRequest"
                      @done="$apollo.queries.holiday_requests.refetch()"
                    >
                      <check-icon class="icon icon-inline" />
                    </approve-holiday-request-button>
                    <router-link
                      v-if="!holidayRequest.approved_at"
                      :to="{
                        name: 'edit-holiday-request',
                        params: { id: holidayRequest.id },
                      }"
                    >
                      <edit class="icon icon-inline" />
                    </router-link>
                    <delete-holiday-request-button
                      v-if="!holidayRequest.approved_at && canApproveHolidays"
                      :holiday-request="holidayRequest"
                      @done="$apollo.queries.holiday_requests.refetch()"
                    >
                      <delete-icon class="icon icon-inline" />
                    </delete-holiday-request-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <paginator
            v-if="holiday_requests"
            :pageId.sync="pageId"
            :lastPage="holiday_requests.paginatorInfo.lastPage"
          />
        </div>
      </div>
    </div>
    <div class="layout-sidebar">
      <ul>
        <li>
          <router-link class="button is-primary" :to="{ name: 'new-holiday-request' }">
            Create
          </router-link>
        </li>
        <li>
          <holiday-request-filters
            :can-see-other-team-members="canApproveHolidays"
            @update:where="(value) => (where = value)"
          />
        </li>
        <li class="break">
          <HolidayRequestExport :filters="filters" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Paginator from '@/components/Paginator.vue';
import Status from '@/components/Status.vue';
import OrderColumn from '@/components/OrderColumn.vue';
import Open from '@/assets/icons/view.svg';
import Edit from '@/assets/icons/edit.svg';
import DeleteIcon from '@/assets/icons/delete.svg';
import CheckIcon from '@/assets/icons/check.svg';
import HolidayRequestFilters from './holiday-request-filters.modal.vue';
import ApproveHolidayRequestButton from '@/components/ApproveHolidayRequestButton';
import DeleteHolidayRequestButton from '@/components/DeleteHolidayRequestButton';
import HolidayRequestExport from '@/components/HolidayRequestExport';
import { GET_HOLIDAY_REQUESTS } from '@/graphql/queries/holiday_requests';

const filter = (items) => {
  const result = {};
  for (const item in items) {
    if (items[item]) {
      result[item] = items[item];
    }
  }
  return result;
};

export default {
  name: 'HolidayRequests',
  components: {
    Paginator,
    Status,
    OrderColumn,
    Open,
    Edit,
    DeleteIcon,
    CheckIcon,
    HolidayRequestFilters,
    ApproveHolidayRequestButton,
    DeleteHolidayRequestButton,
    HolidayRequestExport,
  },
  data() {
    return {
      holiday_requests: null,
      pageId: 1,
      order: { column: 'START_AT', order: 'ASC' },
      where: { AND: [] },
      filter: 'approved',
    };
  },
  computed: {
    canApproveHolidays() {
      return this.$store.getters.havePermission('approve holidays');
    },
    whereVar() {
      let where = Object.assign({ AND: [] }, this.where);

      where.AND = where.AND.filter((and) => and.column !== 'APPROVED_AT');

      return where;
    },
    filters() {
      const users = this.where.AND?.find(({ column }) => column === 'REQUESTER_ID')?.value;
      const startAt = this.where.AND?.find(({ column }) => column === 'START_AT')?.value;
      const endAt = this.where.AND?.find(({ column }) => column === 'END_AT')?.value;

      return {
        users: users || [],
        startAt: startAt || null,
        endAt: endAt || null,
      };
    },
  },
  apollo: {
    holiday_requests: {
      query: GET_HOLIDAY_REQUESTS,
      variables() {
        return {
          page: this.pageId,
          orderBy: this.order,
          where: this.whereVar,
          ...filter({
            pending: this.filter === 'pending' ? true : null,
            approved: this.filter === 'approved' ? true : null,
            archived: this.filter === 'archived' ? true : null,
          }),
        };
      },
      results({ data }) {
        this.holiday_requests = data.holiday_requests ? data.holiday_requests : null;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.column-max-width {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sticky-column-wrapper {
  position: relative;
}

.sticky-column-scroller {
  margin-right: 140px;
  overflow: auto;
  width: auto;
}

.sticky-column-table {
  width: 100%;

  .sticky-column {
    border-left: 1px solid #243143;
    padding: 0.8rem 0.5rem;
    right: 0;
    position: absolute;
    top: auto;
    width: 140px;
  }
}
</style>

<template>
  <ApolloMutation
    :mutation="isUpdate() ? UPDATE_CLIENT : CREATE_CLIENT"
    :variables="variables"
    @done="onDone"
    @error="onError"
  >
    <template v-slot="{ mutate, loading }">
      <form @submit.prevent="mutate()">
        <fieldset :disabled="loading || $apollo.queries.client.loading">
          <div class="layout has-sidebar">
            <div class="layout-header">
              <h1 v-if="isUpdate()">Edit client</h1>
              <h1 v-else>New client</h1>
            </div>

            <div class="layout-content">
              <div class="columns is-multiline">
                <div class="column info-column">
                  <b-field
                    label="Name"
                    label-for="name"
                    :type="{ 'is-danger': validationErrors['name'] }"
                    :message="validationErrors['name']"
                  >
                    <b-input type="text" id="name" v-model="name" />
                  </b-field>

                  <b-field
                    label="Code"
                    label-for="code"
                    :type="{ 'is-danger': validationErrors['code'] }"
                    :message="validationErrors['code']"
                  >
                    <b-input type="text" id="code" v-model="code" />
                  </b-field>

                  <b-field
                    v-if="isUpdate()"
                    label="Is archived"
                    label-for="is_archived"
                    :type="{ 'is-danger': validationErrors['is_archived'] }"
                    :message="validationErrors['is_archived']"
                  >
                    <b-checkbox id="is_archived" v-model="is_archived">
                      {{ is_archived ? 'yes' : 'no' }}
                    </b-checkbox>
                  </b-field>

                  <h2>Contact person</h2>
                  <b-field
                    label="Name"
                    label-for="contact"
                    :type="{ 'is-danger': validationErrors['contact'] }"
                    :message="validationErrors['contact']"
                  >
                    <b-input type="text" id="contact" v-model="contact" />
                  </b-field>

                  <b-field
                    label="E-mail"
                    label-for="email"
                    :type="{ 'is-danger': validationErrors['email'] }"
                    :message="validationErrors['email']"
                  >
                    <b-input type="email" id="email" v-model="email" />
                  </b-field>

                  <b-field
                    label="Phone"
                    label-for="phone"
                    :type="{ 'is-danger': validationErrors['phone'] }"
                    :message="validationErrors['phone']"
                  >
                    <b-input type="text" id="phone" v-model="phone" />
                  </b-field>

                  <h2>Financial contact person</h2>
                  <b-field
                    label="Name"
                    label-for="finance_contact"
                    :type="{ 'is-danger': validationErrors['finance_contact'] }"
                    :message="validationErrors['finance_contact']"
                  >
                    <b-input type="text" id="finance_contact" v-model="finance_contact" />
                  </b-field>

                  <b-field
                    label="E-mail"
                    label-for="finance_email"
                    :type="{ 'is-danger': validationErrors['finance_email'] }"
                    :message="validationErrors['finance_email']"
                  >
                    <b-input type="email" id="finance_email" v-model="finance_email" />
                  </b-field>

                  <b-field
                    label="Phone"
                    label-for="finance_phone"
                    :type="{ 'is-danger': validationErrors['finance_phone'] }"
                    :message="validationErrors['finance_phone']"
                  >
                    <b-input type="text" id="finance_phone" v-model="finance_phone" />
                  </b-field>
                </div>

                <div class="column notes-column">
                  <h2>Notes</h2>
                  <markdown label="Notes" :editable="true" :text.sync="notes"></markdown>
                </div>
              </div>
            </div>

            <div class="layout-sidebar">
              <ul>
                <li>
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': loading }"
                  >
                    Save
                  </button>
                </li>

                <li v-if="isUpdate()">
                  <router-link
                    :to="{ name: 'view-client', params: { id: id } }"
                    v-if="isUpdate()"
                    class="button is-danger is-outlined"
                  >
                    Cancel
                  </router-link>
                </li>
                <li v-else>
                  <router-link :to="{ name: 'clients' }" class="button is-danger is-outlined">
                    Cancel
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
  </ApolloMutation>
</template>

<script>
import Markdown from '@/components/Markdown.vue';
import { GET_CLIENT } from '@/graphql/queries/clients/client';
import { CREATE_CLIENT } from '@/graphql/mutations/create_client';
import { UPDATE_CLIENT } from '@/graphql/mutations/update_client';

export default {
  name: 'ClientEdit',
  components: {
    Markdown,
  },
  props: ['id'],
  data() {
    return {
      name: '',
      is_archived: false,
      code: '',
      notes: '',
      contact: '',
      email: '',
      phone: '',
      finance_contact: '',
      finance_email: '',
      finance_phone: '',
      validationErrors: {},
      CREATE_CLIENT,
      UPDATE_CLIENT,
    };
  },
  computed: {
    variables() {
      return {
        name: this.name,
        code: this.code,
        notes: this.notes,
        contact: this.contact,
        email: this.email,
        phone: this.phone,
        finance_contact: this.finance_contact,
        finance_email: this.finance_email,
        finance_phone: this.finance_phone,
        ...(this.isUpdate() && {
          id: this.id,
          is_archived: this.is_archived,
        }),
      };
    },
  },
  methods: {
    isUpdate() {
      return this.$route ? this.$route.name == 'edit-client' : false;
    },
    onDone(data) {
      let id = this.isUpdate() ? data.data.updateClient.id : data.data.createClient.id;
      this.$router.push({ name: 'view-client', params: { id: id } });
    },
    onError(error) {
      let { graphQLErrors } = error;
      this.validationErrors = {};
      if (graphQLErrors[0].extensions.category === 'validation') {
        this.validationErrors = graphQLErrors[0].extensions.validation;
      }
    },
  },
  apollo: {
    client: {
      query: GET_CLIENT,
      variables() {
        if (this.id) {
          return {
            id: this.id,
          };
        }
      },
      result({ data }) {
        this.is_archived = data.client.is_archived;
        this.name = data.client.name;
        this.code = data.client.code;
        this.notes = data.client.notes;
        this.contact = data.client.contact;
        this.email = data.client.email;
        this.phone = data.client.phone;
        this.finance_contact = data.client.finance_contact;
        this.finance_email = data.client.finance_email;
        this.finance_phone = data.client.finance_phone;
      },
      skip() {
        return !this.isUpdate();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-column {
  min-width: 320px;
  max-width: 420px;
}
.notes-column {
  min-width: 320px;
  max-width: 960px;
}
</style>

<template>
  <div class="boolean" :class="{ true: value }">
    {{ value ? 'Yes' : 'No' }}
  </div>
</template>

<script>
export default {
  name: 'boolean',
  props: {
    value: Boolean,
  },
};
</script>

<style lang="scss" scoped>
$green: #05fe79;
$red: #ff2f05;

.boolean {
  display: inline-block;

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    vertical-align: middle;
    margin-right: 6px;
  }

  &:not(.true):before {
    background-color: $red;
    box-shadow: 0 0 4px 0 transparentize($red, 0.5);
  }

  &.true:before {
    background-color: $green;
    box-shadow: 0 0 4px 0 transparentize($green, 0.5);
  }
}
</style>

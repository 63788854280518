<template>
  <div class="layout">
    <div class="layout-header">
      <h1>Account</h1>
    </div>
    <div class="layout-content">
      <!-- Sessions -->
      <div class="card mb-6">
        <div class="card-body">
          <h2 class="mb-4">Sessions</h2>
          <p class="mb-5">Manage your active sessions.</p>
          <p class="mb-5 info">
            Beware, your account is still accessible when logging out from Tools but not from
            Google. <br />
            Visit Google and log out to end your session entirely.
          </p>

          <button class="button is-danger" @click="logout()">Logout</button>

          <table class="table access-tokens mt-5 is-fullwidth">
            <tr>
              <th>Name</th>
              <th style="width: 25%;">Last used</th>
              <th style="width: 25%;">Created</th>
              <th style="width: 15%;"></th>
            </tr>
            <tr v-if="refreshing">
              <td colspan="100%">
                <div class="is-flex is-justify-content-center">
                  <loader :loading="true" />
                </div>
              </td>
            </tr>
            <tr v-else v-for="accessToken of me.accessTokens" :key="accessToken.id">
              <td>
                {{ accessToken.name }}
                <small
                  class="has-background-dark py-1 px-2 ml-5"
                  v-if="accessToken.id === me.currentAccessToken.id"
                >
                  Current
                </small>
              </td>
              <td>{{ date(accessToken.lastUsedAt) }}</td>
              <td>{{ date(accessToken.createdAt) }}</td>
              <td class="has-text-right">
                <a
                  @click.prevent="revoke(accessToken)"
                  class="has-text-danger"
                  v-if="accessToken.id !== me.currentAccessToken.id"
                >
                  <delete-icon class="icon icon-delete" />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import RevokeTokenModal from '../../components/RevokeTokenModal';
import DeleteIcon from '@/assets/icons/delete.svg';
import Loader from '@/components/Loader';
import LogoutModal from '@/components/LogoutModal';
import { GET_ME } from '@/graphql/queries/me.ts';

export default {
  name: 'Account',

  components: {
    DeleteIcon,
    Loader,
  },

  data() {
    return {
      refreshing: false,
    };
  },

  methods: {
    logout() {
      this.$buefy.modal.open({
        parent: this,
        component: LogoutModal,
        hasModalCard: true,
        trapFocus: true,
        width: 640,
      });
    },

    revoke(accessToken) {
      this.$buefy.modal.open({
        props: {
          accessToken,
        },
        events: {
          success: () => {
            this.$buefy.notification.open({
              duration: 5000,
              message: `You've been logged out.`,
              type: 'is-success',
            });

            this.refreshing = true;

            this.$apollo
              .query({
                query: GET_ME,
                fetchPolicy: 'network-only',
              })
              .then(({ data }) => {
                this.$store.commit('logged_in_user', data.me);
              })
              .finally(() => {
                this.refreshing = false;
              });
          },
        },
        component: RevokeTokenModal,
        hasModalCard: true,
        trapFocus: true,
        parent: this,
        width: 640,
      });
    },

    date(value) {
      return format(parseISO(value), 'yyyy-MM-dd HH:m');
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  border-left: 3px solid $primary;
  padding-left: 1em;
}

.layout-content {
  padding: 2rem;
}

.card {
  box-shadow: none;
  border: 1px solid #384961;
  border-radius: 4px;
  background: #243143;
}

.card-body {
  padding: 2rem;
}

.table {
  border-radius: 5px;

  &.access-tokens {
    tr {
      td,
      th {
        padding: 1rem 1.5rem;
        color: $text;
      }
    }

    tr {
      td {
        a {
          display: flex;
          justify-content: flex-end;
        }

        .icon-delete {
          height: 1.25em;
          width: auto;
          margin: 2px 0 0;
        }
      }

      &:last-child {
        td {
          border-bottom: none;

          &:first-child {
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <ApolloMutation
    :mutation="EXPORT_TIMESHEET"
    :variables="this.variables"
    @done="onDone"
    @error="onError"
    v-if="this.canExport"
  >
    <template v-slot="{ mutate, loading }">
      <h3>Timesheet export</h3>
      <div class="label">Format</div>
      <div class="block">
        <b-radio v-model="format" size="is-small" native-value="pdf">
          PDF
        </b-radio>
        <b-radio v-model="format" size="is-small" native-value="csv">
          CSV
        </b-radio>
        <b-radio v-model="format" size="is-small" native-value="pdf_totals">
          PDF (totals)
        </b-radio>
      </div>
      <div class="has-text-right">
        <b-button @click="mutate" class="button is-primary" :loading="loading">Export</b-button>
      </div>
    </template>
  </ApolloMutation>
</template>

<script>
import { format } from 'date-fns';
import { EXPORT_TIMESHEET } from '@/graphql/mutations/export_timesheets';

export default {
  name: 'exporter',
  components: {},
  props: {
    activeFilters: {
      type: Object,
      default: function() {
        return {
          hours: [0, 12],
          dates: null,
          clients: [],
          users: [],
          projects: [],
          categories: [],
          editable: 'all',
        };
      },
    },
  },
  data() {
    return {
      format: 'pdf',
      variables: this.getVars(),
      EXPORT_TIMESHEET,
    };
  },
  watch: {
    format: {
      handler() {
        this.variables = this.getVars();
      },
    },
    activeFilters: {
      deep: true,
      handler() {
        this.variables = this.getVars();
      },
    },
  },
  computed: {
    canExport() {
      return this.$store.getters.havePermission('export timesheets');
    },
  },
  methods: {
    dateFormatter(dates) {
      if (dates.length === 2) {
        return format(dates[0], 'dd-MM-yyyy') + ' - ' + format(dates[1], 'dd-MM-yyyy');
      }
      return '';
    },
    onDone({ data }) {
      let url = data.createTimesheetExport;

      window.open(url, '_blank')?.focus();
    },
    onError() {
      this.$buefy.notification.open({
        duration: 5000,
        message: `Export failed`,
        type: 'is-danger',
      });
    },
    getVars() {
      let startDate = null;
      let endDate = null;
      if (
        'dates' in this.activeFilters &&
        this.activeFilters.dates !== null &&
        this.activeFilters.dates.length === 2
      ) {
        startDate = format(this.activeFilters.dates[0], 'yyyy-MM-dd');
        endDate = format(this.activeFilters.dates[1], 'yyyy-MM-dd');
      }

      return {
        hours: this.mapHours(this.activeFilters.hours),
        dates: [startDate, endDate],
        clients: this.mapObjectsToIds(this.activeFilters.clients),
        users: this.mapObjectsToIds(this.activeFilters.users),
        categories: this.mapObjectsToIds(this.activeFilters.categories),
        projects: this.mapObjectsToIds(this.activeFilters.projects),
        editable: this.activeFilters.editable,

        format: this.format,
      };
    },
    mapObjectsToIds(arr) {
      if (!Array.isArray(arr)) {
        return arr;
      }

      return arr.map(function(item) {
        return typeof item === 'object' ? item.id : item;
      });
    },
    mapHours(hours) {
      return typeof hours === 'undefined' || hours === null || (hours[0] === 0 && hours[1] === 12)
        ? null
        : hours;
    },
  },
};
</script>

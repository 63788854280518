<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
      <button type="button" class="delete" @click="close()" />
    </header>
    <section class="modal-card-body">
      {{ message }}
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="close()">
        {{ closeButton }}
      </button>

      <b-button class="button" :class="confirmButtonType" @click="handle()" type="button">
        {{ confirmButton }}
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    title: {
      type: String,
      default: 'Are you sure?',
    },
    message: {
      type: String,
      required: true,
    },
    closeButton: {
      type: String,
      default: 'Close',
    },
    confirmButton: {
      type: String,
      default: 'Confirm',
    },
    confirmButtonType: {
      type: String,
      default: 'is-primary',
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    handle() {
      this.$emit('handle');
    },
  },
};
</script>

<style scoped lang="scss">
.modal-card-head {
  padding: 30px 30px 25px;
}

.modal-card-body {
  padding: 0 30px 10px;
}

.modal-card-foot {
  padding: 25px 30px 30px;

  .button:not(:last-child) {
    margin-right: 1em;
  }
}
</style>

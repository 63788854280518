<template>
  <div>
    <div class="mb-6">
      <h2>Active holidays</h2>
      <div v-if="holidays.active" class="table-responsive">
        <table class="table">
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Absence</th>
          </tr>
          <tr v-if="loading">
            <td colspan="100%">
              <div class="is-flex is-justify-content-center">
                <loader :loading="true" />
              </div>
            </td>
          </tr>
          <template v-else>
            <!-- Active -->
            <tr v-for="holiday of holidays.active" :key="holiday.id">
              <td>
                <div class="is-inline-flex is-align-items-center">
                  {{ holiday.requester.name }}

                  <component
                    :is="getRandomIcon()"
                    stroke-width="1"
                    height="20"
                    width="20"
                    class="ml-3"
                  />
                </div>
              </td>
              <td>
                <template v-if="holiday.startAt === holiday.endAt">
                  {{ holiday.startAt }}
                </template>
                <template v-else>
                  {{ holiday.startAt }}
                  &nbsp;&ndash;&nbsp;
                  {{ holiday.endAt }}
                </template>
              </td>
              <td>{{ holiday.hours }}h</td>
            </tr>
          </template>
        </table>
      </div>
      <div v-else>
        <i>There are no active holidays at the moment</i>
      </div>
    </div>
    <div class="mb-6">
      <h2>Upcoming holidays</h2>
      <div v-if="holidays.upcoming" class="table-responsive">
        <table class="table">
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Absence</th>
          </tr>
          <tr v-if="loading">
            <td colspan="100%">
              <div class="is-flex is-justify-content-center">
                <loader :loading="true" />
              </div>
            </td>
          </tr>
          <template v-else>
            <!-- Upcoming -->
            <tr v-for="holiday of holidays.upcoming" :key="holiday.id">
              <td>
                <div class="is-inline-flex is-align-items-center">
                  {{ holiday.requester.name }}
                </div>
              </td>
              <td>
                <template v-if="holiday.startAt === holiday.endAt">
                  {{ holiday.startAt }}
                </template>
                <template v-else>
                  {{ holiday.startAt }}
                  &nbsp;&ndash;&nbsp;
                  {{ holiday.endAt }}
                </template>
              </td>
              <td>{{ holiday.hours }}h</td>
            </tr>
          </template>
        </table>
      </div>
      <div v-else>
        <i>There are no upcoming holidays</i>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardCard from '@/components/DashboardCard';
import Loader from '@/components/Loader';
import {
  BeachIcon,
  CaravanIcon,
  CaretDownIcon,
  CaretUpIcon,
  ScubaMaskIcon,
  SpeedboatIcon,
} from 'vue-tabler-icons';
import { defineComponent, computed } from '@vue/composition-api/dist/vue-composition-api';
import { useQuery } from '@vue/apollo-composable';
import { GET_HOLIDAYS } from '@/graphql/queries/holidays';

export default defineComponent({
  components: {
    DashboardCard,
    Loader,
    BeachIcon,
    CaravanIcon,
    ScubaMaskIcon,
    SpeedboatIcon,
    CaretDownIcon,
    CaretUpIcon,
  },
  setup() {
    const { result, loading } = useQuery(GET_HOLIDAYS);
    const holidays = computed(() => {
      return result.value
        ? { active: result.value.active.data, upcoming: result.value.upcoming.data }
        : [];
    });

    function getRandomIcon() {
      const icons = ['beach-icon', 'caravan-icon', 'scuba-mask-icon', 'speedboat-icon'];
      return icons[Math.floor(Math.random() * icons.length)];
    }

    return {
      loading,
      holidays,
      getRandomIcon,
    };
  },
});
</script>

<style lang="scss">
.table-responsive {
  overflow: auto;
  width: 100%;
  display: block;
}

.table {
  border-radius: 5px;
  width: 100%;

  tr {
    td,
    th {
      padding: 1rem 1.5rem;
      color: $text;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: middle;
    }

    &:last-child {
      td {
        border-bottom: none;

        &:first-child {
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
}
</style>

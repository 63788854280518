<template>
  <b-field>
    <template v-slot:label>
      Statuses
      <span class="reset" @click="reset">Reset</span>
    </template>

    <b-taginput
      v-model="selected"
      @input="input"
      type="is-dark"
      autocomplete
      :data="options"
      open-on-focus
      keep-first
      field="label"
    />
  </b-field>
</template>

<script>
import ProjectStatus from '@/enums/ProjectStatus';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      statuses: ProjectStatus.cases(),
      selected: [],
    };
  },

  mounted() {
    this.selected = this.value;
  },

  methods: {
    reset() {
      this.$emit('reset');
      this.selected = [];
    },

    input(value) {
      this.$emit('input', value);
    },
  },

  computed: {
    options() {
      return this.statuses.filter(
        (status) => !this.selected.map(({ value }) => value).includes(status.value),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  color: #2b7eed;
  text-decoration: underline;
  cursor: pointer;
  float: right;

  &:hover {
    text-decoration: none;
  }
}
</style>

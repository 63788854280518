var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout has-sidebar"},[_vm._m(0),_c('div',{staticClass:"layout-content"},[_c('div',{staticClass:"columns"},[_c('ApolloQuery',{staticClass:"column is-narrow is-max-full is-full",attrs:{"query":_vm.GET_TIMESHEETS,"variables":_vm.variables},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
var query = ref.query;
return [(data && data.timesheets)?_c('div',{staticClass:"sticky-column-wrapper"},[_c('div',{staticClass:"sticky-column-scroller"},[_c('table',{staticClass:"sticky-column-table data-table"},[_c('thead',[(_vm.canSeeOtherTeamMembers)?_c('th',[_vm._v("Team member")]):_vm._e(),_c('th',[_vm._v("Project")]),_c('OrderColumn',{attrs:{"order":_vm.order,"column-name":"DESCRIPTION"},on:{"update:order":[function($event){_vm.order=$event},function($event){return query.refetch()}]}},[_vm._v(" Description ")]),_c('OrderColumn',{attrs:{"order":_vm.order,"column-name":"DATE"},on:{"update:order":[function($event){_vm.order=$event},function($event){return query.refetch()}]}},[_vm._v(" Date ")]),_c('OrderColumn',{attrs:{"order":_vm.order,"column-name":"HOURS"},on:{"update:order":[function($event){_vm.order=$event},function($event){return query.refetch()}]}},[_vm._v(" Hours ")]),_c('OrderColumn',{attrs:{"order":_vm.order,"column-name":"EDITABLE"},on:{"update:order":[function($event){_vm.order=$event},function($event){return query.refetch()}]}},[_vm._v(" Editable ")]),_c('OrderColumn',{attrs:{"order":_vm.order,"column-name":"FROM_HOME"},on:{"update:order":[function($event){_vm.order=$event},function($event){return query.refetch()}]}},[_vm._v(" From home ")]),_c('th',{staticClass:"has-text-right"},[(isLoading)?_c('div',{staticClass:"loader inline"}):_vm._e()])],1),_vm._l((data.timesheets.data),function(timesheet){return _c('tr',{key:timesheet.id},[(_vm.canSeeOtherTeamMembers)?_c('td',[_c('router-link',{staticClass:"font-medium hover:text-blue",attrs:{"to":{
                        name: 'view-user',
                        params: { id: timesheet.participation.user.id },
                      }}},[_vm._v(" "+_vm._s(timesheet.participation.user.first_name)+" "+_vm._s(timesheet.participation.user.last_name)+" ")])],1):_vm._e(),_c('td',{staticClass:"column-max-width"},[_c('router-link',{staticClass:"font-medium hover:text-blue",attrs:{"to":{
                        name: 'view-project',
                        params: { id: timesheet.participation.project.id },
                      }}},[_c('span',{attrs:{"title":timesheet.participation.project.display_code +
                            ': ' +
                            timesheet.participation.project.name}},[_c('strong',[_vm._v(_vm._s(timesheet.participation.project.display_code))]),_vm._v(" "+_vm._s(timesheet.participation.project.name)+" ")])])],1),_c('td',{staticClass:"column-max-width"},[_c('span',{attrs:{"title":timesheet.description}},[_vm._v(_vm._s(timesheet.description))])]),_c('td',[_vm._v(_vm._s(_vm._f("dateShort")(timesheet.date)))]),_c('td',[_vm._v(_vm._s(_vm._f("hours")(timesheet.hours,2)))]),_c('td',[_c('boolean',{attrs:{"value":timesheet.editable}})],1),_c('td',[_c('boolean',{attrs:{"value":timesheet.fromHome}})],1),_c('td',{staticClass:"sticky-column"},[_c('router-link',{attrs:{"to":{
                        name: 'view-timesheet',
                        params: { id: timesheet.id },
                      }}},[_c('open',{staticClass:"icon icon-inline"})],1),(timesheet.editable)?_c('router-link',{attrs:{"to":{
                        name: 'edit-timesheet',
                        params: { id: timesheet.id },
                      }}},[_c('edit',{staticClass:"icon icon-inline"})],1):_vm._e(),(timesheet.editable)?_c('delete-timesheet-button',{attrs:{"timesheet":timesheet},on:{"done":function($event){return query.refresh()}}},[_c('delete-icon',{staticClass:"icon icon-inline"})],1):_vm._e()],1)])})],2)])]):_vm._e(),(data && data.timesheets)?_c('paginator',{attrs:{"pageId":_vm.pageId,"lastPage":data.timesheets.paginatorInfo.lastPage},on:{"update:pageId":function($event){_vm.pageId=$event},"update:page-id":function($event){_vm.pageId=$event}}}):_vm._e()]}}])})],1)]),_c('div',{staticClass:"layout-sidebar"},[_c('ul',[_c('li',[_c('router-link',{staticClass:"button is-primary",attrs:{"to":{ name: 'new-timesheet' }}},[_vm._v(" Create ")])],1),_c('li',[_c('timesheet-filters',{attrs:{"can-see-other-team-members":_vm.canSeeOtherTeamMembers},on:{"update:whereHasProject":function (value) { return (_vm.whereHasProject = value); },"update:whereHas":function (value) { return (_vm.whereHas = value); },"update:where":function (value) { return (_vm.where = value); },"update:activeFilters":function (value) { return (_vm.activeFilters = value); }}})],1),_c('li',{staticClass:"break"},[_c('exporter',{attrs:{"active-filters":_vm.activeFilters}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-header"},[_c('h1',[_vm._v("Timesheets")])])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.queries.project.loading)?_c('div',{staticClass:"loading is-full"}):_c('div',{staticClass:"layout has-sidebar"},[_c('div',{staticClass:"layout-header"},[_c('h1',[_vm._v("Edit team for "+_vm._s(_vm.project_name))])]),_c('div',{staticClass:"layout-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"data-table-scroll"},[_c('table',{staticClass:"data-table"},[_vm._m(0),_vm._l((_vm.participations),function(participation,index){return _c('tr',{key:index},[_c('th',[_c('router-link',{attrs:{"to":{
                    name: 'view-user',
                    params: { id: participation.user.id },
                  }}},[_vm._v(" "+_vm._s(participation.user.first_name)+" "+_vm._s(participation.user.last_name)+" ")])],1),_c('td',[_c('ApolloMutation',{attrs:{"mutation":_vm.UPDATE_PARTICIPATION,"variables":{
                    id: participation.id,
                    hours: participation.hours,
                  }},on:{"done":_vm.onDoneUpdate,"error":_vm.onErrorUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var mutate = ref.mutate;
                  var loading = ref.loading;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return mutate()}}},[_c('fieldset',{attrs:{"disabled":loading}},[_c('b-numberinput',{attrs:{"type":"number","min":"0","controls":false},nativeOn:{"change":function($event){return mutate()}},model:{value:(participation.hours),callback:function ($$v) {_vm.$set(participation, "hours", $$v)},expression:"participation.hours"}})],1)])]}}],null,true)})],1),_c('td',[_c('b-progress',{attrs:{"value":participation.worked_hours,"max":participation.hours,"size":"is-medium","type":_vm.progress_type(participation),"show-value":""}},[_vm._v(" "+_vm._s(participation.worked_hours)+" / "+_vm._s(participation.hours)+" ")])],1),_c('td',[_c('ApolloMutation',{attrs:{"mutation":_vm.DELETE_PARTICIPATION,"variables":{ id: participation.id }},on:{"done":_vm.onDoneRemove,"error":_vm.onErrorRemove},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var mutate = ref.mutate;
                  var loading = ref.loading;
return [_c('button',{staticClass:"button is-danger is-outlined is-small",class:{ 'is-disabled': loading },on:{"click":function($event){return mutate()}}},[_vm._v(" Remove ")])]}}],null,true)})],1)])})],2)])])])]),_c('div',{staticClass:"layout-sidebar"},[_c('ul',[_c('li',[_c('router-link',{staticClass:"button is-primary is-outlined",attrs:{"to":{ name: 'view-project', params: { id: _vm.id } }}},[_vm._v(" Back to project ")])],1),_c('li',{staticClass:"break"},[_c('ApolloMutation',{attrs:{"mutation":_vm.CREATE_PARTICIPATION,"variables":{ project_id: this.id, user_id: this.user_id }},on:{"done":_vm.onDoneAdd,"error":_vm.onErrorAdd},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var mutate = ref.mutate;
                  var loading = ref.loading;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return mutate()}}},[_c('fieldset',{attrs:{"disabled":loading || _vm.$apollo.queries.all_users.loading}},[_c('b-field',{attrs:{"label":"Add team member","label-for":"team_member"}},[_c('b-select',{attrs:{"id":"team_member","disabled":_vm.$apollo.queries.all_users.loading,"loading":_vm.$apollo.queries.all_users.loading},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}},_vm._l((_vm.users),function(user){return _c('option',{key:user.id,domProps:{"value":user.id}},[_vm._v(" "+_vm._s(user.first_name)+" "+_vm._s(user.last_name)+" ")])}),0)],1),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit"}},[_vm._v(" Add team member ")])],1)])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Team Member")]),_c('th',[_vm._v("Hours for project")]),_c('th',[_vm._v("Actions")])])}]

export { render, staticRenderFns }
<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <rect x="4" y="7" width="16" height="9" rx="1"></rect>
      <path d="M7 16v4"></path>
      <path d="M7.5 16l9 -9"></path>
      <path d="M13.5 16l6.5 -6.5"></path>
      <path d="M4 13.5l6.5 -6.5"></path>
      <path d="M17 16v4"></path>
      <path d="M5 20h4"></path>
      <path d="M15 20h4"></path>
      <path d="M17 7v-2"></path>
      <path d="M7 7v-2"></path>
    </svg>

    <h2 class="mt-4 mb-3" style="font-weight: normal;">
      We have encountered a blockage!
    </h2>
    <p>
      We've sent too many requests too Toggl and they can't keep up anymore. <br />
      Wait a minute and try again.
    </p>

    <button class="button mt-5" @click="$parent.refresh()">
      Refresh
    </button>
  </div>
</template>

<script>
export default {
  props: ['provider'],
};
</script>

<style lang="scss" scoped>
div {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  line-height: 2em;
  text-align: center;
}
</style>

<template>
  <div v-if="editable" class="field">
    <div class="header">
      <label class="label">{{ this.label }}</label>
      <span v-if="edit" @click="edit = false" class="preview-link">Switch to preview</span>
      <span v-else @click="edit = true" class="preview-link">Switch to edit mode</span>
    </div>
    <div v-if="edit" class="control">
      <b-input
        v-model="text"
        @input="$emit('update:text', text)"
        type="textarea"
        class="markdown-textarea"
        :rows="this.rows || 40"
        :placeholder="this.placeholder"
      ></b-input>
    </div>
    <div v-else class="markdown editable" v-html="this.compiledMarkdown"></div>
  </div>
  <div v-else-if="empty" class="empty">No notes</div>
  <div v-else class="markdown" v-html="this.compiledMarkdown"></div>
</template>

<script>
export default {
  name: 'markdown',
  props: {
    text: String,
    label: String,
    placeholder: String,
    rows: Number,
    editable: Boolean,
  },
  data() {
    return {
      edit: this.editable,
    };
  },
  computed: {
    empty() {
      return this.text == null || this.text.trim() == '';
    },
    compiledMarkdown() {
      var DOMPurify = require('dompurify');
      var marked = require('marked');

      return DOMPurify.sanitize(marked(this.text));
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
}

.preview-link {
  text-decoration: underline;
  font-size: 0.8rem;
  cursor: pointer;
  transition: 0.2s;
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    color: rgba(255, 255, 255, 0.875);
  }
}

.editable {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 1rem;
}

.markdown-textarea::v-deep .textarea {
  font-family: 'Source Code Pro', monospace;
}

.markdown::v-deep {
  color: rgba(255, 255, 255, 0.875);
  background: transparentize(#243143, 0.5);
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 16px;

  h1,
  h2,
  h3 {
    font-weight: bold;
    padding: 0;
    margin-bottom: 1rem;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  h1 {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  h3 {
    font-size: 1rem;
  }

  blockquote {
    white-space: normal;
    margin: 1rem 0;
  }

  ul,
  ol {
    padding-left: 2rem;
    margin: 1rem 0;

    ul,
    ol {
      padding-left: 2rem;
      margin: 0;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}

.empty {
  font-style: italic;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 2rem;
}
</style>

<template>
  <a :href="url" class="button is-primary">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="#ffffff"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M17.788 5.108a9 9 0 1 0 3.212 6.892h-8"></path>
    </svg>
    Login
  </a>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'GoogleLoginButton',
  setup() {
    const url = process.env.VUE_APP_GOOGLE_LOGIN_ENDPOINT;

    return {
      url,
    };
  },
});
</script>

<style lang="scss" scoped>
.button {
  display: inline-flex;
  padding: 0.8em 2em;
  height: auto;

  svg {
    height: 1.2em;
    margin-right: 0.4em;
  }
}
</style>

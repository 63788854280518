<template>
  <tr :class="{ imported: entry.timesheet }">
    <td>
      <b-checkbox
        class="ml-0 mr-0 mt-1"
        @input="toggle(entry)"
        :value="selected"
        v-if="!entry.timesheet"
        :disabled="loading || !data.projectId"
      />
    </td>
    <td>
      <b class="mr-2">{{ entry.externalClientName }}</b> &ndash;
      <span class="ml-2">{{ entry.externalProjectName }}</span>
      <br class="mb-3" />
      <span class="mr-2">{{ entry.date }}</span> &ndash; &nbsp;
      <input
        type="number"
        step="0.25"
        min="0.25"
        max="10"
        required
        v-model="data.hours"
        class="input hours-input"
        @input="checkHourValue($event.target.value) && update()"
        v-if="!entry.timesheet"
      />
      <b v-else>{{ data.hours }}</b>
      hours
    </td>
    <td>
      <select-project
        v-if="!entry.timesheet"
        ref="project"
        name="project"
        style="width: 220px"
        :options="me.projects"
        :search="searchEntryProject(entry)"
        v-model="data.projectId"
        :disabled="loading"
        @input="update()"
      />
      <template v-else>
        {{ entry.timesheet.project.title }}
      </template>
    </td>
    <td class="description">
      <b-input
        v-if="!entry.timesheet"
        class="description"
        type="textarea"
        cols="48"
        rows="1"
        maxlength="1000"
        id="description"
        v-model="data.description"
        @input="update()"
        :disabled="loading"
      />

      <blockquote v-else>
        {{ data.description }}
      </blockquote>
    </td>
    <td>
      <BCheckbox v-model="data.fromHome" id="fromHome" @input="update()" />
    </td>
    <td>
      <div class="is-flex is-justify-content-flex-end" v-if="!entry.timesheet">
        <b-dropdown
          :triggers="loading || !data.projectId ? [] : ['hover', 'click']"
          aria-role="list"
          position="is-bottom-left"
          :disabled="loading || !data.projectId"
          @input="update()"
        >
          <template #trigger>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="cursor: pointer;"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="12" cy="12" r="1" />
              <circle cx="12" cy="19" r="1" />
              <circle cx="12" cy="5" r="1" />
            </svg>
          </template>
          <b-dropdown-item aria-role="listitem" has-link>
            <a @click="run()">Import</a>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </td>
  </tr>
</template>

<script>
import SelectProject from '../../../components/SelectProject';
import { IMPORT_TIMESHEET_BATCH } from '@/graphql/mutations/import/import_timesheet_batch.ts';

export default {
  components: {
    SelectProject,
  },

  props: ['entry', 'selected'],

  data() {
    return {
      loading: false,

      data: {
        hours: this.entry.hours,
        description: this.entry.description,
        projectId: this.entry.projectId,
        fromHome: this.entry.timesheet?.fromHome || false,
      },
    };
  },

  watch: {
    entry: {
      deep: true,
      handler() {
        this.data = {
          hours: this.entry.hours,
          description: this.entry.description,
          projectId: this.entry.projectId,
          fromHome: this.entry.fromHome,
        };
      },
    },
  },

  methods: {
    searchEntryProject() {
      return () => ({
        variables: {
          my: true,
        },
      });
    },

    update() {
      this.$emit('input', this.data);
    },

    toggle() {
      this.$emit(this.selected ? 'deselect' : 'select');
    },

    run() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: IMPORT_TIMESHEET_BATCH,

          variables: {
            timesheets: [
              {
                id: this.entry.id,
                input: {
                  hours: Number.parseFloat(this.data.hours),
                  description: this.data.description,
                  projectId: this.data.projectId,
                  fromHome: this.data.fromHome,
                },
              },
            ],
          },
        })
        .then(async () => {
          this.$buefy.notification.open({
            message: 'Successfully imported timesheet',
            type: 'is-success',
          });

          await this.$parent.fetch();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    checkHourValue(value) {
      if (value > 10) {
        this.$buefy.notification.open({
          message: 'Can not be more than 10 hours long.',
          type: 'is-danger',
        });

        this.data.hours = 10;
      }

      if (value < 0.25) {
        this.$buefy.notification.open({
          message: 'Can not be less than 0.25 hours long.',
          type: 'is-danger',
        });

        this.data.hours = 0.25;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tr.imported {
  transition: 0.2s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

::v-deep .description {
  textarea:focus {
    height: 90px;
  }
}

.input.hours-input {
  display: inline;
  padding: 0.2rem 1rem;
  font-weight: 500;
  margin-right: 0.5rem;
  margin-top: -2px;
  font-size: 1em;
  height: auto;
  width: 95px;
  min-width: auto;
}
</style>

<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Logout</p>
      <button type="button" class="delete" @click="cancel()" :disabled="loading" />
    </header>
    <section class="modal-card-body">
      <p>Are you sure you want to logout from this device?</p>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="cancel()" :disabled="loading">
        Cancel
      </button>
      <b-button class="button is-danger" :loading="loading" @click="logout()" type="button">
        Logout
      </b-button>
    </footer>
  </div>
</template>

<script>
import { REVOKE_TOKEN } from '@/graphql/mutations/tokens/revoke';

export default {
  name: 'LogoutModal',

  props: {
    accessToken: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async cancel() {
      await this.$emit('cancel');

      await this.close();
    },

    async logout() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: REVOKE_TOKEN,
          variables: {
            tokenId: this.accessToken.id,
          },
        })
        .then(async ({ data }) => {
          await this.$emit('success', data);
        })
        .catch(async (error) => {
          await this.$emit('error', error);

          this.$buefy.notification.open({
            duration: 5000,
            message: `Could not end session.`,
            type: 'is-danger',
          });
        })
        .finally(async () => {
          this.loading = false;

          await this.close();
        });
    },

    async close() {
      await this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.modal-card {
  .modal-card-head {
    padding: 30px 30px 25px;
  }

  .modal-card-body {
    padding: 0 30px 10px;
  }

  .modal-card-foot {
    padding: 25px 30px 30px;

    .button:not(:last-child) {
      margin-right: 1em;
    }
  }
}
</style>

<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <circle cx="8" cy="15" r="4"></circle>
      <line x1="10.85" y1="12.15" x2="19" y2="4"></line>
      <line x1="18" y1="5" x2="20" y2="7"></line>
      <line x1="15" y1="8" x2="17" y2="10"></line>
    </svg>

    <h2 class="mt-4 mb-3" style="font-weight: normal;">
      Authorization needed
    </h2>
    <p>
      Google needs your authorization to share your calendar with us.
    </p>

    <a class="button mt-5" :href="url">
      Authorize
    </a>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  padding: 2rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>

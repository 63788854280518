<template>
  <div class="layout has-sidebar">
    <div class="layout-header">
      <h1>Planning</h1>
    </div>

    <div class="layout-sidebar">
      <ul>
        <li>
          <button class="button is-primary has-margin-right" @click="zoom(0.2)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
              />
            </svg>
          </button>
          <button class="button is-primary" @click="zoom(-0.2)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7"
              />
            </svg>
          </button>
        </li>

        <li>
          <b-field label="Start date">
            <b-datepicker v-model="viewDates" type="month" position="is-bottom-left" range>
            </b-datepicker>
          </b-field>
        </li>
      </ul>
    </div>

    <div class="layout-content">
      <div v-if="$apollo.queries.planning.loading">
        <div class="loader full"></div>
      </div>
      <div v-else class="planning">
        <div class="planning-months">
          <div
            class="date"
            v-for="month in months"
            :key="month.id"
            :style="{ 'min-width': month.width }"
          >
            <div class="year">{{ month.year }}</div>
            <div class="month">{{ month.month }}</div>
          </div>
        </div>

        <div ref="view" class="planning-view">
          <div class="planning-grid">
            <div
              class="planning-grid-line"
              v-for="month in months"
              :key="month.id"
              :style="{ 'min-width': month.width }"
            ></div>
          </div>
          <div class="planning-today" v-if="showToday" :style="{ width: todayWidth }"></div>
          <div class="planning-lane" v-for="lane in lanes" :key="lane.id">
            <planning-project
              :pixelsPerDay="pixelsPerDay"
              :offsetDate="project.offsetDate"
              :project="project.obj"
              :viewStartDate="viewStartDate"
              :viewEndDate="viewEndDate"
              v-for="project in lane"
              :key="project.id"
            ></planning-project>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  subMonths,
  addMonths,
  startOfMonth,
  differenceInCalendarDays,
  isBefore,
  isAfter,
  format,
  getDaysInMonth,
  max,
  compareAsc,
} from 'date-fns';
import PlanningProject from './planning-project.view.vue';
import { GET_PLANNING } from '@/graphql/queries/planning';

export default {
  name: 'Planning',
  components: {
    PlanningProject,
  },
  data() {
    return {
      viewDates: [startOfMonth(subMonths(new Date(), 2)), startOfMonth(addMonths(new Date(), 6))],
      zoomLevel: 1,
      months: [],
      lanes: [],
    };
  },
  mounted() {
    this.calculateMonths();
  },
  computed: {
    viewStartDate() {
      return this.viewDates[0];
    },
    viewEndDate() {
      return addMonths(this.viewDates[1], 1);
    },
    pixelsPerDay() {
      return 8 * this.zoomLevel;
    },
    viewWidth() {
      return this.$refs.view.clientWidth;
    },
    todayWidth() {
      return differenceInCalendarDays(new Date(), this.viewStartDate) * this.pixelsPerDay + 'px';
    },
    showToday() {
      return isAfter(new Date(), this.viewStartDate) && isBefore(new Date(), this.viewEndDate);
    },
  },
  methods: {
    zoom(diff) {
      this.zoomLevel += diff;

      if (this.zoomLevel < 1) {
        this.zoomLevel = 1;
      }

      if (this.zoomLevel > 2) {
        this.zoomLevel = 2;
      }

      this.calculateMonths();
    },
    getZoomWidth() {
      return this.pixelsPerDay * differenceInCalendarDays(this.viewEndDate, this.viewStartDate);
    },
    calculateMonths() {
      let start = this.viewStartDate;
      let end = this.viewEndDate;

      this.months = [];
      let current_year = 0;
      for (let date = start; isBefore(date, end); date = addMonths(date, 1)) {
        let year = date.getFullYear();
        this.months.push({
          month: format(date, 'MMM'),
          year: year === current_year ? '' : year,
          width: getDaysInMonth(date) * this.pixelsPerDay + 'px',
        });

        if (year !== current_year) {
          current_year = year;
        }
      }
    },
  },
  watch: {
    viewDates(o, n) {
      if (o !== n) {
        this.calculateMonths();
      }
    },
  },
  apollo: {
    planning: {
      query: GET_PLANNING,
      variables() {
        return {
          startDate: format(this.viewDates[0], 'yyyy-MM-dd'),
          endDate: format(this.viewDates[1], 'yyyy-MM-dd'),
        };
      },
      update(data) {
        // Algo params
        let days_buffer = 7;

        // This algorithm divides the projects over the lanes
        let lanes = [];
        let projects = data.planning.sort((a, b) => {
          let start_a = max([new Date(a.start_date), this.viewStartDate]);
          let start_b = max([new Date(b.start_date), this.viewStartDate]);

          // Returns 0 if dates are the same
          if (compareAsc(start_a, start_b) === 0) {
            return a.external_delivery_date < b.external_delivery_date ? -1 : 1;
          }

          return compareAsc(start_a, start_b);
        });

        for (let id in projects) {
          let project = projects[id];
          let project_start_date = new Date(project.start_date);

          // Greedy algorithm; loop over lanes and find first fit
          let i;
          for (i = 0; i < lanes.length; i++) {
            let lane_end = new Date(lanes[i][lanes[i].length - 1].obj.external_delivery_date);

            if (differenceInCalendarDays(project_start_date, lane_end) > days_buffer) {
              // The project fits this lane. Add it and break.
              lanes[i].push({
                offsetDate: lane_end,
                obj: project,
              });

              break;
            }
          }
          if (i === lanes.length) {
            // Didn't fit in any lane: add a new lane
            lanes.push([
              {
                offsetDate: this.viewStartDate,
                obj: project,
              },
            ]);
          }
        }

        this.lanes = lanes;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.planning {
  overflow-x: auto;

  &-months {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    .date {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transition: 0.2s;

      .year {
        font-weight: 300;
        font-size: 1.2rem;
      }

      .month {
        font-weight: 500;
      }
    }
  }

  &-view {
    position: relative;
    padding: 2rem 0;
    padding-bottom: 480px;
  }

  &-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    &-line {
      border-top: 2px solid rgba(255, 255, 255, 0.1);
      border-left: 2px solid rgba(255, 255, 255, 0.1);
      transition: 0.2s;
    }
  }

  &-today {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-right: 2px solid #0595fe;
    z-index: 0;
    transition: 0.2s;
  }

  &-lane {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
}

button svg {
  display: inline-block;
  width: 24px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":_vm.GET_HOLIDAY_REQUEST,"variables":{ id: _vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
var query = ref.query;
return [_c('div',{staticClass:"layout has-sidebar"},[_c('div',{staticClass:"layout-header"},[_c('h1',[_vm._v("Holiday request")])]),_c('div',{staticClass:"layout-content"},[(isLoading)?_c('div',{staticClass:"loader full"}):_vm._e(),(!isLoading && data.holiday_request)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-narrow is-max-full"},[_c('div',{staticClass:"data-table-scroll"},[_c('table',{staticClass:"data-table"},[_c('tr',[_c('th',[_vm._v("Team member")]),_c('td',[_c('router-link',{staticClass:"font-medium hover:text-blue",attrs:{"to":{
                        name: 'view-user',
                        params: {
                          id: data.holiday_request.requester.id,
                        },
                      }}},[_vm._v(" "+_vm._s(data.holiday_request.requester.first_name)+" "+_vm._s(data.holiday_request.requester.last_name)+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Start holiday")]),_c('td',[_vm._v(_vm._s(_vm._f("date")(data.holiday_request.start_at)))])]),_c('tr',[_c('th',[_vm._v("End holiday")]),_c('td',[_vm._v(_vm._s(_vm._f("date")(data.holiday_request.end_at)))])]),_c('tr',[_c('th',[_vm._v("Hours of absence")]),_c('td',[_vm._v(_vm._s(_vm._f("hours")(data.holiday_request.hour_sum,2)))])]),_c('tr',[_c('th',[_vm._v("Explanation")]),_c('td',[_c('blockquote',{staticClass:"description"},[_vm._v(" "+_vm._s(data.holiday_request.explanation)+" ")])])]),_c('tr',[_c('th',[_vm._v("Approved")]),_c('td',[_c('status',{attrs:{"value":data.holiday_request.approved_at === null ? 'pending' : 'yes',"options":[
                        { value: 'pending', color: 'gray' },
                        { value: 'yes', color: '#05fe79' } ]}})],1)]),(data.holiday_request.approved_at !== null)?_c('tr',[_c('th',[_vm._v("Approver")]),_c('td',[(data.holiday_request.approver)?_c('router-link',{staticClass:"font-medium hover:text-blue",attrs:{"to":{
                        name: 'view-user',
                        params: {
                          id: data.holiday_request.approver.id,
                        },
                      }}},[_vm._v(" "+_vm._s(data.holiday_request.approver.first_name)+" "+_vm._s(data.holiday_request.approver.last_name)+" ")]):_vm._e()],1)]):_vm._e(),_c('tr',[_c('th',[_vm._v("Created at")]),_c('td',[_vm._v(_vm._s(_vm._f("datetime")(data.holiday_request.created_at)))])]),_c('tr',[_c('th',[_vm._v("Last updated")]),_c('td',[_vm._v(_vm._s(_vm._f("datetime")(data.holiday_request.updated_at)))])])])])])]):_vm._e()]),(!isLoading && data.holiday_request)?_c('div',{staticClass:"layout-sidebar"},[_c('ul',[(!isLoading && !data.holiday_request.approved_at)?_c('li',[_c('router-link',{staticClass:"button is-primary",attrs:{"to":{ name: 'edit-holiday-request', params: { id: _vm.id } }}},[_vm._v(" Edit ")])],1):_vm._e(),_c('li',[(!data.holiday_request.approved_at && _vm.canApproveHolidays)?_c('approve-holiday-request-button',{attrs:{"holiday-request":data.holiday_request},on:{"done":function($event){return query.refetch()}}},[_c('button',{staticClass:"button is-primary"},[_vm._v("Approve")])]):_vm._e()],1),(!isLoading && !data.holiday_request.approved_at && _vm.canApproveHolidays)?_c('li',[_c('delete-holiday-request-button',{staticClass:"button is-danger",attrs:{"holiday-request":data.holiday_request},on:{"done":function($event){return _vm.deleted()}}})],1):_vm._e()])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ApolloMutation
    :mutation="UPDATE_SETTINGS"
    :variables="this.variables"
    @done="onDone"
    @error="onError"
  >
    <template v-slot="{ mutate, loading }">
      <form @submit.prevent="mutate()">
        <fieldset :disabled="loading || $apollo.queries.settings.loading">
          <div class="layout has-sidebar">
            <div class="layout-header">
              <h1>Edit settings</h1>
            </div>

            <div class="layout-content">
              <div class="columns is-multiline">
                <div class="column info-column">
                  <h2>Personal information</h2>

                  <b-field
                    label="Birthday"
                    label-for="birthday"
                    :type="{ 'is-danger': validationErrors['birthday'] }"
                    :message="validationErrors['birthday']"
                  >
                    <b-datepicker
                      v-model="birthday"
                      :max-date="new Date()"
                      open-on-focus
                      trap-focus
                      :date-formatter="dateFormatter"
                      :date-parser="dateParser"
                    />
                  </b-field>

                  <b-field
                    label="Phone"
                    label-for="phone"
                    :type="{ 'is-danger': validationErrors['phone'] }"
                    :message="validationErrors['phone']"
                  >
                    <b-input type="text" id="phone" v-model="phone" />
                  </b-field>

                  <b-field
                    label="Private email"
                    label-for="private_email"
                    :type="{ 'is-danger': validationErrors['private_email'] }"
                    :message="validationErrors['private_email']"
                  >
                    <b-input type="text" id="private_email" v-model="private_email" />
                  </b-field>
                  <b-field
                    label="Emergency contact"
                    label-for="emergency_contact"
                    :type="{ 'is-danger': validationErrors['emergency_contact'] }"
                    :message="validationErrors['emergency_contact']"
                  >
                    <b-input type="text" id="emergency_contact" v-model="emergency_contact" />
                  </b-field>

                  <h2>Change password</h2>

                  <b-field
                    label="Password"
                    label-for="password"
                    :type="{ 'is-danger': validationErrors['password'] }"
                    :message="validationErrors['password']"
                  >
                    <b-input type="password" id="password" v-model="password" />
                  </b-field>

                  <b-field
                    label="Password confirmation"
                    label-for="passwordConfirmation"
                    :type="{
                      'is-danger': validationErrors['password_confirmation'],
                    }"
                    :message="validationErrors['password_confirmation']"
                  >
                    <b-input
                      type="password"
                      id="passwordConfirmation"
                      v-model="passwordConfirmation"
                    />
                  </b-field>

                  <h2>Toggl Track</h2>

                  <TogglTrackTokenField />

                  <h2>Google Calendar</h2>

                  <b-field label="Calendar" label-for="google_calendar_id">
                    <b-select
                      v-model="data.google_calendar_id"
                      :disabled="$apollo.queries.settings.loading"
                      :loading="$apollo.queries.settings.loading"
                      v-if="google_calendars.length"
                      expanded
                    >
                      <option
                        v-for="calendar of google_calendars"
                        :key="calendar.id"
                        :value="calendar.id"
                      >
                        {{ calendar.summary }}
                      </option>
                      >
                    </b-select>

                    <span
                      v-if="!google_calendars.length"
                      class="is-block ml-4 mt-2 mb-4 has-text-danger"
                    >
                      Calendars can not be fetched. This is probably caused because no permission
                      was given yet. Permission can be given through the timesheet import.
                    </span>
                  </b-field>

                  <p class="mb-3">
                    Enabled
                    <boolean :value="data.google_calendar_enabled"></boolean>
                  </p>

                  <p>Last imported at {{ data.google_calendar_imported_at | datetime }}</p>
                </div>
              </div>
            </div>

            <div class="layout-sidebar">
              <ul>
                <li>
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': loading }"
                  >
                    Save
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
  </ApolloMutation>
</template>

<script>
import Boolean from '@/components/Boolean.vue';
import { format } from 'date-fns';
import { GET_SETTINGS } from '@/graphql/queries/settings';
import { UPDATE_SETTINGS } from '@/graphql/mutations/update_settings';
import TogglTrackTokenField from '@/components/TogglTrackTokenField';

export default {
  name: 'ProfileEdit',
  components: {
    Boolean,
    TogglTrackTokenField,
  },
  props: ['id'],
  data() {
    return {
      google_calendars: [],

      password: '',
      passwordConfirmation: '',
      togglErrorMessage: '',
      togglLastImport: '',
      togglEnabled: false,
      phone: '',
      birthday: null,
      private_email: '',
      emergency_contact: '',
      validationErrors: {},
      data: {
        toggl_track_token: null,
        toggl_track_enabled: false,
        toggl_track_imported_at: null,
        google_calendar_enabled: false,
        google_calendar_imported_at: null,
        google_calendar_id: null,
      },
      UPDATE_SETTINGS,
    };
  },
  computed: {
    variables() {
      const variables = {
        id: Number(this.id),
        ...(this.password && { password: this.password }),
        ...(this.passwordConfirmation && {
          password_confirmation: this.passwordConfirmation,
        }),
        phone: this.phone,
        birthday: this.birthday ? format(this.birthday, 'yyyy-MM-dd') : null,
        private_email: this.private_email,
        emergency_contact: this.emergency_contact,

        toggl_track_enabled: this.data.toggl_track_enabled,
        toggl_track_imported_at: this.data.toggl_track_imported_at,
        google_calendar_enabled: this.data.google_calendar_enabled,
        google_calendar_imported_at: this.data.google_calendar_imported_at,
        google_calendar_id: this.data.google_calendar_id,
      };

      if (this.data.toggl_track_token) {
        variables.toggl_track_token = this.data.toggl_track_token;
      }

      return variables;
    },
  },
  methods: {
    dateFormatter(date) {
      return date ? format(date, 'yyyy-MM-dd') : null;
    },
    dateParser(date) {
      return date ? new Date(date) : null;
    },
    onDone({ data }) {
      this.phone = data.updateMe.phone;
      this.birthday = data.updateMe.birthday ? new Date(data.updateMe.birthday) : null;
      this.private_email = data.updateMe.private_email;
      this.emergency_contact = data.updateMe.emergency_contact;

      this.password = '';
      this.passwordConfirmation = '';
      this.validationErrors = {};

      this.data = {
        ...this.data,

        // Hide the token.
        toggl_track_token: null,
        toggl_track_enabled: data.updateMe.toggl_track_enabled,
        toggl_track_imported_at: data.updateMe.toggl_track_imported_at,
        google_calendar_enabled: data.updateMe.google_calendar_enabled,
        google_calendar_imported_at: data.updateMe.google_calendar_imported_at,
        google_calendar_id: data.updateMe.google_calendar_id,
      };

      this.$buefy.notification.open({
        message: `Settings have been saved`,
      });
    },
    onError(error) {
      let { graphQLErrors } = error;
      this.validationErrors = {};
      if (graphQLErrors[0].extensions.category === 'validation') {
        this.validationErrors = graphQLErrors[0].extensions.validation;
      }
    },
  },
  apollo: {
    settings: {
      query: GET_SETTINGS,
      variables() {
        if (this.id) {
          return {
            id: this.id,
          };
        }
      },

      update(data) {
        this.phone = data.user.phone;
        this.birthday = data.user.birthday ? new Date(data.user.birthday) : null;
        this.private_email = data.user.private_email;
        this.emergency_contact = data.user.emergency_contact;

        this.data = {
          ...this.data,

          toggl_track_enabled: data.user.toggl_track_enabled,
          toggl_track_imported_at: data.user.toggl_track_imported_at,
          google_calendar_enabled: data.user.google_calendar_enabled,
          google_calendar_imported_at: data.user.google_calendar_imported_at,
          google_calendar_id: data.user.google_calendar_id,
        };

        if (data.user.google_calendar_id === 'primary') {
          this.data.google_calendar_id = data.google_calendars.find(
            (calendar) => !!calendar.primary,
          ).id;
        }

        this.google_calendars = data.google_calendars;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-column {
  min-width: 320px;
  max-width: 540px;
}

.help {
  a {
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.6);
    transition: 0.2s;

    &:hover {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.875);
    }
  }
}

.keep-margin {
  margin: 0;
}
</style>

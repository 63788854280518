<template>
  <ApolloMutation
    :mutation="this.isUpdate() ? UPDATE_TIMESHEET : CREATE_TIMESHEET"
    :variables="this.variables"
    @done="onDone"
    @error="onError"
    class="layout"
  >
    <template v-slot="{ mutate, loading }">
      <div class="layout-header">
        <h1 v-if="isUpdate()">Edit timesheet</h1>
        <h1 v-else>New timesheet</h1>
      </div>
      <div class="layout-content">
        <form @submit.prevent="mutate" @keyup.ctrl.enter="mutate">
          <fieldset :disabled="loading || $apollo.queries.timesheet.loading">
            <div class="columns">
              <div class="column is-half-desktop is-one-third-widescreen">
                <b-field label="User" label-for="user" v-if="canManageProjects()">
                  <b-select
                    id="user"
                    v-model="user_id"
                    :disabled="$apollo.queries.all_users.loading || !canManageProjects()"
                    :loading="$apollo.queries.all_users.loading"
                    expanded
                  >
                    <option v-for="user in users" :key="user.id" :value="user.id">
                      {{ user.first_name }} {{ user.last_name }}
                    </option>
                  </b-select>
                </b-field>

                <b-field
                  label="Date"
                  label-for="date"
                  :type="{ 'is-danger': validationErrors['date'] }"
                  :message="validationErrors['date']"
                >
                  <b-datepicker
                    v-model="date"
                    ref="date"
                    open-on-focus
                    trap-focus
                    :date-formatter="dateFormatter"
                    :date-parser="dateParser"
                  >
                  </b-datepicker>
                </b-field>

                <b-field
                  label="Project"
                  label-for="project"
                  @keydown.enter.prevent.native="(e) => e.preventDefault()"
                >
                  <b-autocomplete
                    v-if="!participation_id"
                    :data="participations"
                    :loading="$apollo.queries.participations.loading"
                    @typing="(input) => (project_name_query = input)"
                    @select="(option) => (participation_id = option.id)"
                    :custom-formatter="participationFormatter"
                    open-on-focus
                    ref="project"
                  />
                  <b-input
                    v-if="participation_id"
                    type="text"
                    :value="participationFormatterById(participation_id)"
                    @focus="initChangeProject"
                  />
                </b-field>

                <b-field
                  label="Hours"
                  label-for="hours"
                  :type="{ 'is-danger': validationErrors['hours'] }"
                  :message="validationErrors['hours']"
                >
                  <b-input
                    type="number"
                    ref="hours"
                    min="0.25"
                    max="12"
                    step="0.25"
                    id="hours"
                    v-model="hours"
                  ></b-input>
                </b-field>

                <b-field
                  label="Description"
                  label-for="description"
                  :type="{ 'is-danger': validationErrors['description'] }"
                  :message="validationErrors['description']"
                >
                  <b-input
                    type="textarea"
                    rows="10"
                    maxlength="1023"
                    id="description"
                    v-model="description"
                  ></b-input>
                </b-field>

                <BField
                  label="Worked from home"
                  label-for="fromHome"
                  :type="validationErrors['description']"
                  :message="validationErrors['description']"
                >
                  <BCheckbox v-model="fromHome" id="fromHome" />
                </BField>

                <div class="has-text-right">
                  <router-link
                    :to="{ name: 'timesheets' }"
                    class="button is-danger is-outlined has-margin-top has-margin-right"
                  >
                    Cancel
                  </router-link>

                  <button
                    @click="go_to_overview = true"
                    type="submit"
                    class="button is-primary is-outlined has-margin-top has-margin-right"
                    :class="{ 'is-loading': loading }"
                  >
                    Save & overview
                  </button>

                  <button
                    type="submit"
                    class="button is-primary has-margin-top"
                    :class="{ 'is-loading': loading }"
                  >
                    {{ isUpdate() ? 'Save & view' : 'Save & Add Another' }}
                  </button>
                </div>

                <p class="py-5 is-italic is-size-6">
                  Hint: Use ctrl + enter to submit your timesheet
                </p>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </template>
  </ApolloMutation>
</template>

<script>
import { format } from 'date-fns';
import { GET_ALL_USERS } from '@/graphql/queries/all_users';
import { GET_PARTICIPATIONS } from '@/graphql/queries/participations';
import { GET_TIMESHEET } from '@/graphql/queries/timesheet';
import { CREATE_TIMESHEET } from '@/graphql/mutations/create_timesheet';
import { UPDATE_TIMESHEET } from '@/graphql/mutations/update_timesheet';

export default {
  name: 'TimesheetEdit',
  components: {},
  props: ['id'],
  data() {
    return {
      user_id: undefined,
      participation_id: -1,
      description: '',
      hours: 0,
      date: new Date(),
      users: [],
      project_name_query: '',
      participations: [],
      go_to_overview: false,
      validationErrors: {},
      fromHome: false,

      CREATE_TIMESHEET,
      UPDATE_TIMESHEET,
    };
  },
  mounted() {
    this.user_id = !this.isUpdate() ? this.$store.getters.me.id : undefined;
    this.participation_id = this.$route.query.participation_id;
  },
  computed: {
    variables() {
      return {
        participation_id: this.participation_id,
        description: this.description,
        hours: parseFloat(this.hours),
        date: format(this.date, 'yyyy-MM-dd'),
        fromHome: this.fromHome,
        ...(this.isUpdate() && { id: this.id }),
      };
    },
    me() {
      return this.$store.getters.me;
    },
  },
  methods: {
    dateFormatter(date) {
      return format(date, 'yyyy-MM-dd');
    },
    dateParser(date) {
      return new Date(date);
    },
    canManageProjects() {
      return this.$store.getters.havePermission('manage projects');
    },
    isUpdate() {
      return this.$route ? this.$route.name === 'edit-timesheet' : false;
    },
    onDone({ data }) {
      if (this.isUpdate()) {
        this.$buefy.notification.open({
          message: `Timesheet has been updated`,
          type: 'is-success',
        });
      } else {
        this.$buefy.notification.open({
          message: `Timesheet has been created`,
          type: 'is-success',
        });
      }
      if (!this.go_to_overview && this.isUpdate()) {
        // we've just edited an existing timesheet, view it
        const id = data.updateTimesheet.id;
        this.$router.push({ name: 'view-timesheet', params: { id } });
      } else if (!this.go_to_overview) {
        // we're creating new timesheets, go for another round
        this.hours = 0;
        this.description = '';
      } else {
        // Navigate to timesheets overview
        this.$router.push({ name: 'timesheets' });
      }
    },
    onError(error) {
      let { graphQLErrors } = error;
      this.validationErrors = {};
      if (graphQLErrors[0].extensions.category === 'validation') {
        this.validationErrors = graphQLErrors[0].extensions.validation;
      }
    },
    participationFormatterById(participation_id) {
      if (this.participations.length !== 0) {
        const participation = this.participations.find(
          (participation) => participation.id === participation_id,
        );

        if (!participation) {
          throw new Error('participationFormatterById could not find id: ' + participation_id);
        }

        return this.participationFormatter(participation);
      }
    },
    participationFormatter(participation) {
      return `${participation.project.client.code}-${participation.project.code}: ${participation.project.name}`;
    },
    initChangeProject() {
      this.participation_id = undefined;
      this.project_name_query = '';
      this.$nextTick(() => {
        this.$refs.project.focus();
      });
    },
  },
  apollo: {
    timesheet: {
      query: GET_TIMESHEET,
      variables() {
        if (this.id) {
          return {
            id: this.id,
          };
        }
      },
      result({ data }) {
        if (data === undefined) return;

        this.participation_id = data.timesheet.participation.id;
        this.description = data.timesheet.description;
        this.hours = data.timesheet.hours;
        this.user_id = data.timesheet.participation.user.id;
        this.date = new Date(data.timesheet.date);
        this.fromHome = data.timesheet.fromHome;
      },
      skip() {
        return !this.isUpdate();
      },
    },
    all_users: {
      query: GET_ALL_USERS,
      result({ data }) {
        this.users = data
          ? data.all_users.sort((a, b) => (a.first_name < b.first_name ? -1 : 1))
          : [];
      },
      skip() {
        return !this.canManageProjects();
      },
    },
    participations: {
      query: GET_PARTICIPATIONS,
      debounce: 250,
      variables() {
        return {
          user_id: parseInt(this.user_id),
          active: !this.isUpdate(),
          formatted_participation: this.project_name_query,
        };
      },
      result({ data }) {
        this.participations = data ? data.participations : [];

        this.participations = this.participations.sort((a, b) =>
          a.project.code < b.project.code ? 1 : -1,
        );

        this.participations = this.participations.sort((a, b) =>
          a.project.client.code < b.project.client.code ? -1 : 1,
        );
      },
      skip() {
        return this.user_id <= 0 || this.$apollo.queries.timesheet.loading;
      },
    },
  },
};
</script>

<template>
  <a @click="verify()">
    <slot>Delete</slot>
  </a>
</template>

<script>
import DeleteModal from '@/components/DeleteModal';
import { DELETE_HOLIDAY_REQUEST } from '@/graphql/mutations/delete_holiday_request';
import { defineComponent, ref, onUnmounted } from '@vue/composition-api';
import { useMutation } from '@vue/apollo-composable';
import { useBuefy } from '@/composables/buefy';

export default defineComponent({
  name: 'DeleteHolidayRequestButton',
  props: {
    holidayRequest: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const buefy = useBuefy(context);
    const modal = ref();

    const { mutate: deleteHolidayRequest } = useMutation(DELETE_HOLIDAY_REQUEST);

    function verify() {
      modal.value = buefy.modal.open({
        component: DeleteModal,
        width: 640,
        events: {
          delete: async () => {
            modal.value.close();

            await deleteHolidayRequest({
              id: props.holidayRequest.id,
            });

            context.emit('done');

            buefy.notification.open({
              message: `Holiday request successfully deleted`,
              type: 'is-success',
            });
          },
        },
      });
    }

    onUnmounted(() => {
      if (modal.value) {
        modal.value.close();
      }
    });

    return {
      verify,
    };
  },
});
</script>

<template>
  <div class="dashboard">
    <div class="dashboard-left">
      <div class="dashboard-chart-header">
        <h1>{{ greeting }}, {{ me.first_name }}</h1>
        <b-field v-if="can(Permission.MANAGE_PROJECTS)">
          <b-radio-button
            v-for="chartType in ChartTypes"
            :key="'radio-button-' + chartType.key"
            :value="chartParams.type === chartType.key"
            @input="updateChartParams(chartType.key, 'type')"
            :native-value="chartParams.type === chartType.key ? true : 'not-selected'"
            type="is-primary is-light"
          >
            <span>{{ chartType.display }}</span>
          </b-radio-button>
        </b-field>
      </div>
      <dashboard-chart
        :params="chartParams"
        @intervalChange="updateChartParams($event, 'interval')"
      />
      <active-projects class="active-projects has-margin-top" />
    </div>
    <div class="dashboard-right">
      <birthdays class="mb-6" />

      <holidays-dashboard-card />

      <holiday-requests class="has-margin-top" />
    </div>
  </div>
</template>

<script>
import ActiveProjects from './active-projects.view.vue';
import Birthdays from './birthdays.view.vue';
import HolidayRequests from './holiday-requests.view.vue';
import { computed, defineComponent, onMounted, reactive } from '@vue/composition-api';
import router from '@/router';
import { useAuth } from '@/composables/auth';
import { Permission } from '@/enums/Permission';
import { ChartTypes } from '@/constants/ChartTypes';
import { ChartIntervals } from '@/constants/ChartIntervals';
import DashboardChart from '@/components/DashboardChart';
import HolidaysDashboardCard from '@/components/HolidaysDashboardCard';

export default defineComponent({
  name: 'Dashboard',
  components: {
    ActiveProjects,
    Birthdays,
    HolidayRequests,
    HolidaysDashboardCard,
    DashboardChart,
  },
  setup() {
    const greeting = computed(() => {
      const date = new Date();
      const h = date.getHours();

      if (h < 6) {
        return 'Good night';
      } else if (h < 12) {
        return 'Good morning';
      } else if (h < 18) {
        return 'Good afternoon';
      } else {
        return 'Good evening';
      }
    });

    const chartParams = reactive({
      type: ChartTypes.PERSONAL.key,
      interval: ChartIntervals.WEEKLY.key,
    });

    const setChartQueryParam = () => {
      const urlParams = new URLSearchParams(window.location.search);
      chartParams.type = urlParams.get('type');
      chartParams.interval = urlParams.get('interval');
    };

    onMounted(() => {
      updateChartParams(localStorage.getItem('type') ?? chartParams.type, 'type');
      updateChartParams(localStorage.getItem('interval') ?? chartParams.interval, 'interval');
      setChartQueryParam();
    });

    const updateChartParams = (input, attribute) => {
      chartParams[attribute] = input;
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get(attribute) !== input) {
        router.push({
          query: { ...Object.fromEntries(urlParams.entries()), [attribute]: input },
        });
        localStorage.setItem(attribute, input);
      }
    };

    const { can } = useAuth();

    return {
      ChartTypes,
      ChartIntervals,
      chartParams,
      greeting,
      updateChartParams,
      can,
      Permission,
    };
  },
});
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 2rem 1rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1rem;
  width: 100%;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }

  &-slider {
    margin: 0 0 2rem 0;
    align-self: center;

    .b-slider {
      margin: 0;
    }

    @media (min-width: 1023px) {
      font-size: 4rem;
      line-height: 3.5rem;
    }
  }

  .dashboard-chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.has-margin-top {
  margin-top: 2rem;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"has-margin mb-4"},[_vm._v("Timesheets")]),(_vm.loading)?[_vm._m(0)]:(_vm.timesheets.length)?[_c('div',{staticClass:"data-table-scroll"},[_c('table',{staticClass:"data-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Team member")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Hours")]),_c('th',{staticStyle:{"max-width":"30%"}},[_vm._v("Description")]),_c('th',[_vm._v("Editable")]),_c('th',{staticClass:"has-text-right",staticStyle:{"width":"10%"}},[(_vm.loading)?_c('div',{staticClass:"loader inline"}):_vm._e()])])]),_vm._l((_vm.timesheets),function(timesheet){return _c('tr',{key:timesheet.id},[_c('td',[_c('router-link',{staticClass:"font-medium hover:text-blue",attrs:{"to":{
                name: 'view-user',
                params: {
                  id: timesheet.participation.user.id,
                },
              }}},[_vm._v(" "+_vm._s(timesheet.participation.user.first_name)+" "+_vm._s(timesheet.participation.user.last_name)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm._f("date")(timesheet.date)))]),_c('td',[_vm._v(_vm._s(_vm._f("hours")(timesheet.hours,2)))]),_c('td',[_c('div',{staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","max-width":"25vw"}},[_vm._v(" "+_vm._s(timesheet.description)+" ")])]),_c('td',[_c('boolean',{attrs:{"value":timesheet.editable}})],1),_c('td',{staticStyle:{"text-align":"right"}},[_c('router-link',{attrs:{"to":{
                name: 'view-timesheet',
                params: { id: timesheet.id },
              }}},[_c('open',{staticClass:"icon icon-inline"})],1),_c('router-link',{attrs:{"to":{
                name: 'edit-timesheet',
                params: { id: timesheet.id },
              }}},[_c('edit',{staticClass:"icon icon-inline"})],1)],1)])})],2)]),(_vm.timesheets.length)?_c('paginator',{attrs:{"page-id":_vm.page,"lastPage":_vm.paginator.lastPage},on:{"update:pageId":function($event){_vm.page=$event},"update:page-id":function($event){_vm.page=$event}}}):_vm._e()]:[_c('div',{staticClass:"empty"},[_vm._v("No timesheets")])]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex is-align-items-center mt-3"},[_c('div',{staticClass:"loader inline mr-4"}),_vm._v(" Loading ")])}]

export { render, staticRenderFns }
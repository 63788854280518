import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import { onError } from 'apollo-link-error';
import Router from './router';
import { NotificationProgrammatic as Notification } from 'buefy';

// Install the vue plugin
Vue.use(VueApollo);

/*
const errorLink = onError(({ networkError }) => {
  if (networkError.statusCode === 401) {
    logout();
  }
});
*/
// Error handler
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions, message }) => {
      if (extensions && extensions.category === 'unauthenticated') {
        localStorage.removeItem(AUTH_TOKEN);

        if (Router.currentRoute.name !== 'login') {
          Notification.open({
            duration: 5000,
            message: `You've been logged out`,
            type: 'is-danger',
          });

          Router.push('/login');
        }
      } else if (extensions && extensions.category === 'validation') {
        // Display a notification for all validation errors
        for (var key of Object.keys(extensions.validation)) {
          extensions.validation[key].forEach((msg) =>
            Notification.open({
              duration: 5000,
              message: msg,
              type: 'is-danger',
            }),
          );
        }
      } else if (extensions && extensions.category === 'google calendar unauthorized') {
        //
      } else {
        Notification.open({
          duration: 5000,
          message: `Error: ` + message,
          type: 'is-danger',
        });
      }
    });
  } else if (networkError) {
    switch (networkError.statusCode) {
      case 401:
      case 403:
        localStorage.removeItem(AUTH_TOKEN);

        Notification.open({
          duration: 5000,
          message: `You've been logged out`,
          type: 'is-danger',
        });

        Router.push('/login');
        break;
      case 500:
        Notification.open({
          duration: 5000,
          message: `Server error`,
          type: 'is-danger',
        });
        break;
      default:
    }
  }
});

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:8000/graphql';
//const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://192.168.0.113:8000/graphql'

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  //wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:8000/graphql',
  wsEndpoint: null,

  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: errorLink,

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
        //fetchPolicy: 'cache',
        errorPolicy: 'all',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message,
      );
    },
  });
}

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);

  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);

  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}

// Checks if user is authenticated
export function isAuthenticated() {
  return typeof localStorage.getItem(AUTH_TOKEN) === 'string';
}

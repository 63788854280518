<template>
  <ApolloQuery :query="GET_HOLIDAY_REQUESTS" :variables="variables">
    <template slot-scope="{ result: { data }, isLoading }">
      <div class="loader full" v-if="isLoading"></div>
      <div v-else>
        <h2>Your holiday requests</h2>
        <ul>
          <li v-for="holiday_request of data.holiday_requests.data" :key="holiday_request.id">
            <router-link :to="{ name: 'view-holiday-request', params: { id: holiday_request.id } }">
              {{ holiday_request.start_at | dateMedium }} -
              {{ holiday_request.end_at | dateMedium }} - {{ holiday_request.hour_sum | hours }} -
              {{ holiday_request.approved_at ? 'Approved' : 'Pending' }}
            </router-link>
          </li>
          <li v-if="data.holiday_requests.paginatorInfo.total > data.holiday_requests.data.length">
            <router-link :to="{ name: 'holiday-requests' }">
              And several more ...
            </router-link>
          </li>
          <li v-if="data.holiday_requests.paginatorInfo.total == 0">
            <i>You have not requested a holiday yet</i>
          </li>
        </ul>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import { format } from 'date-fns';
import { GET_HOLIDAY_REQUESTS } from '@/graphql/queries/holiday_requests';

export default {
  name: 'HolidayRequests',
  methods: {},
  data() {
    return {
      GET_HOLIDAY_REQUESTS,
    };
  },
  computed: {
    variables() {
      return {
        page: 1,
        orderBy: { column: 'START_AT', order: 'ASC' },
        where: {
          AND: [
            {
              column: 'END_AT',
              operator: 'GTE',
              value: format(new Date(), 'yyyy-MM-dd'),
            },
            {
              column: 'REQUESTER_ID',
              operator: 'EQ',
              value: this.$store.getters.me.id,
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>

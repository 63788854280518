<template>
  <div />
</template>
<script>
import { onLogin } from '@/vue-apollo';
import { GET_ME } from '@/graphql/queries/me.ts';

export default {
  name: 'Authorize',

  async mounted() {
    const token = this.$route.query.token;

    if (!token) {
      return this.$router.push('/');
    }

    await onLogin(this.$apollo.provider.defaultClient, token);

    await this.$apollo
      .query({
        query: GET_ME,
      })
      .then(({ data }) => {
        this.$store.commit('logged_in_user', data.me);
      });

    await this.$router.push('/');
  },
};
</script>

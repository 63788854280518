<template>
  <button
    type="button"
    class="button is-danger"
    :class="{ 'is-loading': loading }"
    :disabled="loading"
    @click="value ? unblock() : block()"
  >
    <barrier-block-off-icon stroke-width="1.4" width="20" height="20" v-if="value" />
    <barrier-block-icon stroke-width="1.4" width="20" height="20" v-else />

    {{ value ? 'Unblock' : 'Block' }}
  </button>
</template>

<script>
import ConfirmModal from '@/components/ConfirmModal';
import { BarrierBlockIcon, BarrierBlockOffIcon } from 'vue-tabler-icons';
import { BLOCK_USER } from '@/graphql/mutations/users/block_user';
import { UNBLOCK_USER } from '@/graphql/mutations/users/unblock_user';

export default {
  components: {
    BarrierBlockIcon,
    BarrierBlockOffIcon,
  },

  props: {
    id: {
      required: true,
      type: Number,
    },
    blocked: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      value: false,
      loading: false,
    };
  },

  mounted() {
    this.value = this.blocked;
  },

  methods: {
    async block() {
      await this.$buefy.modal.open({
        parent: this,
        component: ConfirmModal,
        hasModalCard: true,
        trapFocus: true,
        width: 640,
        props: {
          message: "The user can not login anymore and it's Toggl Track token will be removed.",
          action: () => {
            this.loading = true;

            return this.$apollo
              .mutate({
                mutation: BLOCK_USER,

                variables: {
                  id: this.id,
                },
              })
              .then(() => {
                this.value = true;

                this.$buefy.notification.open({
                  message: 'User has been blocked.',
                  type: 'is-success',
                });
              })
              .catch(() => {
                this.$buefy.notification.open({
                  message: 'Could not block user.',
                  type: 'is-danger',
                });
              })
              .finally(() => {
                this.loading = false;
              });
          },
        },
      });
    },

    unblock() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: UNBLOCK_USER,

          variables: {
            id: this.id,
          },
        })
        .then(() => {
          this.value = false;

          this.$buefy.notification.open({
            message: 'User has been unblocked.',
            type: 'is-success',
          });
        })
        .catch(() => {
          this.$buefy.notification.open({
            message: 'Could not unblock user.',
            type: 'is-danger',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .icon {
    margin: 0 0.5em 0 -0.1em !important;
  }
}
</style>

<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M3 14c.83 .642 2.077 1.017 3.5 1c1.423 .017 2.67 -.358 3.5 -1c.83 -.642 2.077 -1.017 3.5 -1c1.423 -.017 2.67 .358 3.5 1"
      ></path>
      <path d="M8 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2"></path>
      <path d="M12 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2"></path>
      <path d="M3 10h14v5a6 6 0 0 1 -6 6h-2a6 6 0 0 1 -6 -6v-5z"></path>
      <path d="M16.746 16.726a3 3 0 1 0 .252 -5.555"></path>
    </svg>

    <h2 class="mt-4 mb-3" style="font-weight: normal;">
      End the coffee break.
    </h2>
    <p>
      And start creating time entries.
    </p>

    <button class="button mt-5" @click="$parent.refresh()">
      Refresh
    </button>
  </div>
</template>

<script>
export default {
  props: ['provider'],
};
</script>

<style lang="scss" scoped>
div {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>

<template>
  <div class="layout has-sidebar">
    <div class="layout-header">
      <h1>Users</h1>
    </div>
    <div class="layout-content">
      <div class="columns">
        <ApolloQuery
          :query="GET_USERS"
          :variables="{ page: pageId, orderBy: order }"
          class="column is-narrow is-max-full"
        >
          <template slot-scope="{ result: { data }, isLoading, query }">
            <div class="data-table-scroll" v-if="data && data.users">
              <table class="data-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <order-column
                      :order.sync="order"
                      @update:order="query.refetch()"
                      column-name="EMAIL"
                    >
                      Email
                    </order-column>
                    <th>
                      Active
                    </th>
                    <th class="has-text-right">
                      <div class="loader inline" v-if="isLoading"></div>
                    </th>
                  </tr>
                </thead>
                <tr v-for="user of data.users.data" :key="user.id">
                  <td>
                    <router-link
                      :to="{ name: 'view-user', params: { id: user.id } }"
                      class="font-medium hover:text-blue"
                    >
                      {{ user.first_name }} {{ user.last_name }}
                    </router-link>
                  </td>
                  <td>{{ user.email }}</td>
                  <td>
                    <user-blocked-status :value="!!user.blocked" />
                  </td>
                  <td>
                    <router-link :to="{ name: 'view-user', params: { id: user.id } }">
                      <Open class="icon icon-inline"></Open>
                    </router-link>
                    <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
                      <Edit class="icon icon-inline"></Edit>
                    </router-link>
                    <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
                      <Delete class="icon icon-inline"></Delete>
                    </router-link>
                  </td>
                </tr>
              </table>
            </div>

            <paginator
              v-if="data && data.users"
              :pageId.sync="pageId"
              :lastPage="data.users.paginatorInfo.lastPage"
            />
          </template>
        </ApolloQuery>
      </div>
    </div>
    <div class="layout-sidebar">
      <ul>
        <li>
          <router-link class="button is-primary" :to="{ name: 'new-user' }">
            New User
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Paginator from '@/components/Paginator.vue';
import OrderColumn from '@/components/OrderColumn.vue';
import Open from '@/assets/icons/view.svg';
import Edit from '@/assets/icons/edit.svg';
import Delete from '@/assets/icons/delete.svg';
import UserBlockedStatus from '@/components/UserBlockedStatus';
import { GET_USERS } from '@/graphql/queries/users';

export default {
  name: 'Users',
  components: {
    Paginator,
    OrderColumn,
    Open,
    Edit,
    Delete,
    UserBlockedStatus,
  },
  data() {
    return {
      pageId: 1,
      order: { column: 'EMAIL', order: 'ASC' },
      GET_USERS,
    };
  },
};
</script>

<template>
  <div class="status" :style="{ '--status-color': color }">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'status',
  props: {
    value: {
      default() {
        return null;
      },
    },
    options: {
      type: Array,
    },
  },
  computed: {
    color() {
      if (this.value === null) return 'gray';

      return this.options.find(({ value }) => value === this.value)?.color;
    },

    label() {
      if (this.value === null) return null;

      const option = this.options.find(({ value }) => value === this.value);
      return option?.label === undefined ? option?.value : option?.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: inline-block;

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    vertical-align: middle;
    margin-right: 6px;
    box-shadow: 0 0 4px 0 var(--status-color);
    background: var(--status-color);
  }
}
</style>

<template>
  <div class="loading is-full" v-if="$apollo.queries.project.loading"></div>
  <div v-else class="layout has-sidebar">
    <div class="layout-header">
      <h1>Edit team for {{ project_name }}</h1>
    </div>

    <div class="layout-content">
      <div class="columns">
        <div class="column">
          <div class="data-table-scroll">
            <table class="data-table">
              <tr>
                <th>Team Member</th>
                <th>Hours for project</th>
                <th>Actions</th>
              </tr>
              <tr v-for="(participation, index) in participations" :key="index">
                <th>
                  <router-link
                    :to="{
                      name: 'view-user',
                      params: { id: participation.user.id },
                    }"
                  >
                    {{ participation.user.first_name }}
                    {{ participation.user.last_name }}
                  </router-link>
                </th>
                <td>
                  <ApolloMutation
                    :mutation="UPDATE_PARTICIPATION"
                    :variables="{
                      id: participation.id,
                      hours: participation.hours,
                    }"
                    @done="onDoneUpdate"
                    @error="onErrorUpdate"
                  >
                    <template v-slot="{ mutate, loading }">
                      <form @submit.prevent="mutate()">
                        <fieldset :disabled="loading">
                          <b-numberinput
                            v-model="participation.hours"
                            type="number"
                            min="0"
                            :controls="false"
                            @change.native="mutate()"
                          />
                        </fieldset>
                      </form>
                    </template>
                  </ApolloMutation>
                </td>
                <td>
                  <b-progress
                    :value="participation.worked_hours"
                    :max="participation.hours"
                    size="is-medium"
                    :type="progress_type(participation)"
                    show-value
                  >
                    {{ participation.worked_hours }} /
                    {{ participation.hours }}
                  </b-progress>
                </td>
                <td>
                  <ApolloMutation
                    :mutation="DELETE_PARTICIPATION"
                    :variables="{ id: participation.id }"
                    @done="onDoneRemove"
                    @error="onErrorRemove"
                  >
                    <template v-slot="{ mutate, loading }">
                      <button
                        class="button is-danger is-outlined is-small"
                        :class="{ 'is-disabled': loading }"
                        @click="mutate()"
                      >
                        Remove
                      </button>
                    </template>
                  </ApolloMutation>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-sidebar">
      <ul>
        <li>
          <router-link
            :to="{ name: 'view-project', params: { id: id } }"
            class="button is-primary is-outlined"
          >
            Back to project
          </router-link>
        </li>
        <li class="break">
          <ApolloMutation
            :mutation="CREATE_PARTICIPATION"
            :variables="{ project_id: this.id, user_id: this.user_id }"
            @done="onDoneAdd"
            @error="onErrorAdd"
          >
            <template v-slot="{ mutate, loading }">
              <form @submit.prevent="mutate()">
                <fieldset :disabled="loading || $apollo.queries.all_users.loading">
                  <b-field label="Add team member" label-for="team_member">
                    <b-select
                      id="team_member"
                      v-model="user_id"
                      :disabled="$apollo.queries.all_users.loading"
                      :loading="$apollo.queries.all_users.loading"
                    >
                      <option v-for="user in users" :key="user.id" :value="user.id">
                        {{ user.first_name }} {{ user.last_name }}
                      </option>
                    </b-select>
                  </b-field>
                  <button type="submit" class="button is-primary">
                    Add team member
                  </button>
                </fieldset>
              </form>
            </template>
          </ApolloMutation>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { GET_ALL_USERS } from '@/graphql/queries/all_users';
import { GET_PROJECT } from '@/graphql/queries/projects/project';
import { CREATE_PARTICIPATION } from '@/graphql/mutations/projects/create_participation';
import { UPDATE_PARTICIPATION } from '@/graphql/mutations/projects/update_participation';
import { DELETE_PARTICIPATION } from '@/graphql/mutations/projects/delete_participation';

export default {
  name: 'ParticipationEdit',
  props: ['id'],
  data() {
    return {
      project_name: '',
      user_id: -1,
      users: [],
      participations: [],
      CREATE_PARTICIPATION,
      UPDATE_PARTICIPATION,
      DELETE_PARTICIPATION,
    };
  },
  methods: {
    progress_type(team_member) {
      if (team_member.worked_hours > team_member.hours) {
        return 'is-danger';
      }

      if (team_member.worked_hours > 0.9 * team_member.hours) {
        return 'is-warning';
      }

      return 'is-primary';
    },
    onDoneAdd({ data }) {
      this.participations.push(data.createParticipation);
      this.$buefy.notification.open({
        message: data.createParticipation.user.first_name + ' added',
      });
    },
    onErrorAdd() {
      this.$buefy.notification.open({
        message: 'Could not add user',
        type: 'is-danger',
      });
    },
    onDoneUpdate({ data }) {
      this.$buefy.notification.open({
        message:
          'Hours for ' +
          data.updateParticipation.user.first_name +
          ' changed to ' +
          data.updateParticipation.hours,
      });
    },
    onErrorUpdate() {
      this.$buefy.notification.open({
        message: 'Something went wrong updating the hours',
        type: 'is-danger',
      });
    },
    onDoneRemove({ data }) {
      let id = data.deleteParticipation;
      this.participations = this.participations.filter((item) => item.id != id);
      this.$buefy.notification.open({
        message: 'Team member removed',
      });
    },
    onErrorRemove(error) {
      let { graphQLErrors } = error;
      if (graphQLErrors[0].extensions.category === 'validation') {
        this.$buefy.notification.open({
          message: graphQLErrors[0].extensions.validation.id[0],
          type: 'is-danger',
        });
      }
    },
  },
  apollo: {
    project: {
      query: GET_PROJECT,
      variables() {
        return {
          id: this.id,
        };
      },
      update(data) {
        this.project_name = data.project.name;
        this.participations = data.project.team_members;
      },
    },
    all_users: {
      query: GET_ALL_USERS,
      update(data) {
        this.users = data.all_users
          .filter(
            (user) =>
              !this.participations.find((participation) => participation.user.id === user.id),
          )
          .sort((a, b) => (a.first_name < b.first_name ? -1 : 1));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
li.break {
  padding-top: 1rem;
}

.progress-wrapper {
  min-width: 180px;
}
</style>

<template>
  <div>
    <h2 class="has-margin mb-4">Timesheets</h2>

    <template v-if="loading">
      <div class="is-flex is-align-items-center mt-3">
        <div class="loader inline mr-4" />
        Loading
      </div>
    </template>

    <template v-else-if="timesheets.length">
      <div class="data-table-scroll">
        <table class="data-table">
          <thead>
            <tr>
              <th>Team member</th>
              <th>Date</th>
              <th>Hours</th>
              <th style="max-width: 30%;">Description</th>
              <th>Editable</th>
              <th class="has-text-right" style="width: 10%">
                <div class="loader inline" v-if="loading" />
              </th>
            </tr>
          </thead>
          <tr v-for="timesheet of timesheets" :key="timesheet.id">
            <td>
              <router-link
                :to="{
                  name: 'view-user',
                  params: {
                    id: timesheet.participation.user.id,
                  },
                }"
                class="font-medium hover:text-blue"
              >
                {{ timesheet.participation.user.first_name }}
                {{ timesheet.participation.user.last_name }}
              </router-link>
            </td>
            <td>{{ timesheet.date | date }}</td>
            <td>{{ timesheet.hours | hours(2) }}</td>
            <td>
              <div style="text-overflow: ellipsis; overflow:hidden; max-width: 25vw;">
                {{ timesheet.description }}
              </div>
            </td>
            <td>
              <boolean :value="timesheet.editable" />
            </td>
            <td style="text-align: right">
              <!-- View -->
              <router-link
                :to="{
                  name: 'view-timesheet',
                  params: { id: timesheet.id },
                }"
              >
                <open class="icon icon-inline" />
              </router-link>

              <!-- Edit -->
              <router-link
                :to="{
                  name: 'edit-timesheet',
                  params: { id: timesheet.id },
                }"
              >
                <edit class="icon icon-inline" />
              </router-link>
            </td>
          </tr>
        </table>
      </div>

      <paginator v-if="timesheets.length" :page-id.sync="page" :lastPage="paginator.lastPage" />
    </template>

    <template v-else>
      <div class="empty">No timesheets</div>
    </template>
  </div>
</template>

<script>
import Paginator from '@/components/Paginator';
import Boolean from '@/components/Boolean';
import Open from '@/assets/icons/view.svg';
import Edit from '@/assets/icons/edit.svg';
import { GET_TIMESHEETS_FOR_PROJECT } from '@/graphql/queries/timesheets_for_project';

export default {
  components: {
    Paginator,
    Boolean,

    // Icons
    Open,
    Edit,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      page: 1,

      /**
       * @type Array<{ participation: { user: { id: Number, first_name: String, last_name: String } } }>
       */
      timesheets: [],

      /**
       * @type {{ lastPage: Number }}
       */
      paginator: {
        lastPage: 1,
      },
    };
  },

  mounted() {
    this.loading = true;

    this.$apollo
      .query({
        query: GET_TIMESHEETS_FOR_PROJECT,

        variables: {
          projectId: this.projectId,
          page: this.page,
        },
      })
      .then(
        ({
          data: {
            project: {
              timesheets: { data: timesheets, paginatorInfo: paginator },
            },
          },
        }) => {
          this.timesheets = timesheets;
          this.paginator = paginator;
          //
        },
      )
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

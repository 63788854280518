var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{attrs:{"query":_vm.GET_TIMESHEET,"variables":{ id: _vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"layout has-sidebar"},[_c('div',{staticClass:"layout-header"},[_c('h1',[_vm._v("Timesheet")])]),_c('div',{staticClass:"layout-content"},[(isLoading)?_c('div',{staticClass:"loader full"}):_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-narrow is-max-full"},[_c('div',{staticClass:"data-table-scroll"},[_c('table',{staticClass:"data-table"},[_c('tr',[_c('th',[_vm._v("User")]),_c('td',[_c('router-link',{staticClass:"font-medium hover:text-blue",attrs:{"to":{
                        name: 'view-user',
                        params: {
                          id: data.timesheet.participation.user.id,
                        },
                      }}},[_vm._v(" "+_vm._s(data.timesheet.participation.user.first_name)+" "+_vm._s(data.timesheet.participation.user.last_name)+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Project")]),_c('td',[_c('router-link',{staticClass:"font-medium hover:text-blue",attrs:{"to":{
                        name: 'view-project',
                        params: {
                          id: data.timesheet.participation.project.id,
                        },
                      }}},[_c('strong',[_vm._v(_vm._s(data.timesheet.participation.project.display_code))]),_vm._v(" "+_vm._s(data.timesheet.participation.project.name)+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Date")]),_c('td',[_vm._v(_vm._s(_vm._f("date")(data.timesheet.date)))])]),_c('tr',[_c('th',[_vm._v("Hours")]),_c('td',[_vm._v(_vm._s(_vm._f("hours")(data.timesheet.hours,2)))])]),_c('tr',[_c('th',[_vm._v("Description")]),_c('td',[_c('blockquote',{staticClass:"description"},[_vm._v(" "+_vm._s(data.timesheet.description)+" ")])])]),_c('tr',[_c('th',[_vm._v("Editable")]),_c('td',[_c('boolean',{attrs:{"value":data.timesheet.editable}})],1)]),_c('tr',[_c('th',[_vm._v("Worked from home")]),_c('td',[_c('boolean',{attrs:{"value":data.timesheet.fromHome}})],1)]),_c('tr',[_c('th',[_vm._v("Created at")]),_c('td',[_vm._v(_vm._s(_vm._f("datetime")(data.timesheet.created_at)))])]),_c('tr',[_c('th',[_vm._v("Last updated")]),_c('td',[_vm._v(_vm._s(_vm._f("datetime")(data.timesheet.updated_at)))])])])])])])]),(!isLoading && data.timesheet.editable)?_c('div',{staticClass:"layout-sidebar"},[_c('ul',[(!isLoading && data.timesheet.editable)?_c('li',[_c('router-link',{staticClass:"button is-primary",attrs:{"to":{ name: 'edit-timesheet', params: { id: _vm.id } }}},[_vm._v(" Edit ")])],1):_vm._e(),(!isLoading && data.timesheet.editable)?_c('li',[_c('delete-timesheet-button',{staticClass:"button is-danger",attrs:{"timesheet":data.timesheet},on:{"done":function($event){return _vm.deleted()}}})],1):_vm._e()])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <ApolloMutation
    :mutation="this.isUpdate() ? UPDATE_USER : CREATE_USER"
    :variables="this.variables"
    @done="onDone"
    @error="onError"
  >
    <template v-slot="{ mutate, loading }">
      <form @submit.prevent="mutate()">
        <fieldset :disabled="loading || $apollo.queries.user.loading">
          <div class="layout has-sidebar">
            <div class="layout-header">
              <h1 v-if="isUpdate()">Edit user</h1>
              <h1 v-else>New user</h1>
            </div>

            <div class="layout-content">
              <div class="columns is-multiline">
                <div class="column info-column">
                  <b-field
                    label="First name"
                    label-for="firstName"
                    :type="{ 'is-danger': validationErrors['first_name'] }"
                    :message="validationErrors['first_name']"
                  >
                    <b-input type="text" id="firstName" v-model="firstName"></b-input>
                  </b-field>

                  <b-field
                    label="Last name"
                    label-for="lastName"
                    :type="{ 'is-danger': validationErrors['last_name'] }"
                    :message="validationErrors['last_name']"
                  >
                    <b-input type="text" id="lastName" v-model="lastName"></b-input>
                  </b-field>

                  <b-field
                    label="E-mail"
                    label-for="email"
                    :type="{ 'is-danger': validationErrors['email'] }"
                    :message="validationErrors['email']"
                  >
                    <b-input type="text" id="email" v-model="email"></b-input>
                  </b-field>
                </div>

                <div class="column notes-column">
                  <h2>NAW data</h2>

                  <b-field
                    label="Phone"
                    label-for="phone"
                    :type="{ 'is-danger': validationErrors['phone'] }"
                    :message="validationErrors['phone']"
                  >
                    <b-input type="text" id="phone" v-model="phone" />
                  </b-field>

                  <b-field label="Birthday">
                    <b-datepicker
                      v-model="birthday"
                      :max-date="new Date()"
                      open-on-focus
                      trap-focus
                      :date-formatter="dateFormatter"
                      :date-parser="dateParser"
                    />
                  </b-field>

                  <b-field
                    label="Private email"
                    label-for="private_email"
                    :type="{ 'is-danger': validationErrors['private_email'] }"
                    :message="validationErrors['private_email']"
                  >
                    <b-input type="text" id="private_email" v-model="private_email" />
                  </b-field>
                  <b-field
                    label="Emergency contact"
                    label-for="emergency_contact"
                    :type="{ 'is-danger': validationErrors['emergency_contact'] }"
                    :message="validationErrors['emergency_contact']"
                  >
                    <b-input type="text" id="emergency_contact" v-model="emergency_contact" />
                  </b-field>
                </div>
              </div>
            </div>

            <div class="layout-sidebar">
              <ul>
                <li>
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': loading }"
                  >
                    Save
                  </button>
                </li>
                <template v-if="isUpdate()">
                  <li>
                    <router-link
                      :to="{ name: 'view-user', params: { id: id } }"
                      v-if="isUpdate()"
                      class="button is-danger is-outlined"
                    >
                      Cancel
                    </router-link>
                  </li>
                </template>
                <li v-else>
                  <router-link :to="{ name: 'users' }" class="button is-danger is-outlined">
                    Cancel
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
  </ApolloMutation>
</template>

<script>
import { format } from 'date-fns';
import { GET_USER } from '@/graphql/queries/users/user';
import { CREATE_USER } from '@/graphql/mutations/create_user';
import { UPDATE_USER } from '@/graphql/mutations/update_user';

export default {
  name: 'UserEdit',

  props: ['id'],
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      validationErrors: {},
      phone: '',
      birthday: null,
      private_email: '',
      emergency_contact: '',
      blocked: false,
      CREATE_USER,
      UPDATE_USER,
    };
  },
  computed: {
    variables() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        ...(this.password && { password: this.password }),
        ...(this.passwordConfirmation && {
          password_confirmation: this.passwordConfirmation,
        }),
        ...(this.isUpdate() && { id: this.id }),
        phone: this.phone,
        birthday: this.birthday ? format(this.birthday, 'yyyy-MM-dd') : null,
        private_email: this.private_email,
        emergency_contact: this.emergency_contact,
      };
    },
  },
  methods: {
    dateFormatter(date) {
      return date ? format(date, 'yyyy-MM-dd') : null;
    },
    dateParser(date) {
      return date ? new Date(date) : null;
    },
    isUpdate() {
      return this.$route ? this.$route.name === 'edit-user' : false;
    },
    onDone(data) {
      let id = this.isUpdate() ? data.data.updateUser.id : data.data.createUser.id;
      this.$router.push({ name: 'view-user', params: { id: id } });
    },
    onError(error) {
      let { graphQLErrors } = error;
      this.validationErrors = {};
      if (graphQLErrors[0].extensions.category === 'validation') {
        this.validationErrors = graphQLErrors[0].extensions.validation;
      }
    },
  },
  apollo: {
    user: {
      query: GET_USER,
      variables() {
        if (this.id) {
          return {
            id: this.id,
          };
        }
      },
      result({ data }) {
        this.firstName = data.user.first_name;
        this.lastName = data.user.last_name;
        this.email = data.user.email;
        this.phone = data.user.phone;
        this.birthday = data.user.birthday ? new Date(data.user.birthday) : null;
        this.private_email = data.user.private_email;
        this.emergency_contact = data.user.emergency_contact;
        this.blocked = data.user.blocked;
      },
      skip() {
        return !this.isUpdate();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-column {
  min-width: 320px;
  max-width: 420px;
}

.notes-column {
  min-width: 320px;
  max-width: 960px;
}
</style>

<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 24 24"
      stroke-width="1"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"
      ></path>
      <circle cx="12" cy="12" r="3"></circle>
    </svg>

    <h2 class="mt-4 mb-3" style="font-weight: normal;">
      Set up needed
    </h2>
    <p v-if="provider === 'TOGGL_TRACK'" class="has-text-centered">
      You probably haven't provided your api token in the settings.
    </p>

    <p v-if="provider === 'GOOGLE_CALENDAR'" class="has-text-centered">
      You should probably try the classic
      <i>"have you tried logging out and logging back in again"</i>.<br />
      But without joking, your calendar token should probably be retrieved, and this is done when<br />
      starting a new session.
    </p>

    <router-link
      :to="{ name: 'profile-settings', params: { id: me.id } }"
      v-if="provider === 'TOGGL_TRACK'"
      class="button mt-5"
    >
      Settings
    </router-link>

    <router-link
      :to="{ name: 'account', params: { id: me.id } }"
      v-if="provider === 'GOOGLE_CALENDAR'"
      class="button mt-5"
    >
      Account
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['provider'],
};
</script>

<style lang="scss" scoped>
div {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>

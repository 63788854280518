<template>
  <div
    class="bar"
    :class="{
      'left-clipped': isLeftClipped,
      'right-clipped': isRightClipped,
      'info-right-clipped': isInfoRightClipped,
      'is-my-project': isMyProject,
    }"
    :style="{
      'margin-left': offset,
      width: width,
      'padding-right': paddingRight,
    }"
  >
    <div
      class="bar-internal"
      :class="{ 'right-clipped': isInternalRightClipped }"
      :style="{ width: internalWidth }"
    >
      <div class="bar-content">
        <router-link
          v-if="internalWidth != '0px'"
          :to="{ name: 'view-project', params: { id: project.id } }"
        >
          {{ project.display_code }}
        </router-link>

        <div class="bar-info">
          <h4>{{ project.name }}</h4>
          <table>
            <tr>
              <th>Start Date</th>
              <td>{{ project.start_date | date }}</td>
            </tr>
            <tr>
              <th>Internal Due Date</th>
              <td>{{ project.internal_delivery_date | date }}</td>
            </tr>
            <tr>
              <th>External Due Date</th>
              <td>{{ project.external_delivery_date | date }}</td>
            </tr>
            <tr>
              <th>Hours</th>
              <td>
                {{ project.total_worked_hours }}/{{ project.total_available_hours }} ({{
                  (project.total_worked_hours / project.total_available_hours) | percentage
                }})
              </td>
            </tr>
          </table>

          <div
            class="team-member"
            v-for="team_member in project.team_members"
            :key="team_member.id"
          >
            <img
              :src="team_member.user.avatar || '/images/avatar.svg'"
              :alt="team_member.user.first_name + ' ' + team_member.user.last_name"
            />
            <div class="team-member-name">
              {{ team_member.user.first_name }} {{ team_member.user.last_name }}
            </div>
            <b-progress
              :value="team_member.worked_hours"
              :max="team_member.hours"
              size="is-medium"
              :type="progress_type(team_member)"
              show-value
              v-if="team_member.worked_hours"
            >
              {{ team_member.worked_hours }} / {{ team_member.hours }}
            </b-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isBefore, isAfter, differenceInCalendarDays, max, min } from 'date-fns';

export default {
  name: 'PlanningProject',
  props: {
    project: {},
    pixelsPerDay: Number,
    offsetDate: {},
    viewStartDate: {},
    viewEndDate: {},
  },
  methods: {
    progress_type(team_member) {
      if (team_member.worked_hours > team_member.hours) {
        return 'is-danger';
      }

      if (team_member.worked_hours > 0.9 * team_member.hours) {
        return 'is-warning';
      }

      return 'is-primary';
    },
  },
  computed: {
    isMyProject() {
      if (!this.project || (this.project && this.project.team_members.length === 0) || !this.me) {
        return false;
      }

      return this.project.team_members.some((teamMember) => teamMember.user.id === this.me.id);
    },
    startDate() {
      return new Date(this.project.start_date);
    },
    internalDate() {
      return new Date(this.project.internal_delivery_date);
    },
    externalDate() {
      return new Date(this.project.external_delivery_date);
    },
    isLeftClipped() {
      return isBefore(this.startDate, this.viewStartDate);
    },
    isRightClipped() {
      return isAfter(this.externalDate, this.viewEndDate);
    },
    isInternalRightClipped() {
      return isAfter(this.internalDate, this.viewEndDate);
    },
    isInfoRightClipped() {
      return differenceInCalendarDays(this.viewEndDate, this.startDate) * this.pixelsPerDay < 360;
    },
    offsetDateClipped() {
      return max([this.offsetDate, this.viewStartDate]);
    },
    startDateClipped() {
      return max([this.startDate, this.viewStartDate]);
    },
    externalDateClipped() {
      return min([this.externalDate, this.viewEndDate]);
    },
    internalDateClipped() {
      if (isAfter(this.internalDate, this.viewEndDate)) {
        return this.viewEndDate;
      } else if (isBefore(this.internalDate, this.viewStartDate)) {
        return this.viewStartDate;
      }

      return this.internalDate;
    },
    offset() {
      return (
        differenceInCalendarDays(this.startDateClipped, this.offsetDateClipped) *
          this.pixelsPerDay +
        'px'
      );
    },
    paddingRight() {
      return (
        differenceInCalendarDays(this.externalDateClipped, this.internalDateClipped) *
          this.pixelsPerDay +
        'px'
      );
    },
    width() {
      return (
        differenceInCalendarDays(this.externalDateClipped, this.startDateClipped) *
          this.pixelsPerDay +
        'px'
      );
    },
    internalWidth() {
      return (
        differenceInCalendarDays(this.internalDateClipped, this.startDateClipped) *
          this.pixelsPerDay +
        'px'
      );
    },
  },
};
</script>

<style scoped lang="scss">
$height: 36px;
$infoWidth: 360px;
$radius: 18px;

.bar {
  height: $height;
  border: 2px solid #39485c;
  border-radius: $radius;
  position: relative;
  transition: 0.2s;

  &-internal {
    position: relative;
    height: $height;
    border-radius: $radius;
    background: transparentize(#2b7eed, 0.15);
    transition: 0.2s;
    margin-top: -2px;
    margin-left: -2px;

    &.left-clipped {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.right-clipped {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.is-my-project {
    .bar-internal {
      background: #7d0aff;
    }
  }

  &.left-clipped {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    .bar-internal {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.right-clipped {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  &-content {
    position: sticky;
    left: 0;
    max-width: 100%;
    display: inline-block;
    line-height: $height;

    a {
      padding: 0 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: rgba(255, 255, 255, 0.875);
      transition: 0.2s;
      display: inline-block;
      max-width: 100%;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-info {
    position: absolute;
    left: 6px;
    top: $height + 6px;
    right: 6px;
    width: $infoWidth;

    background-color: #243143;
    border-radius: $radius;
    border: 2px solid rgba(255, 255, 255, 0.6);
    padding: 1rem;
    font-size: 0.9rem;
    display: none;
    line-height: normal;

    h4 {
      margin-bottom: 1rem;
      text-align: center;
      font-weight: bold;
      font-size: 1rem;
    }

    table,
    th,
    td {
      color: rgba(255, 255, 255, 0.875);
    }

    th {
      padding-right: 1rem;
    }

    table {
      margin-bottom: 1rem;
    }

    .team-member {
      display: grid;
      grid-template-columns: 24px auto;
      grid-template-rows: 24px auto;
      column-gap: 0.5rem;
      row-gap: 0.5rem;
      margin-top: 1rem;
      align-items: center;

      img {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 24px;
        width: 24px;
        border-radius: 24px;
      }

      .team-member-name {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }

      .progress-wrapper {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }
    }
  }

  &.info-right-clipped {
    .bar-info {
      right: 6px;
      left: auto;
    }
  }

  &:hover {
    z-index: 1000;

    .bar-internal {
      background: #2b7eed;
    }

    .bar-info {
      display: block;
    }
  }
}
</style>

<template>
  <div class="layout has-sidebar">
    <div class="layout-header">
      <h1>Manage timesheets</h1>
    </div>

    <div class="layout-content">
      <div class="columns">
        <div class="column is-narrow is-max-full is-full">
          <div class="sticky-column-wrapper">
            <div class="sticky-column-scroller">
              <table class="sticky-column-table data-table">
                <tr>
                  <th></th>
                  <th>Project</th>
                  <th>
                    <div class="is-flex is-flex-direction-column is-align-content-center mb-3">
                      Billable
                      <b-checkbox
                        v-if="!!timesheets"
                        class="mt-4"
                        type="is-success"
                        @input="toggleList('billable', $event)"
                        :indeterminate="indeterminateList('billable')"
                        :value="selectedList('billable').length === timesheets.data.length"
                      />
                    </div>
                  </th>
                  <th>Description</th>
                  <th>
                    <div class="is-flex is-flex-direction-column is-align-content-center mb-3">
                      Editable
                      <b-checkbox
                        v-if="!!timesheets"
                        class="mt-4"
                        @input="toggleList('editable', $event)"
                        :indeterminate="indeterminateList('editable')"
                        :value="selectedList('editable').length === timesheets.data.length"
                      />
                    </div>
                  </th>
                  <th>
                    <div class="is-flex is-flex-direction-column is-align-content-center mb-3">
                      From home
                      <b-checkbox
                        v-if="timesheets.length"
                        class="mt-4"
                        @input="toggleList('fromHome', $event)"
                        :indeterminate="indeterminateList('fromHome')"
                        :value="selectedList('fromHome').length === timesheets.data.length"
                      />
                    </div>
                  </th>
                  <th class="sticky-column" style="height: 100%;">
                    <div style="margin-top: 2.7rem;">
                      <b-checkbox
                        v-if="!!timesheets"
                        class="ml-0 mt-3"
                        @input="toggleList('selected', $event)"
                        :indeterminate="indeterminateList('selected')"
                        :value="selectedList('selected').length === timesheets.data.length"
                      />

                      <b-dropdown :triggers="['hover']" aria-role="list">
                        <template #trigger>
                          <b-button class="is-flex is-justify-content-center px-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#ffffff"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="mt-2"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <line x1="4" y1="8" x2="20" y2="8" />
                              <line x1="4" y1="16" x2="20" y2="16" />
                            </svg>
                          </b-button>
                        </template>
                        <b-dropdown-item aria-role="listitem" has-link>
                          <a @click="saveBatch()">
                            Save
                          </a>
                        </b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" has-link>
                          <a @click="lockAndSaveBatch()">
                            Lock and save
                          </a>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <div class="loader inline" v-if="loading" />
                  </th>
                </tr>

                <template v-if="!loading && timesheets && timesheets.data">
                  <x-line
                    v-for="timesheet of timesheets.data"
                    :key="timesheet.id"
                    :disabled="timesheet.disabled"
                    :selected="timesheet.selected"
                    :timesheet="timesheet"
                  >
                    {{ timesheet.selected }}
                  </x-line>
                </template>
              </table>
            </div>
          </div>

          <paginator
            v-if="timesheets"
            :page-id.sync="page"
            :last-page="timesheets.paginator.lastPage"
          />
        </div>
      </div>
    </div>
    <div class="layout-sidebar">
      <ul class="p-3">
        <li class="py-2 mb-4"><b>Filters</b></li>
        <li>
          <timesheet-filters
            editable="editable"
            @update:where="(value) => (filters.where = value)"
            @update:whereHas="(value) => (filters.whereHas = value)"
            @update:whereHasProject="(value) => (filters.whereHasProject = value)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Paginator from '@/components/Paginator.vue';
import TimesheetFilters from './timesheet-filters.modal.vue';
import XLine from './manage-line.vue';
import { GET_MANAGE } from '@/graphql/queries/timesheets/manage';
import { UPDATE_TIMESHEET_BATCH } from '@/graphql/mutations/update_timesheet_batch';

export default {
  name: 'Timesheets',
  components: {
    Paginator,
    TimesheetFilters,
    XLine,
  },
  data() {
    return {
      page: 1,
      orderBy: {
        column: 'DATE',
        order: 'DESC',
      },
      filters: {
        where: {},
        whereHas: {},
        whereHasProject: {},
      },
    };
  },

  methods: {
    /**
     * @param {Array<*>} timesheets
     */
    async updateBatch(timesheets) {
      return this.$apollo.mutate({
        mutation: UPDATE_TIMESHEET_BATCH,
        variables: {
          timesheets,
        },
      });
    },

    async lockAndSaveBatch() {
      const selected = this.selectedList('selected');

      for (const item of selected) {
        item.editable = false;
      }

      return await this.saveBatch();
    },

    async saveBatch() {
      const selected = this.selectedList('selected');

      if (!selected.length) {
        return Promise.resolve();
      }

      selected.forEach((timesheet) => {
        timesheet.disabled = true;
      });

      this.$forceUpdate();

      return this.updateBatch(
        selected.map((timesheet) => ({
          id: timesheet.id,
          input: {
            editable: timesheet.editable,
            billable: timesheet.billable,
            description: timesheet.description,
            hours: timesheet.hours,
            projectId: timesheet.projectId,
            fromHome: timesheet.fromHome,
          },
        })),
      )
        .then(() => {
          selected.forEach((timesheet) => {
            timesheet.selected = false;
          });

          this.$forceUpdate();

          this.$buefy.notification.open({
            type: 'is-success',
            duration: 2000,
            message: `Saved batch`,
          });
        })
        .catch(() => {
          this.$buefy.notification.open({
            type: 'is-danger',
            duration: 2000,
            message: `Something went wrong`,
          });
        })
        .finally(() => {
          selected.forEach((timesheet) => {
            timesheet.disabled = false;
          });

          this.$forceUpdate();
        });
    },

    toggleList(property, value) {
      const timesheets = this.timesheets.data;

      if (value) {
        for (const timesheet of timesheets) {
          timesheet[property] = true;
        }
      } else {
        for (const timesheet of timesheets) {
          timesheet[property] = false;
        }
      }

      this.$forceUpdate();
    },

    selectedList(property) {
      return this.timesheets.data.filter((item) => item[property]);
    },

    indeterminateList(property) {
      const count = this.selectedList(property).length;
      return !!this.timesheets && !!count && this.timesheets.data.length !== count;
    },
  },

  computed: {
    /**
     * @returns {boolean}
     */
    loading() {
      return this.$apollo.queries?.timesheets?.loading;
    },
  },

  apollo: {
    timesheets: {
      query: GET_MANAGE,

      /**
       * @param {{data: Array<*>, paginatorInfo: {lastPage: Number}}} response
       * @returns {{data, paginator: *}}
       */
      update({ timesheets: response }) {
        return {
          paginator: response.paginatorInfo,
          data: response.data,
        };
      },

      variables() {
        return {
          page: this.page,
          orderBy: this.orderBy,
          ...this.filters,
        };
      },

      debounce: 250,
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  tr {
    th {
      padding: 1rem 0.8rem 1.4rem;
    }

    &:hover {
      background: initial;
    }

    td {
      padding: 0.8rem;
    }
  }
}

svg {
  height: 1.1em;

  path {
    stroke-width: 1.5px;
  }
}

.column-max-width {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sticky-column-wrapper {
  position: relative;
  margin-right: -15px;
}

.sticky-column-scroller {
  margin-right: 140px;
  overflow: auto;
  width: auto;
}

.sticky-column-table {
  width: 100%;

  .sticky-column {
    border-left: 1px solid #243143;
    right: 0;
    position: absolute;
    top: auto;
    width: 140px;
    padding: 0 0.8rem;
    display: flex;
    justify-content: center;

    > a {
      padding: 1.5rem 0;
      //margin-left: 0.1rem;
      display: block;
    }
  }
}

.dropdown-content {
  border-radius: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
</style>

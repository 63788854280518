<template>
  <div class="unauthorized">
    <h1>You're not allowed to do this!</h1>
    <router-link :to="{ name: 'dashboard' }">Go back home</router-link>
  </div>
</template>

<script>
export default {
  name: 'unauthorized',
};
</script>

<style scoped>
.unauthorized {
  padding-top: 20vh;
  text-align: center;
}
</style>

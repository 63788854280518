<template>
  <ApolloQuery :query="GET_HOLIDAY_REQUEST" :variables="{ id: id }">
    <template slot-scope="{ result: { data }, isLoading, query }">
      <div class="layout has-sidebar">
        <div class="layout-header">
          <h1>Holiday request</h1>
        </div>
        <div class="layout-content">
          <div class="loader full" v-if="isLoading"></div>
          <div v-if="!isLoading && data.holiday_request" class="columns">
            <div class="column is-narrow is-max-full">
              <div class="data-table-scroll">
                <table class="data-table">
                  <tr>
                    <th>Team member</th>
                    <td>
                      <router-link
                        :to="{
                          name: 'view-user',
                          params: {
                            id: data.holiday_request.requester.id,
                          },
                        }"
                        class="font-medium hover:text-blue"
                      >
                        {{ data.holiday_request.requester.first_name }}
                        {{ data.holiday_request.requester.last_name }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <th>Start holiday</th>
                    <td>{{ data.holiday_request.start_at | date }}</td>
                  </tr>
                  <tr>
                    <th>End holiday</th>
                    <td>{{ data.holiday_request.end_at | date }}</td>
                  </tr>
                  <tr>
                    <th>Hours of absence</th>
                    <td>{{ data.holiday_request.hour_sum | hours(2) }}</td>
                  </tr>
                  <tr>
                    <th>Explanation</th>
                    <td>
                      <blockquote class="description">
                        {{ data.holiday_request.explanation }}
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <th>Approved</th>
                    <td>
                      <status
                        :value="data.holiday_request.approved_at === null ? 'pending' : 'yes'"
                        :options="[
                          { value: 'pending', color: 'gray' },
                          { value: 'yes', color: '#05fe79' },
                        ]"
                      />
                    </td>
                  </tr>
                  <tr v-if="data.holiday_request.approved_at !== null">
                    <th>Approver</th>
                    <td>
                      <router-link
                        v-if="data.holiday_request.approver"
                        :to="{
                          name: 'view-user',
                          params: {
                            id: data.holiday_request.approver.id,
                          },
                        }"
                        class="font-medium hover:text-blue"
                      >
                        {{ data.holiday_request.approver.first_name }}
                        {{ data.holiday_request.approver.last_name }}
                      </router-link>
                    </td>
                  </tr>

                  <tr>
                    <th>Created at</th>
                    <td>{{ data.holiday_request.created_at | datetime }}</td>
                  </tr>

                  <tr>
                    <th>Last updated</th>
                    <td>{{ data.holiday_request.updated_at | datetime }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="layout-sidebar" v-if="!isLoading && data.holiday_request">
          <ul>
            <li v-if="!isLoading && !data.holiday_request.approved_at">
              <router-link
                class="button is-primary"
                :to="{ name: 'edit-holiday-request', params: { id: id } }"
              >
                Edit
              </router-link>
            </li>
            <li>
              <approve-holiday-request-button
                v-if="!data.holiday_request.approved_at && canApproveHolidays"
                :holiday-request="data.holiday_request"
                @done="query.refetch()"
              >
                <button class="button is-primary">Approve</button>
              </approve-holiday-request-button>
            </li>
            <li v-if="!isLoading && !data.holiday_request.approved_at && canApproveHolidays">
              <delete-holiday-request-button
                :holiday-request="data.holiday_request"
                class="button is-danger"
                @done="deleted()"
              />
            </li>
          </ul>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import DeleteHolidayRequestButton from '@/components/DeleteHolidayRequestButton';
import Status from '@/components/Status';
import ApproveHolidayRequestButton from '@/components/ApproveHolidayRequestButton';
import { GET_HOLIDAY_REQUEST } from '@/graphql/queries/holiday_request';

export default {
  name: 'HolidayRequest',
  components: {
    Status,
    ApproveHolidayRequestButton,
    DeleteHolidayRequestButton,
  },
  props: ['id'],

  data() {
    return {
      GET_HOLIDAY_REQUEST,
    };
  },

  computed: {
    canApproveHolidays() {
      return this.$store.getters.havePermission('approve holidays');
    },
  },

  methods: {
    async deleted() {
      await this.$router.push({ name: 'holiday-requests' });
    },
  },
};
</script>

<template>
  <modal
    message="You will not be able to undo this action."
    confirm-button="Delete"
    confirm-button-type="is-danger"
    @close="close"
    @handle="handle"
  />
</template>

<script>
import Modal from '@/components/Modal';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DeleteModal',
  components: {
    Modal,
  },
  setup(props, { emit }) {
    function close() {
      emit('close');
    }

    function handle() {
      emit('delete');
    }

    return {
      close,
      handle,
    };
  },
});
</script>

<template>
  <div class="stat">
    <span class="stat__title">
      <slot name="title"></slot>
    </span>
    <div class="stat__canvas">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseState',
};
</script>

<style lang="scss" scoped>
.stat {
  padding: 1rem 1.5rem;
  background-color: $darkBlue;
  border-radius: 24px;
  max-width: 600px;

  &__title {
    font-weight: 600;
  }

  &__canvas {
    max-width: 100%;
    overflow: hidden;
  }
}
</style>

<template>
  <ApolloMutation
    :mutation="this.isUpdate() ? UPDATE_HOLIDAY_REQUEST : CREATE_HOLIDAY_REQUEST"
    :variables="this.variables"
    @done="onDone"
    @error="onError"
    class="layout"
  >
    <template v-slot="{ mutate, loading }">
      <div class="layout-header">
        <h1 v-if="isUpdate()">Edit holiday request</h1>
        <h1 v-else>New holiday request</h1>
      </div>
      <div class="layout-content">
        <form @submit.prevent="mutate()">
          <fieldset :disabled="loading || $apollo.queries.holiday_request.loading">
            <div class="columns">
              <div class="column is-half-desktop is-one-third-widescreen">
                <b-field label="User" label-for="user" v-if="canApproveHolidays()">
                  <b-select
                    id="user"
                    v-model="requester_id"
                    :disabled="$apollo.queries.all_users.loading || !canApproveHolidays()"
                    :loading="$apollo.queries.all_users.loading"
                    expanded
                  >
                    <option v-for="user in users" :key="user.id" :value="user.id">
                      {{ user.first_name }} {{ user.last_name }}
                    </option>
                  </b-select>
                </b-field>

                <b-field
                  label="I'll be gone starting"
                  label-for="start_at"
                  :type="{ 'is-danger': validationErrors['start_at'] }"
                  :message="validationErrors['start_at']"
                >
                  <b-datepicker
                    v-model="start_at"
                    @input="recalculateAbsenceHours"
                    open-on-focus
                    trap-focus
                    :date-formatter="dateFormatter"
                    :date-parser="dateParser"
                  />
                </b-field>

                <b-field
                  label="And I'll be gone until"
                  label-for="end_at"
                  :type="{ 'is-danger': validationErrors['end_at'] }"
                  :message="validationErrors['end_at']"
                >
                  <b-datepicker
                    v-model="end_at"
                    @input="recalculateAbsenceHours"
                    open-on-focus
                    trap-focus
                    :date-formatter="dateFormatter"
                    :date-parser="dateParser"
                  />
                </b-field>

                <b-field
                  label="Total hours of absence"
                  label-for="hour_sum"
                  :type="{ 'is-danger': validationErrors['hour_sum'] }"
                  :message="
                    validationErrors['hour_sum']
                      ? validationErrors['hour_sum']
                      : 'Lower this amount to request a partial holiday.'
                  "
                >
                  <b-input type="number" ref="hour_sum" min="4" step="0.25" v-model="hour_sum" />
                </b-field>

                <b-field
                  label="Explanation"
                  label-for="explanation"
                  :type="{ 'is-danger': validationErrors['explanation'] }"
                  :message="validationErrors['explanation']"
                >
                  <b-input
                    type="textarea"
                    rows="10"
                    maxlength="1023"
                    id="explanation"
                    v-model="explanation"
                  />
                </b-field>

                <div class="has-text-right">
                  <router-link
                    :to="{ name: 'holiday-requests' }"
                    class="button is-danger is-outlined has-margin-top has-margin-right"
                  >
                    Cancel
                  </router-link>

                  <button
                    type="submit"
                    class="button is-primary has-margin-top"
                    :class="{ 'is-loading': loading }"
                  >
                    Save & View
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </template>
  </ApolloMutation>
</template>

<script>
import { differenceInBusinessDays, format } from 'date-fns';
import { GET_ALL_USERS } from '@/graphql/queries/all_users';
import { GET_HOLIDAY_REQUEST } from '@/graphql/queries/holiday_request';
import { CREATE_HOLIDAY_REQUEST } from '@/graphql/mutations/create_holiday_request.ts';
import { UPDATE_HOLIDAY_REQUEST } from '@/graphql/mutations/update_holiday_request.ts';

export default {
  name: 'HolidayRequestEdit',
  components: {},
  props: ['id'],
  data() {
    return {
      start_at: null,
      end_at: null,
      hour_sum: 0,
      explanation: null,
      requester_id: -1,
      created_at: null,
      updated_at: null,
      users: [],
      validationErrors: {},
      CREATE_HOLIDAY_REQUEST,
      UPDATE_HOLIDAY_REQUEST,
    };
  },
  mounted() {
    this.requester_id = this.$store.getters.me.id;
  },
  watch: {
    start_at() {
      if (!this.end_at) {
        this.end_at = this.start_at;
      } else if (this.start_at > this.end_at) {
        this.end_at = this.start_at;
      }
    },
  },
  computed: {
    variables() {
      return {
        startAt: this.start_at ? format(this.start_at, 'yyyy-MM-dd') : null,
        endAt: this.end_at ? format(this.end_at, 'yyyy-MM-dd') : null,
        hourSum: parseFloat(this.hour_sum),
        explanation: this.explanation,
        requesterId: this.requester_id,
        ...(this.isUpdate() && { id: this.id }),
      };
    },
  },
  methods: {
    recalculateAbsenceHours() {
      if (this.start_at && this.end_at) {
        this.hour_sum = (differenceInBusinessDays(this.end_at, this.start_at) + 1) * 8;
      }
    },
    dateFormatter(date) {
      return date ? format(date, 'yyyy-MM-dd') : null;
    },
    dateParser(date) {
      return date ? new Date(date) : null;
    },
    canApproveHolidays() {
      return this.$store.getters.havePermission('approve holidays');
    },
    isUpdate() {
      return this.$route ? this.$route.name === 'edit-holiday-request' : false;
    },
    onDone(data) {
      let id = this.isUpdate()
        ? data.data.updateHolidayRequest.id
        : data.data.createHolidayRequest.id;
      this.$router.push({ name: 'view-holiday-request', params: { id: id } });
    },
    onError(error) {
      let { graphQLErrors } = error;
      this.validationErrors = {};
      if (graphQLErrors[0].extensions.category === 'validation') {
        this.validationErrors = graphQLErrors[0].extensions.validation;
      }
    },
  },
  apollo: {
    holiday_request: {
      query: GET_HOLIDAY_REQUEST,

      variables() {
        return {
          id: this.id,
        };
      },

      skip() {
        return !this.isUpdate();
      },

      result({ data }) {
        if (!data || !data.holiday_request) {
          return;
        }

        this.start_at = new Date(data.holiday_request.start_at);
        this.end_at = new Date(data.holiday_request.end_at);
        this.hour_sum = data.holiday_request.hour_sum;
        this.explanation = data.holiday_request.explanation;
        this.requester_id = data.holiday_request.requester_id;
        this.created_at = data.holiday_request.created_at;
        this.updated_at = data.holiday_request.updated_at;
      },
    },
    all_users: {
      query: GET_ALL_USERS,
      update(data) {
        this.users = data.all_users.sort((a, b) => (a.first_name < b.first_name ? -1 : 1));
      },
      skip() {
        return !this.canApproveHolidays();
      },
    },
  },
};
</script>

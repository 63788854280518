<template>
  <div>
    <b-field v-if="canSeeOtherTeamMembers">
      <template slot="label">
        Team Members
        <span class="reset" @click="users = []">Reset</span>
      </template>

      <b-taginput
        v-model="users"
        type="is-dark"
        :data="filtered_users"
        autocomplete
        :open-on-focus="true"
        :keep-first="true"
        field="first_name"
        :loading="$apollo.queries.all_users.loading"
        @typing="getFilteredUsers"
      />
    </b-field>

    <b-field>
      <template slot="label">
        Date range
        <span class="reset" @click="dates = null">Reset</span>
      </template>

      <b-datepicker v-model="dates" position="is-bottom-left" range />
    </b-field>

    <b-field>
      <template slot="label">
        Minimal hours of absence
      </template>
      <b-input type="number" ref="hour_sum" min="8" step="1" v-model="hour_sum" />
    </b-field>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { GET_ALL_USERS } from '@/graphql/queries/all_users';

export default {
  name: 'HolidayRequestFilters',
  props: {
    canSeeOtherTeamMembers: Boolean,
  },
  data() {
    return {
      dates: null,
      hour_sum: 0,
      users: [],
      all_users: [],
      filtered_users: [],
    };
  },
  computed: {
    where() {
      let filters = [];

      if (this.hour_sum) {
        filters.push({
          column: 'HOUR_SUM',
          operator: 'GTE',
          value: this.hour_sum,
        });
      }

      if (this.dates && this.dates[0]) {
        filters.push({
          column: 'START_AT',
          operator: 'GTE',
          value: format(this.dates[0], 'yyyy-MM-dd'),
        });
      }

      if (this.dates && this.dates[1]) {
        filters.push({
          column: 'END_AT',
          operator: 'LTE',
          value: format(this.dates[1], 'yyyy-MM-dd'),
        });
      }

      if (this.canSeeOtherTeamMembers && this.users.length) {
        filters.push({
          column: 'REQUESTER_ID',
          operator: 'IN',
          value: this.users.map((user) => user.id),
        });
      }

      return { AND: filters };
    },
  },
  watch: {
    where() {
      this.$emit('update:where', this.where);
    },
  },
  methods: {
    getFilteredUsers(text) {
      // First all items that start with the search text, then all
      // items that match at another place

      text = text.toLowerCase();
      let startMatch = this.all_users.filter((option) => {
        return (
          option.first_name
            .toString()
            .toLowerCase()
            .indexOf(text) === 0
        );
      });

      let middleMatch = this.all_users.filter((option) => {
        return (
          option.first_name
            .toString()
            .toLowerCase()
            .indexOf(text) > 0
        );
      });

      this.filtered_users = this.unique(startMatch.concat(middleMatch));
    },
    unique(arr) {
      // Remove duplicates based on id
      let flags = {};
      return arr.filter(function(entry) {
        if (flags[entry.id]) {
          return false;
        }

        flags[entry.id] = true;
        return true;
      });
    },
  },
  apollo: {
    all_users: {
      query: GET_ALL_USERS,
      update(data) {
        return data.all_users.sort((a, b) => (a.first_name < b.first_name ? -1 : 1));
      },
      skip() {
        return !this.canSeeOtherTeamMembers;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  color: #2b7eed;
  text-decoration: underline;
  cursor: pointer;
  float: right;

  &:hover {
    text-decoration: none;
  }
}
</style>

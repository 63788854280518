<template>
  <div>
    <b-autocomplete
      :custom-formatter="format"
      :data="computedOptions"
      :loading="loading"
      :open-on-focus="true"
      :value="computedValue"
      @focus="focus($event)"
      @select="select($event)"
      @typing="typing($event)"
      @blur="blur($event)"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { GET_ALL_PROJECTS } from '@/graphql/queries/all_projects';

export default {
  name: 'select-project',

  props: {
    value: null,
    selected: null,
    options: Array,
    variables: Object,
    disabled: Boolean,
    search: null,
  },

  mounted() {
    //
  },

  data() {
    return {
      focused: false,
      searched: false,
      query: null,
      innerValue: null,
    };
  },

  methods: {
    /**
     * @param {{title: string}} project
     * @return {string}
     */
    format(project) {
      return project.title;
    },

    /**
     * @param {{id: number}} project
     */
    select(project) {
      this.$emit('input', project?.id || null);
      this.query = null;
    },

    /**
     * @param {string} query
     */
    async typing(query) {
      this.query = query || null;
      this.searched = true;
    },

    focus() {
      this.focused = true;
    },

    blur() {
      this.focused = false;
    },

    focusOnAutocomplete() {
      this.query = null;
    },
  },

  computed: {
    /**
     * @return {array<*>}
     */
    computedOptions() {
      return (this.searched
        ? this.$apolloData.data?.projects
        : this.options
      ).map(({ id, title }) => ({ id, title }));
    },

    computedValue() {
      if (this.focused) return '';
      return this.computedOptions?.find((item) => item.id === this.value)?.title;
    },

    /**
     * @return {boolean}
     */
    loading() {
      return this.$apollo.queries.projects.loading;
    },
  },

  apollo: {
    projects: {
      query: GET_ALL_PROJECTS,

      /**
       * @param {{data: array<*>, paginatorInfo: {lastPage: number}}} response
       * @return {{data, paginator: *}}
       */
      update({ all_projects: data }) {
        return data || [];
      },

      variables() {
        const search = this.search
          ? this.search(this.query)
          : () => ({ variables: { me: false, active: true } });

        return {
          search: this.query,
          ...search.variables,
        };
      },

      debounce: 250,
    },
  },
};
</script>

<template>
  <canvas ref="chart"></canvas>
</template>

<script>
import { Chart } from 'chart.js';

export default {
  name: 'LineChartStat',
  props: {
    labels: Array,
    datasets: Array,
  },
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    initChart() {
      this.chart = new Chart(this.$refs.chart, {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: this.datasets,
        },
        options: {
          responsive: true,
          color: '#fff',
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          stacked: false,
          scales: {
            xAxis: {
              ticks: {
                color: '#fff',
              },
              grid: {
                color: '#39485c',
              },
            },
            y1: {
              type: 'linear',
              display: true,
              position: 'left',
              min: 0,
              ticks: {
                color: '#fff',
              },
              grid: {
                color: '#39485c',
              },
            },
            y2: {
              type: 'linear',
              display: true,
              position: 'right',
              min: 0,
              ticks: {
                color: '#fff',
              },

              // grid line settings
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          },
        },
      });
    },
  },
  watch: {
    datasets: {
      handler: function(datasets) {
        this.chart.data.datasets = datasets;
        this.chart.update();
      },
      deep: true,
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>

<style lang="scss" scoped>
.stat {
  padding: 1rem 1.5rem;
  background-color: $dark;
  border-radius: 24px;

  &__title {
    font-weight: 600;
  }
}
</style>

<template>
  <ApolloMutation
    :mutation="this.isUpdate() ? UPDATE_PROJECT : CREATE_PROJECT"
    :variables="variables"
    @done="onDone"
    @error="onError"
  >
    <template v-slot="{ mutate, loading }">
      <form @submit.prevent="mutate()">
        <fieldset :disabled="loading || $apollo.queries.project.loading">
          <div class="layout has-sidebar">
            <div class="layout-header">
              <h1 v-if="isUpdate()">Edit project</h1>
              <h1 v-else>New project</h1>
            </div>

            <div class="layout-content">
              <div class="columns is-multiline">
                <div class="column info-column">
                  <b-field
                    label="Name"
                    label-for="name"
                    :type="{ 'is-danger': validationErrors['name'] }"
                    :message="validationErrors['name']"
                  >
                    <b-input type="text" id="name" v-model="name" max="250" required></b-input>
                  </b-field>

                  <b-field
                    label="Code"
                    label-for="code"
                    :type="{ 'is-danger': validationErrors['code'] }"
                    :message="validationErrors['code']"
                  >
                    <b-input type="text" id="code" v-model="code" max="6" required></b-input>
                  </b-field>

                  <b-field
                    label="Value"
                    label-for="value"
                    :type="{ 'is-danger': validationErrors['value'] }"
                    :message="validationErrors['value']"
                  >
                    <b-input type="text" id="value" v-model="value"></b-input>
                  </b-field>

                  <b-field
                    label="Invoiced value"
                    label-for="invoiced_value"
                    :type="{ 'is-danger': validationErrors['invoiced_value'] }"
                    :message="validationErrors['invoiced_value']"
                  >
                    <b-input type="text" id="invoiced_value" v-model="invoiced_value"></b-input>
                  </b-field>

                  <b-field
                    label="Expected hourly rate"
                    label-for="expectedHourlyRate"
                    :type="{ 'is-danger': validationErrors['expectedHourlyRate'] }"
                    :message="validationErrors['expectedHourlyRate']"
                  >
                    <b-input
                      type="number"
                      id="expectedHourlyRate"
                      v-model="expectedHourlyRate"
                    ></b-input>
                  </b-field>

                  <b-field
                    label="Status"
                    label-for="status"
                    :type="{ 'is-danger': validationErrors['status'] }"
                    :message="validationErrors['status']"
                  >
                    <b-select id="status" v-model="status" expanded>
                      <option
                        v-for="status in ProjectStatus.cases()"
                        :key="status.value"
                        :value="status.value"
                      >
                        {{ status.label }}
                      </option>
                    </b-select>
                  </b-field>

                  <b-field
                    label="Category"
                    label-for="category"
                    :type="{ 'is-danger': validationErrors['category'] }"
                    :message="validationErrors['category']"
                  >
                    <b-select
                      id="category"
                      v-model="category_id"
                      :disabled="$apollo.queries.project_categories.loading"
                      :loading="$apollo.queries.project_categories.loading"
                      expanded
                    >
                      <option
                        v-for="category in categories"
                        :key="category.id"
                        :value="category.id"
                      >
                        {{ category.title }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field
                    label="Tags"
                    label-for="tags"
                    :type="{ 'is-danger': validationErrors['tags'] }"
                    :message="validationErrors['tags']"
                  >
                    <b-taginput
                      id="tags"
                      v-model="tags_selected"
                      :disabled="$apollo.queries.project_categories.loading"
                      :loading="$apollo.queries.project_categories.loading"
                      expanded
                      multiple
                      :allow-duplicates="false"
                      :data="tags"
                      field="title"
                      type="is-dark"
                      :autocomplete="true"
                      :allow-new="false"
                      :open-on-focus="true"
                    />
                  </b-field>

                  <b-field
                    label="Lead source"
                    label-for="lead_source"
                    :type="{ 'is-danger': validationErrors['lead_source'] }"
                    :message="validationErrors['lead_source']"
                  >
                    <b-select
                      id="lead_source"
                      v-model="lead_source_id"
                      :disabled="$apollo.queries.lead_sources.loading"
                      :loading="$apollo.queries.lead_sources.loading"
                      expanded
                    >
                      <option
                        v-for="lead_source in lead_sources"
                        :key="lead_source.id"
                        :value="lead_source.id"
                      >
                        {{ lead_source.type }}
                      </option>
                    </b-select>
                  </b-field>

                  <b-field
                    label="Client"
                    label-for="client"
                    :type="{ 'is-danger': validationErrors['client'] }"
                    :message="validationErrors['client']"
                  >
                    <b-select
                      id="client"
                      v-model="client_id"
                      :disabled="
                        $apollo.queries.all_clients.loading || (route.query.client && !isUpdate())
                      "
                      :loading="$apollo.queries.all_clients.loading"
                      expanded
                    >
                      <option v-for="client in clients" :key="client.id" :value="client.id">
                        {{ client.code }} - {{ client.name }}
                      </option>
                    </b-select>
                  </b-field>

                  <b-field
                    label="Owner"
                    label-for="owner"
                    :type="{ 'is-danger': validationErrors['owner'] }"
                    :message="validationErrors['owner']"
                  >
                    <b-select
                      id="owner"
                      v-model="owner_id"
                      :disabled="$apollo.queries.owners.loading"
                      :loading="$apollo.queries.owners.loading"
                      expanded
                    >
                      <option v-for="owner in owners" :key="owner.id" :value="owner.id">
                        {{ owner.first_name }} {{ owner.last_name }}
                      </option>
                    </b-select>
                  </b-field>

                  <h2>Project dates</h2>
                  <b-field
                    label="Start date"
                    :type="{ 'is-danger': validationErrors['start_date'] }"
                    :message="validationErrors['start_date']"
                  >
                    <b-datepicker
                      v-model="start_date"
                      position="is-top-right"
                      :date-formatter="dateFormatter"
                      :date-parser="dateParser"
                    />
                  </b-field>

                  <b-field
                    label="Internal delivery date"
                    :type="{
                      'is-danger': validationErrors['internal_delivery_date'],
                    }"
                    :message="validationErrors['internal_delivery_date']"
                  >
                    <b-datepicker
                      v-model="internal_delivery_date"
                      position="is-top-right"
                      :date-formatter="dateFormatter"
                      :date-parser="dateParser"
                    />
                  </b-field>

                  <b-field
                    label="External delivery date"
                    :type="{
                      'is-danger': validationErrors['external_delivery_date'],
                    }"
                    :message="validationErrors['external_delivery_date']"
                  >
                    <b-datepicker
                      v-model="external_delivery_date"
                      position="is-top-right"
                      :date-formatter="dateFormatter"
                      :date-parser="dateParser"
                    />
                  </b-field>
                </div>

                <div class="column notes-column">
                  <markdown label="Notes" :editable="true" :text.sync="notes"></markdown>
                </div>
              </div>
            </div>

            <div class="layout-sidebar">
              <ul>
                <li>
                  <button
                    type="submit"
                    class="button is-primary"
                    :class="{ 'is-loading': loading }"
                  >
                    Save
                  </button>
                </li>

                <li v-if="isUpdate()">
                  <router-link
                    :to="{ name: 'view-project', params: { id: id } }"
                    v-if="isUpdate()"
                    class="button is-danger is-outlined"
                  >
                    Cancel
                  </router-link>
                </li>
                <li v-else>
                  <router-link :to="{ name: 'projects' }" class="button is-danger is-outlined">
                    Cancel
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </fieldset>
      </form>
    </template>
  </ApolloMutation>
</template>

<script>
import { format } from 'date-fns';
import Markdown from '@/components/Markdown.vue';
import ProjectStatus from '@/enums/ProjectStatus';
import { GET_ALL_CLIENTS } from '@/graphql/queries/all_clients';
import { GET_LEAD_SOURCES } from '@/graphql/queries/lead_sources';
import { GET_OWNERS } from '@/graphql/queries/owners';
import { GET_PROJECT } from '@/graphql/queries/projects/project';
import { GET_PROJECT_CATEGORIES } from '@/graphql/queries/project_categories';
import { CREATE_PROJECT } from '@/graphql/mutations/projects/create_project';
import { UPDATE_PROJECT } from '@/graphql/mutations/projects/update_project';

export default {
  name: 'ProjectEdit',
  components: {
    Markdown,
  },
  props: ['id'],
  data() {
    let clientId = null;
    if (this.$route.query.client && !this.isUpdate()) {
      clientId = this.$route.query.client;
    }

    return {
      ProjectStatus,
      name: '',
      code: '',
      value: '',
      invoiced_value: '',
      lead_source_id: null,
      client_id: clientId,
      owner_id: null,
      start_date: null,
      internal_delivery_date: null,
      external_delivery_date: null,
      notes: '',
      lead_sources: {},
      clients: {},
      owners: {},
      validationErrors: {},
      category_id: null,
      categories: {},
      tags_selected: [],
      tags: [],
      status: ProjectStatus.IN_PROGRESS.value,
      expectedHourlyRate: 0,
      CREATE_PROJECT,
      UPDATE_PROJECT,
      route: this.$route,
    };
  },
  computed: {
    variables() {
      return {
        name: this.name,
        code: this.code,
        value: parseFloat(this.value),
        invoiced_value: parseFloat(this.invoiced_value),
        lead_source_id: this.lead_source_id,
        client_id: this.client_id,
        owner_id: this.owner_id,
        category_id: this.category_id,
        start_date: this.start_date ? format(this.start_date, 'yyyy-MM-dd') : null,
        internal_delivery_date: this.internal_delivery_date
          ? format(this.internal_delivery_date, 'yyyy-MM-dd')
          : null,
        external_delivery_date: this.external_delivery_date
          ? format(this.external_delivery_date, 'yyyy-MM-dd')
          : null,
        notes: this.notes,
        ...(this.isUpdate() && { id: this.id }),
        expectedHourlyRate: parseFloat(this.expectedHourlyRate),
        tags: this.tags_selected.map((tag) => tag.id),
        status: this.status,
      };
    },
  },
  methods: {
    dateFormatter(date) {
      return format(date, 'yyyy-MM-dd');
    },
    dateParser(date) {
      return new Date(date);
    },
    isUpdate() {
      return this.$route ? this.$route.name === 'edit-project' : false;
    },
    onDone(data) {
      let id = this.isUpdate() ? data.data.updateProject.id : data.data.createProject.id;
      this.$router.push({ name: 'view-project', params: { id: id } });
    },
    onError(error) {
      let { graphQLErrors } = error;
      this.validationErrors = {};
      if (graphQLErrors[0].extensions.category === 'validation') {
        this.validationErrors = graphQLErrors[0].extensions.validation;
      }
    },
  },
  apollo: {
    project: {
      query: GET_PROJECT,
      variables() {
        if (this.id) {
          return {
            id: this.id,
          };
        }
      },
      result({ data }) {
        if (!data) {
          return;
        }

        const project = data.project;

        this.name = project.name;
        this.code = project.code;
        this.value = project.value;
        this.invoiced_value = project.invoiced_value;
        this.lead_source_id = project.lead_source?.id || null;
        this.client_id = project.client?.id || null;
        this.owner_id = project.owner?.id || null;
        this.category_id = project.category?.id || null;

        this.start_date = project.start_date ? new Date(project.start_date) : null;
        this.internal_delivery_date = project.internal_delivery_date
          ? new Date(project.internal_delivery_date)
          : null;
        this.external_delivery_date = project.external_delivery_date
          ? new Date(project.external_delivery_date)
          : null;

        this.notes = project.notes;
        this.expectedHourlyRate = project.expectedHourlyRate;
        this.tags_selected = project.tags;
        this.status = project.status?.value || null;
      },
      skip() {
        return !this.isUpdate();
      },
    },
    lead_sources: {
      query: GET_LEAD_SOURCES,
      result({ data }) {
        if (!data) {
          return;
        }

        this.lead_sources = data.lead_sources;

        if (this.lead_source_id == null && data.lead_sources.length) {
          this.lead_source_id = data.lead_sources[0].id;
        }
      },
    },
    all_clients: {
      query: GET_ALL_CLIENTS,
      result({ data }) {
        if (!data) {
          return;
        }

        this.clients = data.all_clients.sort((a, b) => (a.code < b.code ? null : 1));
        if (this.client_id == null && data.all_clients.length) {
          this.client_id = data.all_clients[0].id;
        }
      },
    },
    owners: {
      query: GET_OWNERS,
      result({ data }) {
        if (!data) {
          return;
        }

        this.owners = data.owners;

        if (this.owner_id == null && data.owners.length) {
          this.owner_id = data.owners[0].id;
        }
      },
    },
    project_categories: {
      query: GET_PROJECT_CATEGORIES,
      result({ data }) {
        if (!data) {
          return;
        }

        this.categories = data.project_categories;

        if (!this.categories) {
          return;
        }

        this.category_id = this.category_id || this.categories[0].id || null;

        // Get the unique category tags sorted by title in alphabetic order.
        this.tags = [
          ...new Map(
            this.categories
              .reduce((current, category) => [...current, ...category.tags], [])
              .map((item) => [item.id, item]),
          ).values(),
        ].sort((a, b) => (a.title < b.title ? -1 : 1));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-column {
  min-width: 320px;
  max-width: 420px;
}

.notes-column {
  min-width: 320px;
  max-width: 960px;
}
</style>

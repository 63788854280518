import * as Sentry from '@sentry/vue';
import Vue from 'vue';

export function sentry() {
  if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
    });
  }
}

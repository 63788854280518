<template>
  <div>
    <client-filter v-model="active_filters.clients" @reset="active_filters.clients = []" />

    <b-field v-if="canSeeOtherTeamMembers && showFilter('users')">
      <template slot="label">
        Team Members
        <span class="reset" @click="active_filters.users = []">Reset</span>
      </template>

      <b-taginput
        v-model="active_filters.users"
        type="is-dark"
        :data="filtered_users"
        autocomplete
        open-on-focus
        keep-first
        field="first_name"
        :loading="$apollo.queries.all_users.loading"
        @typing="getFilteredUsers"
      />
    </b-field>

    <b-field v-if="showFilter('projects')">
      <template slot="label">
        Projects
        <span class="reset" @click="active_filters.projects = []">Reset</span>
      </template>

      <b-taginput
        v-model="active_filters.projects"
        type="is-dark"
        :data="filtered_projects"
        autocomplete
        open-on-focus
        keep-first
        field="display_code"
        :loading="$apollo.queries.all_projects.loading"
        @typing="getFilteredProjects"
      />
    </b-field>

    <b-field v-if="showFilter('dates')">
      <template slot="label">
        Date range
        <span class="reset" @click="active_filters.dates = null">Reset</span>
      </template>

      <b-datepicker v-model="active_filters.dates" position="is-bottom-left" range />
    </b-field>

    <b-field v-if="showFilter('hours')">
      <template slot="label">
        Hours
        <span class="reset" @click="active_filters.hours = [0, 12]">Reset</span>
      </template>
      <b-slider v-model="active_filters.hours" :min="0" :max="12" :step="0.25" />
    </b-field>

    <b-field label="Editable" v-if="showFilter('editable')">
      <b-select expanded v-model="active_filters.editable" placeholder="Select...">
        <option value="all">Both</option>
        <option value="editable">Editable</option>
        <option value="not_editable">Non-editable</option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
import { format } from 'date-fns';
import ClientFilter from '@/components/ClientFilter';
import { GET_ALL_PROJECTS } from '@/graphql/queries/all_projects';
import { GET_ALL_USERS } from '@/graphql/queries/all_users';

export default {
  name: 'TimesheetFilters',
  components: {
    ClientFilter,
  },

  props: {
    canSeeOtherTeamMembers: Boolean,
    showFilters: {
      type: Array,
    },
    editable: {
      default: 'all',
      type: String,
    },
  },
  data() {
    return {
      all_users: [],
      filtered_users: [],

      all_projects: [],
      filtered_projects: [],

      active_filters: {
        hours: [0, 12],
        dates: null,
        clients: [],
        users: [],
        projects: [],
        editable: 'all',
      },
    };
  },
  mounted() {
    // Set defaults from props
    this.active_filters.editable = this.editable;
  },
  computed: {
    where() {
      let filters = [];

      filters.push({
        column: 'HOURS',
        operator: 'BETWEEN',
        value: this.active_filters.hours,
      });

      if (this.active_filters.dates && this.active_filters.dates[0]) {
        filters.push({
          column: 'DATE',
          operator: 'GTE',
          value: format(this.active_filters.dates[0], 'yyyy-MM-dd'),
        });
      }

      if (this.active_filters.dates && this.active_filters.dates[1]) {
        filters.push({
          column: 'DATE',
          operator: 'LTE',
          value: format(this.active_filters.dates[1], 'yyyy-MM-dd'),
        });
      }

      if (this.active_filters.editable !== 'all') {
        filters.push({
          column: 'EDITABLE',
          operator: 'EQ',
          value: this.active_filters.editable === 'editable',
        });
      }

      return { AND: filters };
    },

    whereHas() {
      if (this.active_filters.users.length === 0 && this.active_filters.projects.length === 0) {
        // If users AND projects are blank, return null to prevent applying a filter at all
        return null;
      }

      let filters = [];

      if (this.active_filters.users.length) {
        filters.push({
          column: 'USER_ID',
          operator: 'IN',
          value: this.active_filters.users.map((user) => user.id),
        });
      }

      if (this.active_filters.projects.length) {
        filters.push({
          column: 'PROJECT_ID',
          operator: 'IN',
          value: this.active_filters.projects.map((project) => project.id),
        });
      }

      return { AND: filters };
    },
    whereHasProject() {
      if (this.active_filters.clients.length === 0) {
        // If clientsis blank, return null to prevent applying a filter at all
        return null;
      }

      let filters = [];

      if (this.active_filters.clients.length) {
        filters.push({
          column: 'CLIENT_ID',
          operator: 'IN',
          value: this.active_filters.clients.map((client) => client.id),
        });
      }

      return { AND: filters };
    },
  },

  watch: {
    where() {
      this.$emit('update:where', this.where);
    },
    whereHas() {
      this.$emit('update:whereHas', this.whereHas);
    },
    whereHasProject() {
      this.$emit('update:whereHasProject', this.whereHasProject);
    },
    active_filters: {
      deep: true,
      handler() {
        this.$emit('update:activeFilters', this.active_filters);
      },
    },
  },
  methods: {
    getFilteredUsers(text) {
      // First all items that start with the search text, then all
      // items that match at another place

      text = text.toLowerCase();
      let startMatch = this.all_users.filter((option) => {
        return (
          option.first_name
            .toString()
            .toLowerCase()
            .indexOf(text) === 0
        );
      });

      let middleMatch = this.all_users.filter((option) => {
        return (
          option.first_name
            .toString()
            .toLowerCase()
            .indexOf(text) > 0
        );
      });

      this.filtered_users = this.unique(startMatch.concat(middleMatch));
    },
    getFilteredProjects(text) {
      // First all items that start with the search text, then all
      // items that match after a - and then all items that match
      // at another place

      text = text.toLowerCase();
      let startMatch = this.all_projects.filter((option) => {
        return (
          option.display_code
            .toString()
            .toLowerCase()
            .indexOf(text) === 0
        );
      });

      let dashText = '-' + text;
      let dashMatch = this.all_projects.filter((option) => {
        return (
          option.display_code
            .toString()
            .toLowerCase()
            .indexOf(dashText) > 0
        );
      });

      let middleMatch = this.all_projects.filter((option) => {
        return (
          option.display_code
            .toString()
            .toLowerCase()
            .indexOf(text) > 0
        );
      });

      let nameMatch = this.all_projects.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text) > 0
        );
      });

      this.filtered_projects = this.unique(startMatch.concat(dashMatch, middleMatch, nameMatch));
    },
    unique(arr) {
      // Remove duplicates based on id
      let flags = {};
      return arr.filter(function(entry) {
        if (flags[entry.id]) {
          return false;
        }

        flags[entry.id] = true;
        return true;
      });
    },
    showFilter(name) {
      return !this.showFilters || this.showFilters.includes(name);
    },
  },
  apollo: {
    all_users: {
      query: GET_ALL_USERS,
      update(data) {
        return data.all_users.sort((a, b) => (a.first_name < b.first_name ? -1 : 1));
      },
      skip() {
        return !this.canSeeOtherTeamMembers;
      },
    },
    all_projects: {
      query: GET_ALL_PROJECTS,
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  color: #2b7eed;
  text-decoration: underline;
  cursor: pointer;
  float: right;

  &:hover {
    text-decoration: none;
  }
}
</style>

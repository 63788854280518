<template>
  <ApolloQuery
    :query="GET_PROJECT_HOURS_MONTHLY_CHART"
    :variables="{ months: months, split: shouldSplit, userId: userId, projectId: projectId }"
  >
    <template slot-scope="{ result: { data }, isLoading }">
      <div class="loader full" v-if="isLoading"></div>
      <div v-else>
        <base-stat>
          <template #title>{{ title }}</template>
          <line-chart-stat
            :labels="data.chartProjectHours.labels"
            :datasets="data.chartProjectHours.datasets"
          />
        </base-stat>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import BaseStat from '@/components/stats/BaseStat';
import LineChartStat from '@/components/stats/LineChartStat';
import { GET_PROJECT_HOURS_MONTHLY_CHART } from '@/graphql/queries/dashboard/project_hours_monthly_chart';

export default {
  name: 'ProjectHoursMonthlyChart',
  components: {
    BaseStat,
    LineChartStat,
  },
  props: {
    title: String,
    months: Number,
    split: Boolean,
    userId: String,
    projectId: Number,
  },
  data() {
    return {
      GET_PROJECT_HOURS_MONTHLY_CHART,
    };
  },
  computed: {
    shouldSplit() {
      return this.canViewPlanning() && this.split;
    },
  },
  methods: {
    canViewPlanning() {
      return this.$store.getters.havePermission('view planning');
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <div class="loader full" v-if="$apollo.queries.me.loading"></div>
    <div v-else>
      <h1>Your active projects</h1>
      <div v-if="active_projects.length === 0">
        <i>You don't have active projects</i>
      </div>
      <div v-else class="data-table-scroll">
        <table class="data-table">
          <thead>
            <th>Code & Name</th>
            <th>Client</th>
            <th>Progress</th>
            <th title="Internal delivery date">Deadline</th>
          </thead>
          <tr v-for="participation in sortedProjects" :key="participation.id">
            <td>
              <router-link
                :to="{
                  name: 'view-project',
                  params: { id: participation.project.id },
                }"
                class="font-medium hover:text-blue"
              >
                {{ participation.project.display_code }}
              </router-link>
            </td>
            <td>
              <router-link
                :to="{
                  name: 'view-client',
                  params: { id: participation.project.client.id },
                }"
                class="font-medium hover:text-blue"
              >
                {{ participation.project.client.name }}
              </router-link>
            </td>
            <td>
              <b-progress
                :value="participation.worked_hours"
                :max="participation.hours"
                size="is-medium"
                :type="progress_type(participation)"
                show-value
              >
                {{ participation.worked_hours }} /
                {{ participation.hours }}
              </b-progress>
            </td>
            <td>{{ participation.project.internal_delivery_date }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_ACTIVE_PROJECTS } from '@/graphql/queries/dashboard/active_projects';

export default {
  name: 'ActiveProjects',
  data() {
    return {
      active_projects: [],
    };
  },
  methods: {
    progress_type(team_member) {
      if (team_member.hours === 0) {
        return 'is-info';
      }

      if (team_member.worked_hours > team_member.hours) {
        return 'is-danger';
      }

      if (team_member.worked_hours > 0.9 * team_member.hours) {
        return 'is-warning';
      }

      return 'is-primary';
    },
  },
  computed: {
    sortedProjects() {
      return [...this.active_projects].sort(function(a, b) {
        if (a.hours !== b.hours) {
          if (b.hours === 0) {
            return -1;
          }
          if (a.hours === 0) {
            return 1;
          }
        }
      });
    },
  },
  apollo: {
    me: {
      query: GET_ACTIVE_PROJECTS,
      result({ data }) {
        if (data === undefined) return;

        this.active_projects = data.me.participations;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-wrapper {
  min-width: 180px;
}
</style>

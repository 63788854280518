<template>
  <div class="layout">
    <div class="layout-header">
      <h1>Update permissions</h1>
    </div>

    <div class="layout-content">
      <div class="columns is-multiline">
        <div class="column info-column">
          <h2>{{ first_name }} {{ last_name }}</h2>

          <ApolloMutation
            :mutation="SET_ADMIN"
            :variables="this.admin_variables"
            @done="onAdminDone"
            @error="onError"
            class="admin"
          >
            <template v-slot="{ mutate, loading }">
              <b-switch
                v-model="is_admin"
                type="is-success"
                passive-type="is-danger"
                :disabled="$apollo.queries.user.loading || loading"
                @change.native="mutate()"
              >
                <div class="permission">
                  <div class="permission-name">Admin rights</div>
                  <div class="permission-description">
                    Grants all permissions and allows changing permissions for users
                  </div>
                </div>
              </b-switch>
            </template>
          </ApolloMutation>

          <h3>Permissions</h3>
          <ApolloMutation
            :mutation="SET_PERMISSIONS"
            :variables="this.variables"
            @done="onDone"
            @error="onError"
          >
            <template v-slot="{ mutate, loading }">
              <b-switch
                v-model="permission.granted"
                v-for="permission in capabilities"
                :key="permission.name"
                :disabled="is_admin || $apollo.queries.user.loading || loading"
                @change.native="mutate()"
              >
                <div class="permission">
                  <div class="permission-name">
                    {{ permission.name | ucFirst }}
                  </div>
                  <div class="permission-description">
                    {{ permission.description }}
                  </div>
                </div>
              </b-switch>
            </template>
          </ApolloMutation>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USER } from '@/graphql/queries/users/user';
import { SET_ADMIN } from '@/graphql/mutations/set_admin';
import { SET_PERMISSIONS } from '@/graphql/mutations/set_permissions';

export default {
  name: 'SetUserPermissions',
  components: {},
  props: ['id'],
  data() {
    return {
      first_name: '',
      last_name: '',
      capabilities: [],
      is_admin: false,
      SET_ADMIN,
      SET_PERMISSIONS,
    };
  },
  computed: {
    variables() {
      // Make a list of all permissions that are granted
      var permissions = [];
      for (let i in this.capabilities) {
        if (this.capabilities[i].granted) {
          permissions.push(this.capabilities[i].name);
        }
      }

      return {
        user_id: this.id,
        permissions: permissions,
      };
    },
    admin_variables() {
      return {
        user_id: this.id,
        grant_admin: this.is_admin,
      };
    },
  },
  methods: {
    onDone(data) {
      this.is_admin = data.grantUserPermissions.is_admin;
      this.capabilities = data.grantUserPermissions.capabilities;
    },
    onAdminDone({ data }) {
      this.is_admin = data.setUserAdmin.is_admin;
      this.capabilities = data.setUserAdmin.capabilities;
    },
    onError() {
      this.$buefy.notification.open({
        message: `Something went terribly wrong!`,
        type: 'is-danger',
      });
    },
  },
  apollo: {
    user: {
      query: GET_USER,
      variables() {
        return {
          id: this.id,
        };
      },
      update(data) {
        this.first_name = data.user.first_name;
        this.last_name = data.user.last_name;
        this.is_admin = data.user.is_admin;
        this.capabilities = data.user.capabilities;

        /*
        for (let i in data.user.capabilities) {
          this.permissions[data.user.capabilities[i].name] = data.user.capabilities[i].granted
        }
         */
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-column {
  min-width: 320px;
  max-width: 420px;
}

.permission {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 2rem;

  &-name {
    grid-area: permission-name;
    font-weight: bold;
    align-items: center;
  }

  &-description {
    grid-area: permission-description;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.7rem;
  }
}

.admin {
  margin-bottom: 1rem;
  border-bottom: 1px solid #243143;
}

label.switch {
  margin-bottom: 1rem;
}
</style>

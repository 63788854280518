import { render, staticRenderFns } from "./holiday-request-filters.modal.vue?vue&type=template&id=6d15bcca&scoped=true&"
import script from "./holiday-request-filters.modal.vue?vue&type=script&lang=js&"
export * from "./holiday-request-filters.modal.vue?vue&type=script&lang=js&"
import style0 from "./holiday-request-filters.modal.vue?vue&type=style&index=0&id=6d15bcca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d15bcca",
  null
  
)

export default component.exports
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import { isAuthenticated } from '@/vue-apollo.js';

import Dashboard from '../views/dashboard/dashboard.view.vue';
import MetricsDashboard from '../views/metrics/dashboard.view.vue';

import Login from '../views/auth/login.view.vue';

import Users from '../views/users/users.view.vue';
import User from '../views/users/user.view.vue';
import UserEdit from '../views/users/user-edit.view.vue';
import UserSetPermissions from '../views/users/user-permissions.view.vue';
import ProfileEdit from '../views/users/profile-edit.view.vue';
import Account from '../views/auth/account.view.vue';

import Projects from '../views/projects/projects.view.vue';
import Project from '../views/projects/project.view.vue';
import ProjectEdit from '../views/projects/project-edit.view.vue';
import ParticipationEdit from '../views/projects/participation-edit.view.vue';

import Clients from '../views/clients/clients.view.vue';
import Client from '../views/clients/client.view.vue';
import ClientEdit from '../views/clients/client-edit.view.vue';

import Timesheets from '../views/timesheets/timesheets.view.vue';
import TimesheetsManage from '../views/timesheets/manage.view.vue';
import Timesheet from '../views/timesheets/timesheet.view.vue';
import TimesheetEdit from '../views/timesheets/timesheet-edit.view.vue';

import WorkLocations from '../views/timesheets/work-locations.view.vue';

import HolidayRequests from '../views/holiday-requests/holiday-requests.view.vue';
import HolidayRequest from '../views/holiday-requests/holiday-request.view.vue';
import HolidayRequestEdit from '../views/holiday-requests/holiday-request-edit.view.vue';

import TimesheetImport from '../views/timesheets/import/import.view.vue';

import Planning from '../views/planning/planning.view.vue';

import Unauthorized from '../views/errors/unauthorized.view.vue';
import NotFound from '../views/errors/not-found.view.vue';
import Authorize from '../views/auth/authorize.view.vue';
import { Permission } from '@/enums/Permission';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/metrics',
    name: 'metrics',
    component: MetricsDashboard,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: '/authorize',
    name: 'authorize',
    component: Authorize,
    meta: {
      guest: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
  },
  {
    path: '/users/new',
    name: 'new-user',
    component: UserEdit,
    meta: {
      permissions: ['manage users'],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'edit-user',
    props: true,
    component: UserEdit,
    meta: {
      permissions: ['manage users'],
    },
  },
  {
    path: '/users/:id',
    name: 'view-user',
    props: true,
    component: User,
  },
  {
    path: '/users/edit/:id/permissions',
    name: 'set-user-permissions',
    props: true,
    component: UserSetPermissions,
    meta: {
      admin: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      menu: 'profile',
    },
  },
  {
    path: '/profile/:id',
    name: 'view-profile',
    props: true,
    component: User,
    meta: {
      menu: 'profile',
    },
  },
  {
    path: '/profile/:id/settings',
    name: 'profile-settings',
    props: true,
    component: ProfileEdit,
    meta: {
      menu: 'profile',
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
  },
  {
    path: '/projects/new',
    name: 'new-project',
    component: ProjectEdit,
    meta: {
      permissions: ['manage projects'],
    },
  },
  {
    path: '/projects/:id/edit',
    name: 'edit-project',
    props: true,
    component: ProjectEdit,
    meta: {
      permissions: ['manage projects'],
    },
  },
  {
    path: '/projects/:id',
    name: 'view-project',
    props: true,
    component: Project,
  },
  {
    path: '/projects/:id/team-members',
    name: 'edit-project-team-members',
    props: true,
    component: ParticipationEdit,
    meta: {
      permissions: ['manage projects'],
    },
  },

  {
    path: '/clients',
    name: 'clients',
    component: Clients,
  },
  {
    path: '/clients/new',
    name: 'new-client',
    component: ClientEdit,
    meta: {
      permissions: ['manage clients'],
    },
  },
  {
    path: '/clients/:id/edit',
    name: 'edit-client',
    props: true,
    component: ClientEdit,
    meta: {
      permissions: ['manage clients'],
    },
  },
  {
    path: '/clients/:id',
    name: 'view-client',
    props: true,
    component: Client,
  },

  {
    path: '/timesheets',
    name: 'timesheets',
    meta: { menu: 'timesheets' },
    component: Timesheets,
  },
  {
    path: '/timesheets/import',
    name: 'timesheets/import',
    meta: { menu: 'timesheets' },
    component: TimesheetImport,
  },
  {
    path: '/timesheets/manage',
    name: 'timesheets/manage',
    meta: { menu: 'timesheets' },
    component: TimesheetsManage,
  },
  {
    path: '/timesheets/work-locations',
    name: 'timesheets/work-locations',
    meta: {
      menu: 'timesheets',
      permissions: [Permission.EXPORT_WORK_LOCATIONS],
    },
    component: WorkLocations,
  },
  {
    path: '/timesheets/new',
    name: 'new-timesheet',
    meta: { menu: 'timesheets' },
    component: TimesheetEdit,
  },
  {
    path: '/timesheets/:id/edit',
    name: 'edit-timesheet',
    props: true,
    meta: { menu: 'timesheets' },
    component: TimesheetEdit,
  },
  {
    path: '/timesheets/:id',
    name: 'view-timesheet',
    props: true,
    meta: { menu: 'timesheets' },
    component: Timesheet,
  },

  {
    path: '/holiday-requests',
    name: 'holiday-requests',
    meta: { menu: 'holiday-requests' },
    component: HolidayRequests,
  },
  {
    path: '/holiday-requests/new',
    name: 'new-holiday-request',
    meta: { menu: 'holiday-requests' },
    component: HolidayRequestEdit,
  },
  {
    path: '/holiday-requests/:id/edit',
    name: 'edit-holiday-request',
    props: true,
    meta: { menu: 'holiday-requests' },
    component: HolidayRequestEdit,
  },
  {
    path: '/holiday-requests/:id',
    name: 'view-holiday-request',
    props: true,
    meta: { menu: 'holiday-requests' },
    component: HolidayRequest,
  },

  {
    path: '/planning',
    name: 'planning',
    component: Planning,
    meta: {
      permissions: ['view planning'],
    },
  },

  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
    meta: {
      guest: true,
    },
  },

  // This route goes last
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {
      guest: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.guest && !isAuthenticated()) {
    // User not logged in, go to login page
    next({ name: 'login' });
  } else if (to.name === 'login' && isAuthenticated()) {
    next({ name: 'dashboard' });
  } else if (to.meta.admin) {
    // This route requires admin rights
    if (store.getters.me.is_admin) {
      next();
    } else {
      next({ name: 'unauthorized' });
    }
  } else if (Array.isArray(to.meta.permissions)) {
    // This route requires permissions. Check if all permissions are set
    if (to.meta.permissions.every((val) => store.getters.havePermission(val))) {
      next();
    } else {
      next({ name: 'unauthorized' });
    }
  } else {
    next();
  }
});

export default router;

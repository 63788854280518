<template>
  <tr>
    <td>
      <router-link
        :to="{
          name: 'view-user',
          params: { id: timesheet.user.id },
        }"
        class="is-block mb-2"
      >
        {{ timesheet.user.name }}
      </router-link>

      {{ timesheet.date }} - <b>{{ timesheet.hours | hours(2) }}</b>
    </td>

    <td>
      <select-project
        ref="project"
        name="project"
        style="width: 220px"
        :loading="loading"
        :search="searchTimesheetProject(timesheet)"
        :options="formatProjectOption(timesheet.project)"
        :selected="timesheet.project"
        :variables="{ participant: timesheet.user.id }"
        v-model="timesheet.projectId"
        :disabled="disabled"
      />
    </td>

    <td>
      <b-checkbox
        class="mt-2"
        v-model="timesheet.billable"
        :disabled="disabled"
        type="is-success"
      />
    </td>

    <td>
      <b-field label-for="description" style="width: 400px;">
        <b-input
          type="textarea"
          rows="1"
          maxlength="1023"
          id="description"
          :has-counter="true"
          v-model="timesheet.description"
          class="description"
          :disabled="disabled"
        ></b-input>
      </b-field>
    </td>

    <td>
      <b-checkbox class="mt-2" v-model="timesheet.editable" :disabled="disabled" />
    </td>

    <td>
      <b-checkbox class="mt-2" v-model="timesheet.fromHome" :disabled="disabled" />
    </td>

    <td class="sticky-column" style="height: 100%;">
      <div class="mt-3">
        <b-checkbox
          class="ml-0 mt-3"
          @input="toggle(timesheet)"
          :value="timesheet.selected"
          :disabled="timesheet.disabled"
        />

        <b-dropdown :triggers="['hover']" aria-role="list">
          <template #trigger>
            <b-button class="is-flex is-justify-content-center px-3" :disabled="timesheet.disabled">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#ffffff"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="mt-2"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="4" y1="8" x2="20" y2="8" />
                <line x1="4" y1="16" x2="20" y2="16" />
              </svg>
            </b-button>
          </template>

          <b-dropdown-item aria-role="listitem" has-link>
            <a @click="save(timesheet)">
              Save
            </a>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link v-if="timesheet.editable">
            <a @click="lockAndSave(timesheet)">
              Lock and save
            </a>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link>
            <hr class="my-2 mx-4" style="height: 1px;" />
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link>
            <router-link :to="{ name: 'view-timesheet', params: { id: timesheet.id } }">
              View
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link>
            <router-link :to="{ name: 'edit-timesheet', params: { id: timesheet.id } }">
              Edit
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" has-link>
            <delete-timesheet-button :timesheet="timesheet" @done="query.refresh()">
              Delete
            </delete-timesheet-button>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </td>
  </tr>
</template>

<script>
import DeleteTimesheetButton from '@/components/DeleteTimesheetButton';
import SelectProject from '../../components/SelectProject';
import { GET_MANAGE } from '@/graphql/queries/timesheets/manage';
import { UPDATE_TIMESHEET } from '@/graphql/mutations/update_timesheet';

export default {
  components: {
    DeleteTimesheetButton,
    SelectProject,
  },

  props: ['timesheet', 'selected', 'disabled'],

  data() {
    return {
      page: 1,
      orderBy: {
        column: 'DATE',
        order: 'DESC',
      },
      filters: {
        where: {},
        whereHas: {},
      },
    };
  },

  methods: {
    searchTimesheetProject(timesheet) {
      return () => ({
        variables: {
          my: false,
          participant: timesheet.user.id,
          active: false,
        },
      });
    },
    formatProjectOption(project) {
      return [
        {
          id: project.id,
          title: project.title,
        },
      ];
    },

    toggle(timesheet) {
      timesheet.selected = !timesheet.selected;

      this.$forceUpdate();
    },

    toggleList(property, value) {
      const timesheets = this.timesheets.data;

      if (value) {
        for (const timesheet of timesheets) {
          timesheet[property] = true;
        }
      } else {
        for (const timesheet of timesheets) {
          timesheet[property] = false;
        }
      }

      this.$forceUpdate();
    },

    async save(timesheet) {
      timesheet.disabled = true;

      this.$forceUpdate();

      return this.$apollo
        .mutate({
          mutation: UPDATE_TIMESHEET,
          variables: {
            id: timesheet.id,
            projectId: timesheet.projectId,
            description: timesheet.description,
            editable: timesheet.editable,
            billable: timesheet.billable,
            fromHome: timesheet.fromHome,
          },
        })
        .then(() => {
          timesheet.selected = false;

          this.$forceUpdate();

          this.$buefy.notification.open({
            type: 'is-success',
            duration: 2000,
            message: `Saved`,
          });
        })
        .catch(() => {
          this.$buefy.notification.open({
            type: 'is-danger',
            duration: 2000,
            message: `Something went wrong`,
          });
        })
        .finally(() => {
          timesheet.disabled = false;

          this.$forceUpdate();
        });
    },

    lockAndSave(timesheet) {
      timesheet.editable = false;

      this.save(timesheet);
    },
  },

  computed: {
    /**
     * @returns {boolean}
     */
    loading() {
      return this.$apollo.queries?.timesheets?.loading;
    },

    /**
     * @returns {{data: Array<*>}}
     */
    projects() {
      return this.$apolloData.data.projects.map(({ id, title }) => ({ id, title }));
    },
  },

  apollo: {
    timesheets: {
      query: GET_MANAGE,

      /**
       * @param {{data: Array<*>, paginatorInfo: {lastPage: Number}}} response
       * @returns {{data, paginator: *}}
       */
      update({ timesheets: response }) {
        return {
          paginator: response.paginatorInfo,
          data: response.data,
        };
      },

      variables() {
        return {
          page: this.page,
          orderBy: this.orderBy,
          ...this.filters,
        };
      },

      debounce: 250,
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  tr {
    th {
      padding: 1rem 0.8rem 1.4rem;
    }

    &:hover {
      background: initial;
    }

    td {
      padding: 0.8rem;
    }
  }
}

svg {
  height: 1.1em;

  path {
    stroke-width: 1.5px;
  }
}

.column-max-width {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sticky-column-wrapper {
  position: relative;
  margin-right: -15px;
}

.sticky-column-scroller {
  margin-right: 140px;
  overflow: auto;
  width: auto;
}

.sticky-column-table {
  width: 100%;

  .sticky-column {
    border-left: 1px solid #243143;
    right: 0;
    position: absolute;
    top: auto;
    width: 140px;
    padding: 0 0.8rem;
    display: flex;
    justify-content: center;

    > a {
      padding: 1.5rem 0;
      //margin-left: 0.1rem;
      display: block;
    }
  }
}

::v-deep .description textarea:focus {
  height: 90px;
}
</style>

<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Are you sure?</p>
      <button type="button" class="delete" @click="close()" :disabled="loading" />
    </header>
    <section class="modal-card-body" v-if="message">
      {{ message }}
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="close()" :disabled="loading">
        Cancel
      </button>
      <b-button class="button is-primary" :loading="loading" @click="submit()" type="button">
        Ok
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
    },

    action: {
      type: Function,
      required: false,
      default: () => Promise.resolve(),
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async close() {
      await this.$emit('close');
    },

    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        await this.action().then(() => {
          this.close();
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-card {
  .modal-card-head {
    padding: 30px 30px 25px;
  }

  .modal-card-body {
    padding: 0 30px 10px;
  }

  .modal-card-foot {
    padding: 25px 30px 30px;

    .button:not(:last-child) {
      margin-right: 1em;
    }
  }
}
</style>

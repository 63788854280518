<template>
  <div class="layout" :class="{ 'has-sidebar': !loading && !disabled && !empty }">
    <div class="layout-header">
      <h1>Import timesheets</h1>

      <div class="providers">
        <b-button
          title="Toggl Track"
          :selected="provider === timeEntryImportProvider.TOGGL_TRACK"
          @click="selectProvider(timeEntryImportProvider.TOGGL_TRACK)"
          :disabled="loading > 0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 528 528"
            fill="none"
          >
            <path
              d="M263.985 405.845C231.256 405.833 199.643 393.95 175.01 372.399C150.378 350.849 134.399 321.095 130.039 288.657C125.678 256.22 133.232 223.303 151.298 196.012C169.365 168.722 196.718 148.911 228.281 140.256V179.607C207.053 187.929 189.397 203.403 178.362 223.356C167.326 243.309 163.605 266.49 167.84 288.894C172.075 311.299 184 331.522 201.556 346.071C219.113 360.62 241.199 368.582 264 368.582C286.802 368.582 308.887 360.62 326.444 346.071C344 331.522 355.926 311.299 360.16 288.894C364.395 266.49 360.674 243.309 349.638 223.356C338.603 203.403 320.947 187.929 299.719 179.607V140.256C331.285 148.912 358.639 168.725 376.706 196.018C394.772 223.312 402.324 256.233 397.96 288.672C393.595 321.112 377.611 350.866 352.973 372.414C328.335 393.962 296.716 405.841 263.985 405.845ZM244.582 95.0933H283.418V287.732H244.582V95.0933ZM263.985 1.73568e-06C211.771 0.00299512 160.731 15.4889 117.319 44.4994C73.906 73.5099 40.0708 114.742 20.0916 162.982C0.112364 211.222 -5.11356 264.303 5.07465 315.513C15.2629 366.723 40.4076 413.762 77.3293 450.681C114.251 487.601 161.291 512.743 212.502 522.928C263.712 533.114 316.793 527.885 365.032 507.903C413.271 487.921 454.501 454.083 483.509 410.669C512.517 367.255 528 316.214 528 264C528.002 229.329 521.174 194.998 507.907 162.966C494.639 130.934 475.192 101.829 450.675 77.314C426.159 52.7987 397.053 33.3531 365.02 20.0874C332.988 6.82176 298.656 -0.00397388 263.985 1.73568e-06Z"
              fill="#E57CD8"
            />
          </svg>
        </b-button>
        <b-button
          title="Google Calendar"
          :selected="provider === timeEntryImportProvider.GOOGLE_CALENDAR"
          @click="selectProvider(timeEntryImportProvider.GOOGLE_CALENDAR)"
          :disabled="loading > 0"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="186 38 76 76">
            <path fill="#fff" d="M244 56h-40v40h40V56z" />
            <path fill="#EA4335" d="M244 114l18-18h-18v18z" />
            <path fill="#FBBC04" d="M262 56h-18v40h18V56z" />
            <path fill="#34A853" d="M244 96h-40v18h40V96z" />
            <path fill="#188038" d="M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z" />
            <path fill="#1967D2" d="M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z" />
            <path fill="#4285F4" d="M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z" />
            <path
              fill="#4285F4"
              d="M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z"
            />
          </svg>
        </b-button>
      </div>
    </div>

    <template v-if="loading">
      <div
        class="is-flex is-align-content-center is-align-items-center is-justify-content-center mb-6"
      >
        <span class="loader" style="height: 2rem; width: 2rem;" />
      </div>
    </template>

    <template v-else>
      <div class="layout-content">
        <div class="columns">
          <div class="column">
            <!-- Google Calendar unauthorized -->
            <template v-if="provider === timeEntryImportProvider.GOOGLE_CALENDAR">
              <import-google-calendar-authorize v-if="meta.auth.url" :url="meta.auth.url" />
            </template>

            <!-- Toggl Track disabled -->
            <template v-if="provider === timeEntryImportProvider.TOGGL_TRACK">
              <import-disabled :provider="provider" v-if="disabled" />
            </template>

            <template v-if="empty && !disabled && !meta.errors">
              <import-empty
                :provider="provider"
                v-if="provider === timeEntryImportProvider.TOGGL_TRACK || !meta.auth.url"
              />
            </template>

            <import-toggl-track-error
              v-if="
                provider === timeEntryImportProvider.TOGGL_TRACK &&
                  meta.errors.toggl_track_too_many_requests
              "
            />

            <!-- Time entry list -->
            <template
              v-if="
                !disabled &&
                  !empty &&
                  (provider === timeEntryImportProvider.TOGGL_TRACK || !meta.auth.url)
              "
            >
              <div class="table-responsive">
                <table class="data-table">
                  <thead>
                    <tr>
                      <th class="check-column">
                        <b-checkbox
                          v-if="!!items"
                          class="m-0"
                          @input="toggleList($event)"
                          :indeterminate="indeterminateList()"
                          :value="Object.keys(selected).length === entriesNotImported.length"
                        />
                      </th>
                      <th style="min-width: 400px;"></th>
                      <th style="min-width: 250px">Project</th>
                      <th style="min-width: 500px">Description</th>
                      <th>From home</th>
                      <th style="width: 100%;">
                        <span class="loader inline" v-if="loading" />
                        <b-dropdown
                          :triggers="Object.keys(selected).length ? ['hover', 'click'] : []"
                          aria-role="list"
                          position="is-bottom-left"
                          class="is-flex is-justify-content-end"
                          :disabled="!Object.keys(selected).length"
                        >
                          <template #trigger>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              style="cursor: pointer;"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <circle cx="12" cy="12" r="1" />
                              <circle cx="12" cy="19" r="1" />
                              <circle cx="12" cy="5" r="1" />
                            </svg>
                          </template>
                          <b-dropdown-item aria-role="listitem" has-link>
                            <a @click="run()">Import</a>
                          </b-dropdown-item>
                        </b-dropdown>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(entries, date) of dates">
                      <tr :key="date" class="group">
                        <td></td>
                        <td colspan="3">
                          <h3 class="m-0 is-inline-block mr-2" style="font-weight: bold;">
                            {{ date }}
                          </h3>
                          &ndash;
                          <b class="ml-2">{{
                            entries.reduce((value, item) => value + item.hours, 0).toFixed(2)
                          }}</b>
                          hours
                        </td>
                        <td>
                          <BCheckbox
                            class="my-1"
                            @input="toggleFromHomeList(date, $event)"
                            :value="
                              entries.filter(({ fromHome }) => fromHome).length === entries.length
                            "
                          />
                        </td>
                        <td></td>
                      </tr>
                      <template v-for="entry of entries">
                        <ImportLine
                          :key="entry.id"
                          :entry="entry"
                          :selected="!!selected[entry.id]"
                          @select="select(entry)"
                          @deselect="deselect(entry)"
                          @input="updateLine(entry.id, $event)"
                        />
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>

              <paginator
                :page-id.sync="page"
                @update:pageId="fetch({ page })"
                :last-page="paginator.lastPage"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="layout-sidebar p-5">
        <div class="is-flex is-justify-content-space-between is-flex-direction-column">
          <button
            class="button is-primary"
            @click="run()"
            :disabled="!Object.keys(selected).length || importing"
          >
            <loader v-if="importing" style="height: 1em; width: 1em; margin-right: 1em;" />
            Import
          </button>

          <div v-if="provider === timeEntryImportProvider.GOOGLE_CALENDAR" class="mt-5">
            The clients and projects will be matched by the event name using the following format:
            <br />
            <code class="my-2 is-inline-block">client-project: title</code>
            <br />
            So for example:
            <br />
            <code class="my-2 is-inline-block">DCC-Meetings: Share</code>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Paginator from '@/components/Paginator.vue';
import ImportLine from './ImportLine.vue';
import ImportDisabled from './ImportDisabled.vue';
import ImportEmpty from './ImportEmpty.vue';
import ImportGoogleCalendarAuthorize from './ImportGoogleCalendarAuthorize.vue';
import Loader from '@/components/Loader';
import { TimeEntryImportProvider } from '@/constants/TimeEntryImportProvider.js';
import ImportTogglTrackError from '@/views/timesheets/import/ImportTogglTrackError';
import { GET_IMPORT } from '@/graphql/queries/timesheets/import.ts';
import { IMPORT_TIMESHEET_BATCH } from '@/graphql/mutations/import/import_timesheet_batch.ts';
import store from '@/store';

export default {
  components: {
    ImportGoogleCalendarAuthorize,
    ImportTogglTrackError,
    Paginator,
    ImportLine,
    ImportDisabled,
    ImportEmpty,
    Loader,
  },

  async mounted() {
    return this.fetch();
  },

  data() {
    return {
      timeEntryImportProvider: TimeEntryImportProvider,

      loading: 0,
      page: 1,
      selected: {},
      provider:
        localStorage.getItem('tools.calendar.provider') || TimeEntryImportProvider.TOGGL_TRACK,

      paginator: {},
      items: [],
      importing: false,

      meta: {
        auth: {
          url: null,
        },
        errors: {},
      },

      interval: null,
    };
  },
  computed: {
    /**
     * @return {boolean}
     */
    disabled() {
      switch (this.provider) {
        case TimeEntryImportProvider.TOGGL_TRACK:
          return !this.me.toggl_track_enabled;
        default:
          return false;
      }
    },

    entriesNotImported() {
      return this.items.filter((i) => !i.timesheet);
    },

    /**
     * @return {boolean}
     */
    empty() {
      return !this.items.length;
    },

    dates() {
      const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      return groupBy(this.items, 'date');
    },
  },
  methods: {
    toggleFromHomeList(date, value) {
      for (const item of this.dates[date]) {
        const index = this.items.findIndex(({ id }) => id === item.id);
        const entry = this.items[index];

        if (!entry.timesheet) {
          this.items[index] = {
            ...entry,
            fromHome: value,
          };
        }
      }

      // Trigger change detection.
      this.items = [...this.items];
    },

    fetch(variables = {}) {
      this.loading++;

      this.items = [];

      variables = {
        provider: this.provider,
        page: this.page,
        ...variables,
      };

      return this.$apollo
        .query({
          query: GET_IMPORT,
          fetchPolicy: 'no-cache',
          variables,
        })
        .then(async ({ data }) => {
          this.meta.errors = {};
          this.paginator = data?.importTimeEntries?.paginatorInfo || {};
          this.items = [...data?.importTimeEntries?.data] || [];
        })
        .catch((error) => {
          const togglTrackInvalidToken = error?.graphQLErrors?.find((error) => {
            return error.extensions.category === 'toggl_track_token_invalid';
          });

          if (togglTrackInvalidToken) {
            store.commit('logged_in_user', {
              ...store.getters.me,
              toggl_track_enabled: false,
            });
          }

          this.meta.errors = {};

          const togglTrackTooManyRequests = error?.graphQLErrors?.find((error) => {
            return error.extensions.category === 'toggl_track_too_many_requests';
          });

          if (togglTrackTooManyRequests) {
            this.meta.errors[togglTrackTooManyRequests.extensions.category] = {
              message: togglTrackTooManyRequests.message,
            };

            return;
          }

          const unauthenticated = error?.graphQLErrors?.find((error) => {
            return error.extensions.category === 'google calendar unauthorized';
          });

          if (!unauthenticated) {
            return;
          }

          this.items = [];
          this.paginator = {};

          this.meta.auth.url = unauthenticated.extensions.url;
        })
        .finally(() => {
          this.loading--;
        });
    },

    selectProvider(provider) {
      this.provider = provider;
      this.selected = {};
      this.page = 1;
      this.fetch();

      localStorage.setItem('tools.calendar.provider', provider);
    },

    toggleList(value) {
      for (const entry of this.items) {
        if (value) {
          if (!entry.timesheet && entry.projectId) {
            this.selected[entry.id] = true;
          }
        } else {
          delete this.selected[entry.id];
        }
      }

      this.$forceUpdate();
    },

    select(entry) {
      this.toggled(entry, true);
    },

    deselect(entry) {
      this.toggled(entry, false);
    },

    toggled(entry, value) {
      if (value) {
        this.selected[entry.id] = entry;
      } else {
        delete this.selected[entry.id];
      }

      this.$forceUpdate();
    },

    indeterminateList() {
      const count = Object.values(this.selected).length;
      return !!this.entriesNotImported && !!count && this.entriesNotImported.length !== count;
    },

    /**
     * @param {number} id
     * @param {{
     *   hours: number,
     *   projectId: string,
     *   description: string,
     *   fromHome: boolean,
     * }} data
     */
    updateLine(id, data) {
      const index = this.items.findIndex((item) => item.id === id);
      const entry = this.items[index];

      this.items[index] = { ...entry, ...data };

      // Trigger change detection.
      this.items = [...this.items];
    },

    run() {
      const timesheets = Object.keys(this.selected).map((id) => {
        const timesheet = this.items.find((i) => i.id === id);

        return {
          id: timesheet.id,
          input: {
            hours: Number.parseFloat(timesheet.hours),
            description: timesheet.description,
            projectId: timesheet.projectId,
            fromHome: timesheet.fromHome,
          },
        };
      });

      this.importing = true;

      this.$apollo
        .mutate({
          mutation: IMPORT_TIMESHEET_BATCH,

          variables: {
            timesheets,
          },
        })
        .then(async ({ data: { importTimesheetBatch: items } }) => {
          const successful = items.filter((i) => !!i.timesheet);
          const success = successful.length === timesheets.length;

          if (success) {
            this.$buefy.notification.open({
              message: 'Successfully imported timesheets',
              type: 'is-success',
            });
          } else {
            this.$buefy.notification.open({
              message: 'Some timesheets could not be imported',
              type: 'is-danger',
            });
          }

          this.selected = {};

          await this.fetch();
        })
        .finally(() => {
          this.importing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.columns {
  min-height: 100%;

  .column {
    padding: 1rem;
    width: 100%;
    min-height: 100%;
  }
}

.table-responsive {
  overflow-x: auto;
}

.layout-header {
  overflow: hidden;
}

::v-deep {
  .button {
    &:focus,
    &:hover {
      color: inherit;
    }
  }

  table {
    tr {
      &:hover {
        td {
          background: #1d293a;
        }
      }

      th {
        vertical-align: middle;
        padding: 2rem 1.5rem;
      }

      td {
        padding: 1.5rem;
      }

      td,
      th {
        text-overflow: ellipsis;
        white-space: nowrap;
        letter-spacing: 0.5px;

        &.check-column {
          width: 0;
          padding-left: 2rem;
          padding-right: 2rem;
        }

        &:first-child {
          position: sticky;
          left: 0;
          background: #182230;
          box-shadow: -1px 0px 0px 0px #243143 inset;
          z-index: 10;
        }

        &:nth-child(2) {
          padding-left: 2rem;
        }

        &:nth-last-child(2) {
          //padding-right: 2rem;
        }

        &:last-child {
          padding-right: 2rem;
        }

        .b-checkbox.checkbox .control-label {
          padding-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .dropdown.is-mobile-modal > .dropdown-menu {
      left: 50vw !important;
      top: 50vh !important;
    }
  }

  .dropdown {
    &.is-hoverable {
      &:hover {
        .dropdown-menu {
          display: block !important;
        }
      }
    }

    .dropdown-menu {
      .dropdown-content {
        border-radius: 4px;

        .has-link {
          a {
            padding: 0.375rem 1rem;
            font-weight: normal;
          }
        }
      }
    }
  }

  tr.group {
    td {
      border-bottom: 1px solid #243143;
      border-top: 1px solid #243143;
    }
  }

  tr.group,
  tr.group:hover {
    background: rgba(36, 49, 67, 0.3) !important;
  }
}

h1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.providers {
  display: flex;

  ::v-deep {
    .button {
      margin-left: 1rem;
      padding-left: 0.8rem;
      padding-right: 0.8rem;

      &:not(.is-selected) {
        opacity: 0.5;

        svg {
          filter: grayscale(1);
        }
      }

      &.is-selected {
        opacity: 1;
      }

      > span {
        display: flex;
        justify-content: center;
        align-content: center;
      }
    }
  }
}
</style>

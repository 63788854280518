var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bar",class:{
    'left-clipped': _vm.isLeftClipped,
    'right-clipped': _vm.isRightClipped,
    'info-right-clipped': _vm.isInfoRightClipped,
    'is-my-project': _vm.isMyProject,
  },style:({
    'margin-left': _vm.offset,
    width: _vm.width,
    'padding-right': _vm.paddingRight,
  })},[_c('div',{staticClass:"bar-internal",class:{ 'right-clipped': _vm.isInternalRightClipped },style:({ width: _vm.internalWidth })},[_c('div',{staticClass:"bar-content"},[(_vm.internalWidth != '0px')?_c('router-link',{attrs:{"to":{ name: 'view-project', params: { id: _vm.project.id } }}},[_vm._v(" "+_vm._s(_vm.project.display_code)+" ")]):_vm._e(),_c('div',{staticClass:"bar-info"},[_c('h4',[_vm._v(_vm._s(_vm.project.name))]),_c('table',[_c('tr',[_c('th',[_vm._v("Start Date")]),_c('td',[_vm._v(_vm._s(_vm._f("date")(_vm.project.start_date)))])]),_c('tr',[_c('th',[_vm._v("Internal Due Date")]),_c('td',[_vm._v(_vm._s(_vm._f("date")(_vm.project.internal_delivery_date)))])]),_c('tr',[_c('th',[_vm._v("External Due Date")]),_c('td',[_vm._v(_vm._s(_vm._f("date")(_vm.project.external_delivery_date)))])]),_c('tr',[_c('th',[_vm._v("Hours")]),_c('td',[_vm._v(" "+_vm._s(_vm.project.total_worked_hours)+"/"+_vm._s(_vm.project.total_available_hours)+" ("+_vm._s(_vm._f("percentage")((_vm.project.total_worked_hours / _vm.project.total_available_hours)))+") ")])])]),_vm._l((_vm.project.team_members),function(team_member){return _c('div',{key:team_member.id,staticClass:"team-member"},[_c('img',{attrs:{"src":team_member.user.avatar || '/images/avatar.svg',"alt":team_member.user.first_name + ' ' + team_member.user.last_name}}),_c('div',{staticClass:"team-member-name"},[_vm._v(" "+_vm._s(team_member.user.first_name)+" "+_vm._s(team_member.user.last_name)+" ")]),(team_member.worked_hours)?_c('b-progress',{attrs:{"value":team_member.worked_hours,"max":team_member.hours,"size":"is-medium","type":_vm.progress_type(team_member),"show-value":""}},[_vm._v(" "+_vm._s(team_member.worked_hours)+" / "+_vm._s(team_member.hours)+" ")]):_vm._e()],1)})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a @click="verify()">
    <slot>Approve</slot>
  </a>
</template>

<script>
import Modal from '@/components/Modal';
import { APPROVE_HOLIDAY_REQUEST } from '@/graphql/mutations/approve_holiday_request';
import { defineComponent, ref, onUnmounted } from '@vue/composition-api';
import { useMutation } from '@vue/apollo-composable';
import { useBuefy } from '@/composables/buefy';

export default defineComponent({
  name: 'ApproveHolidayRequestButton',
  props: {
    holidayRequest: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const buefy = useBuefy(context);
    const modal = ref();

    const { mutate: approveHolidayRequest } = useMutation(APPROVE_HOLIDAY_REQUEST);

    function verify() {
      modal.value = buefy.modal.open({
        component: Modal,
        width: 640,
        props: {
          message: 'The request cannot be edited after it has been approved.',
          confirmButton: 'Approve',
        },
        events: {
          handle: async () => {
            modal.value.close();

            await approveHolidayRequest({
              id: props.holidayRequest.id,
            });

            context.emit('done');

            buefy.notification.open({
              message: `Holiday request successfully approved`,
              type: 'is-success',
            });
          },
        },
      });
    }

    onUnmounted(() => {
      if (modal.value) {
        modal.value.close();
      }
    });

    return {
      verify,
    };
  },
});
</script>

<template>
  <b-field>
    <template v-slot:label>
      Clients
      <span class="reset" @click="reset">Reset</span>
    </template>

    <b-taginput
      v-model="selected"
      @input="input"
      type="is-dark"
      autocomplete
      :data="options"
      open-on-focus
      keep-first
      field="label"
      :loading="loading"
      @typing="query = $event"
    />
  </b-field>
</template>

<script>
import { GET_SEARCH_CLIENTS } from '@/graphql/queries/clients/search_clients';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      query: '',
      clients: [],
      loading: false,

      selected: [],
    };
  },

  mounted() {
    this.selected = this.value;
  },

  methods: {
    reset() {
      this.$emit('reset');
      this.selected = [];
    },

    input(value) {
      this.$emit('input', value);
    },
  },

  computed: {
    options() {
      return this.clients.filter(
        (client) => !this.selected.map(({ id }) => id).includes(client.id),
      );
    },
  },

  apollo: {
    clients: {
      query: GET_SEARCH_CLIENTS,

      variables() {
        return {
          search: this.query,
          limit: 15,
        };
      },
      fetchPolicy: 'cache-and-network',

      debounce: 250,

      loadingKey: 'loading',

      update({ search_clients: clients }) {
        return clients;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  color: #2b7eed;
  text-decoration: underline;
  cursor: pointer;
  float: right;

  &:hover {
    text-decoration: none;
  }
}
</style>

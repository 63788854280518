<template>
  <div class="login">
    <div class="login-wrapper">
      <img class="logo" src="/images/logo.svg" alt="DCC Tools Logo" />
      <h1 class="title">DCC Tools</h1>

      <google-login-button class="login-button" />

      <div class="link">
        <a href="https://dutchcodingcompany.com" target="_blank">Dutch Coding Company</a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import GoogleLoginButton from '@/components/GoogleLoginButton';

export default defineComponent({
  name: 'Login',
  components: {
    GoogleLoginButton,
  },
});
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10vh;

  .login-wrapper {
    width: 420px;
    max-width: 100%;
    padding: 1rem;
    text-align: center;
  }

  .logo {
    margin-bottom: 2rem;
    height: 144px;
  }

  .login-button {
    margin: 3em 0;
  }

  div.link {
    margin-top: 3rem;

    a {
      font-size: 0.8rem;
      color: #39485c;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
      }
    }
  }
}
</style>

<template>
  <ApolloQuery
    :query="GET_PROJECT_HOURS_DAILY_CHART"
    :variables="{ days: days, split: shouldSplit, userId: userId, projectId: projectId }"
  >
    <template slot-scope="{ result: { data }, isLoading }">
      <div class="loader full" v-if="isLoading"></div>
      <div v-else>
        <base-stat>
          <template #title>{{ title }}</template>
          <line-chart-stat
            :labels="data.chartProjectHoursDaily.labels"
            :datasets="data.chartProjectHoursDaily.datasets"
          />
        </base-stat>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import BaseStat from '@/components/stats/BaseStat';
import LineChartStat from '@/components/stats/LineChartStat';
import { GET_PROJECT_HOURS_DAILY_CHART } from '@/graphql/queries/dashboard/project_hours_daily_chart';
import { Permission } from '@/enums/Permission';

export default {
  name: 'ProjectHoursDailyChart',
  components: {
    BaseStat,
    LineChartStat,
  },
  props: {
    title: String,
    days: Number,
    split: Boolean,
    userId: String,
    projectId: Number,
  },
  data() {
    return {
      GET_PROJECT_HOURS_DAILY_CHART,
    };
  },
  computed: {
    shouldSplit() {
      return this.canViewPlanning() && this.split;
    },
  },
  methods: {
    canViewPlanning() {
      return this.$store.getters.havePermission(Permission.VIEW_PLANNING);
    },
  },
};
</script>

<style lang="scss" scoped></style>

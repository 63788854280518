<template>
  <div class="wrapper">
    <section class="search">
      <input
        v-model="query"
        autocomplete="off"
        class="input search-input"
        @keyup="typing"
        ref="query"
      />
    </section>
    <section class="results">
      <transition-group name="search-list" @enter="enter" @leave="leave">
        <div
          class="item search-list-item"
          v-for="(result, index) in search"
          :key="result.id"
          :data-index="index"
          :class="{ selected: index === selected_index }"
          @mouseover="selected_index = index"
          @click="go()"
        >
          <div class="item-icon">
            <svg
              v-if="result.obj_type === 'project'"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
              ></path>
            </svg>
            <svg
              v-if="result.obj_type === 'client'"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              ></path>
            </svg>
            <svg
              v-if="result.obj_type === 'user'"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              ></path>
            </svg>
          </div>
          <div class="item-title">
            {{ result.title }}
          </div>
          <div class="item-subtitle">
            {{ result.subtitle }}
          </div>
        </div>
      </transition-group>
      <div class="has-text-centered">
        <div
          v-if="query.length && search.length === 0 && $apollo.queries.search.loading"
          class="loader inline"
        ></div>
        <transition name="no-results">
          <div class="no-results" v-if="query.length && search.length === 0 && !searching">
            No results
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<script>
import { GET_SEARCH } from '@/graphql/queries/search';

export default {
  name: 'SearchModal',
  data() {
    return {
      query: '',
      search: [],
      selected_index: 0,
      searching: false,
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.$refs.query.focus();
    });
  },
  computed: {},
  methods: {
    typing(event) {
      this.searching = true;

      if (this.query === '') {
        this.search = [];
      }

      if (event.keyCode === 38) {
        // Trigger search on arrow up
        this.select(-1);
      } else if (event.keyCode === 40) {
        // Trigger search on arrow down
        this.select(1);
      } else if (event.keyCode === 13) {
        // Trigger search on enter
        this.go();
      } else {
        this.selected_index = 0;
      }
    },
    select(direction) {
      this.selected_index =
        (this.selected_index + direction + this.search.length) % this.search.length;
    },
    go() {
      if (this.search[this.selected_index]) {
        this.$router.push(this.search[this.selected_index].route);
        this.$emit('close');
      }
    },
    addRoute(result) {
      let name;
      if (result.obj_type === 'user') {
        name = 'view-user';
      } else if (result.obj_type === 'project') {
        name = 'view-project';
      } else if (result.obj_type === 'client') {
        name = 'view-client';
      } else if (result.obj_type === 'timesheet') {
        name = 'view-timesheet';
      }

      result.route = {
        name: name,
        params: {
          id: result.obj_id,
        },
      };

      return result;
    },
    enter: function(el) {
      const delay = ((el.dataset.index * 50) / 1000).toFixed(2) + 's';
      el.style.transitionDelay = delay + ', ' + delay + ', 0s';
    },
    leave: function(el) {
      el.style.transitionDelay = '0s';
    },
  },
  apollo: {
    search: {
      query: GET_SEARCH,
      variables() {
        return {
          query: this.query,
          limit: 5,
        };
      },
      update: function({ search }) {
        this.searching = false;
        return search.map(this.addRoute);
      },
      skip() {
        return this.query.length === 0;
      },
      debounce: 100,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.search {
  width: 600px;
  max-width: 100%;
  height: 80px;
  padding: 0 1rem;
  margin-bottom: 1rem;

  input.search-input {
    font-size: 2rem;
    padding: 0 2rem;
    border-radius: 100px;
  }
}

.results {
  height: 480px;
  width: 600px;
  max-width: 100%;
  padding: 0 2rem;
  overflow-y: auto;

  .item {
    background: #243143;
    border-radius: 16px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 60px auto;
    grid-template-rows: auto auto;
    column-gap: 1rem;

    &.selected {
      background-color: #2b7eed;
    }

    &-title {
      grid-column: 2 / 2;
      grid-row: 1 / 1;
      font-weight: bold;
      font-size: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-subtitle {
      grid-column: 2 / 2;
      grid-row: 2 / 2;
      color: rgba(255, 255, 255, 0.675);
      font-size: 0.9rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      grid-column: 1 / 1;
      grid-row: 1 / 3;
      align-self: center;
      justify-self: center;

      svg {
        width: 40px;
        height: 40px;
        display: block;
        opacity: 0.8;
      }
    }

    &:last {
      margin-bottom: 0;
    }
  }
}

/* Search list animation */
.search-list-item {
  transition: opacity 0.2s, transform 0.2s, background 0.2s;
  transition-delay: 0s, 0s, 0s;
  display: block;
}

.search-list-enter,
.search-list-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.search-list-leave-active {
  transition: all 0.2s;
  position: absolute;
  width: calc(600px - 4rem);
}

.no-results {
  transition: all 0s 0s;
}
.no-results-enter-active {
  transition: all 0.5s 0.3s;
}
.no-results-enter-to {
  opacity: 1;
  transform: none;
}

.no-results-enter,
.no-results-leave-to {
  opacity: 0;
  transform: translateY(5px);
}
</style>

<template>
  <div class="layout has-sidebar">
    <div class="layout-header">
      <h1>Timesheets</h1>
    </div>
    <div class="layout-content">
      <div class="columns">
        <ApolloQuery
          :query="GET_TIMESHEETS"
          :variables="variables"
          class="column is-narrow is-max-full is-full"
        >
          <template slot-scope="{ result: { data }, isLoading, query }">
            <div class="sticky-column-wrapper" v-if="data && data.timesheets">
              <div class="sticky-column-scroller">
                <table class="sticky-column-table data-table">
                  <thead>
                    <th v-if="canSeeOtherTeamMembers">Team member</th>
                    <th>Project</th>
                    <OrderColumn
                      :order.sync="order"
                      @update:order="query.refetch()"
                      column-name="DESCRIPTION"
                    >
                      Description
                    </OrderColumn>
                    <OrderColumn
                      :order.sync="order"
                      @update:order="query.refetch()"
                      column-name="DATE"
                    >
                      Date
                    </OrderColumn>
                    <OrderColumn
                      :order.sync="order"
                      @update:order="query.refetch()"
                      column-name="HOURS"
                    >
                      Hours
                    </OrderColumn>
                    <OrderColumn
                      :order.sync="order"
                      @update:order="query.refetch()"
                      column-name="EDITABLE"
                    >
                      Editable
                    </OrderColumn>
                    <OrderColumn
                      :order.sync="order"
                      @update:order="query.refetch()"
                      column-name="FROM_HOME"
                    >
                      From home
                    </OrderColumn>
                    <th class="has-text-right">
                      <div class="loader inline" v-if="isLoading" />
                    </th>
                  </thead>
                  <tr v-for="timesheet of data.timesheets.data" :key="timesheet.id">
                    <td v-if="canSeeOtherTeamMembers">
                      <router-link
                        :to="{
                          name: 'view-user',
                          params: { id: timesheet.participation.user.id },
                        }"
                        class="font-medium hover:text-blue"
                      >
                        {{ timesheet.participation.user.first_name }}
                        {{ timesheet.participation.user.last_name }}
                      </router-link>
                    </td>
                    <td class="column-max-width">
                      <router-link
                        :to="{
                          name: 'view-project',
                          params: { id: timesheet.participation.project.id },
                        }"
                        class="font-medium hover:text-blue"
                      >
                        <span
                          :title="
                            timesheet.participation.project.display_code +
                              ': ' +
                              timesheet.participation.project.name
                          "
                        >
                          <strong>{{ timesheet.participation.project.display_code }}</strong>
                          {{ timesheet.participation.project.name }}
                        </span>
                      </router-link>
                    </td>
                    <td class="column-max-width">
                      <span :title="timesheet.description">{{ timesheet.description }}</span>
                    </td>
                    <td>{{ timesheet.date | dateShort }}</td>
                    <td>{{ timesheet.hours | hours(2) }}</td>
                    <td>
                      <boolean :value="timesheet.editable" />
                    </td>
                    <td>
                      <boolean :value="timesheet.fromHome" />
                    </td>
                    <td class="sticky-column">
                      <router-link
                        :to="{
                          name: 'view-timesheet',
                          params: { id: timesheet.id },
                        }"
                      >
                        <open class="icon icon-inline" />
                      </router-link>
                      <router-link
                        v-if="timesheet.editable"
                        :to="{
                          name: 'edit-timesheet',
                          params: { id: timesheet.id },
                        }"
                      >
                        <edit class="icon icon-inline" />
                      </router-link>
                      <delete-timesheet-button
                        v-if="timesheet.editable"
                        :timesheet="timesheet"
                        @done="query.refresh()"
                      >
                        <delete-icon class="icon icon-inline" />
                      </delete-timesheet-button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <paginator
              v-if="data && data.timesheets"
              :pageId.sync="pageId"
              :lastPage="data.timesheets.paginatorInfo.lastPage"
            />
          </template>
        </ApolloQuery>
      </div>
    </div>
    <div class="layout-sidebar">
      <ul>
        <li>
          <router-link class="button is-primary" :to="{ name: 'new-timesheet' }">
            Create
          </router-link>
        </li>
        <li>
          <timesheet-filters
            :can-see-other-team-members="canSeeOtherTeamMembers"
            @update:whereHasProject="(value) => (whereHasProject = value)"
            @update:whereHas="(value) => (whereHas = value)"
            @update:where="(value) => (where = value)"
            @update:activeFilters="(value) => (activeFilters = value)"
          />
        </li>
        <li class="break">
          <exporter :active-filters="activeFilters" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Paginator from '@/components/Paginator.vue';
import Boolean from '@/components/Boolean.vue';
import OrderColumn from '@/components/OrderColumn.vue';
import TimesheetFilters from './timesheet-filters.modal.vue';
import Open from '@/assets/icons/view.svg';
import Edit from '@/assets/icons/edit.svg';
import DeleteIcon from '@/assets/icons/delete.svg';
import DeleteTimesheetButton from '@/components/DeleteTimesheetButton';
import Exporter from '@/components/TimesheetExport.vue';
import { GET_TIMESHEETS } from '@/graphql/queries/timesheets';

export default {
  name: 'Timesheets',
  components: {
    Paginator,
    Boolean,
    OrderColumn,
    TimesheetFilters,
    Open,
    Edit,
    DeleteTimesheetButton,
    DeleteIcon,
    Exporter,
  },
  data() {
    return {
      pageId: 1,
      activeFilters: {},
      where: {},
      whereHas: null,
      whereHasProject: null,
      order: { column: 'DATE', order: 'DESC' },
      GET_TIMESHEETS,
    };
  },
  computed: {
    variables() {
      return {
        userId: !this.$route.query.showOtherTeamMembers && this.me ? this.me.id : null,
        page: this.pageId,
        where: this.where,
        whereHas: this.whereHas,
        whereHasProject: this.whereHasProject,
        orderBy: this.order,
      };
    },
    canSeeOtherTeamMembers() {
      return (
        this.$route.query.showOtherTeamMembers &&
        this.$store.getters.havePermission('manage projects')
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.column-max-width {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sticky-column-wrapper {
  position: relative;
}

.sticky-column-scroller {
  margin-right: 90px;
  overflow: auto;
  width: auto;
}

.sticky-column-table {
  width: 100%;

  .sticky-column {
    border-left: 1px solid #243143;
    right: 0;
    position: absolute;
    top: auto;
    width: 90px;
    padding: 0 0.2rem;
    display: flex;

    > a {
      padding: 0.8rem 0;
      display: block;
    }
  }
}
</style>

<template>
  <a @click="verify()">
    <slot>Delete</slot>
  </a>
</template>

<script>
import DeleteModal from '@/components/DeleteModal';
import { DELETE_PROJECT } from '@/graphql/mutations/projects/delete_project';

import { defineComponent, ref, onUnmounted } from '@vue/composition-api';
import { useMutation } from '@vue/apollo-composable';
import { useBuefy } from '@/composables/buefy';

export default defineComponent({
  name: 'DeleteTimesheetButton',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const buefy = useBuefy(context);
    const modal = ref();

    const { mutate: deleteProject } = useMutation(DELETE_PROJECT);

    function verify() {
      modal.value = buefy.modal.open({
        component: DeleteModal,
        width: 640,
        events: {
          delete: async () => {
            modal.value.close();

            await deleteProject({
              id: props.project.id,
            });

            context.emit('done');

            buefy.notification.open({
              message: 'Project successfully deleted',
              type: 'is-success',
            });
          },
        },
      });
    }

    onUnmounted(() => {
      if (modal.value) {
        modal.value.close();
      }
    });

    return {
      verify,
    };
  },
});
</script>

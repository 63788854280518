<template>
  <div>
    <client-filter v-model="clients" @reset="clients = []" />

    <b-field>
      <template slot="label">
        Project owner
        <span class="reset" @click="selected_owners = []">Reset</span>
      </template>

      <b-taginput
        v-model="selected_owners"
        type="is-dark"
        :data="filtered_owners"
        autocomplete
        open-on-focus
        keep-first
        field="first_name"
        :loading="$apollo.queries.owners.loading"
        @typing="getFilteredOwners"
      />
    </b-field>

    <b-field v-if="canManageProjects()">
      <template slot="label">
        Team Members
        <span class="reset" @click="users = []">Reset</span>
      </template>

      <b-taginput
        v-model="users"
        type="is-dark"
        :data="filtered_users"
        autocomplete
        open-on-focus
        keep-first
        field="first_name"
        :loading="$apollo.queries.all_users.loading"
        @typing="getFilteredUsers"
      />
    </b-field>

    <b-field>
      <template slot="label">
        Lead Sources
        <span class="reset" @click="selected_lead_sources = []">Reset</span>
      </template>

      <b-taginput
        v-model="selected_lead_sources"
        type="is-dark"
        :data="filtered_lead_sources"
        autocomplete
        open-on-focus
        keep-first
        field="type"
        :loading="$apollo.queries.lead_sources.loading"
        @typing="getFilteredLeadSources"
      />
    </b-field>

    <b-field>
      <template slot="label">
        Categories
        <span class="reset" @click="selected_categories = []">Reset</span>
      </template>

      <b-taginput
        v-model="selected_categories"
        type="is-dark"
        :data="filtered_categories"
        autocomplete
        open-on-focus
        keep-first
        field="title"
        :loading="$apollo.queries.project_categories.loading"
        @typing="getFilteredCategories"
      />
    </b-field>

    <b-field>
      <template slot="label">
        Date range
        <span class="reset" @click="dates = null">Reset</span>
      </template>

      <b-datepicker v-model="dates" position="is-bottom-left" range />
    </b-field>

    <project-status-filter v-model="statuses" @reset="statuses = []" />
  </div>
</template>

<script>
import { format } from 'date-fns';
import ClientFilter from '@/components/ClientFilter';
import ProjectStatusFilter from '@/components/ProjectStatusFilter';
import ProjectStatus from '@/enums/ProjectStatus';
import { GET_ALL_USERS } from '@/graphql/queries/all_users';
import { GET_LEAD_SOURCES } from '@/graphql/queries/lead_sources';
import { GET_OWNERS } from '@/graphql/queries/owners';
import { GET_PROJECT_CATEGORIES } from '@/graphql/queries/project_categories';

export default {
  name: 'ProjectFilters',

  components: {
    ClientFilter,
    ProjectStatusFilter,
  },

  data() {
    return {
      dates: null,

      users: [],
      all_users: [],
      filtered_users: [],

      clients: [],
      statuses: [ProjectStatus.IN_PROGRESS],

      owners: [],
      selected_owners: [],
      filtered_owners: [],

      lead_sources: [],
      selected_lead_sources: [],
      filtered_lead_sources: [],

      project_categories: [],
      selected_categories: [],
      filtered_categories: [],
    };
  },

  computed: {
    where() {
      let filters = [];

      if (this.dates && this.dates[0]) {
        filters.push({
          column: 'EXTERNAL_DELIVERY_DATE',
          operator: 'GTE',
          value: format(this.dates[0], 'yyyy-MM-dd'),
        });
      }

      if (this.dates && this.dates[1]) {
        filters.push({
          column: 'START_DATE',
          operator: 'LT',
          value: format(this.dates[1], 'yyyy-MM-dd'),
        });
      }

      if (this.clients && this.clients.length) {
        filters.push({
          column: 'CLIENT_ID',
          operator: 'IN',
          value: this.clients.map((client) => client.id),
        });
      }

      if (this.selected_owners && this.selected_owners.length) {
        filters.push({
          column: 'OWNER_ID',
          operator: 'IN',
          value: this.selected_owners.map((owner) => owner.id),
        });
      }

      if (this.selected_lead_sources && this.selected_lead_sources.length) {
        filters.push({
          column: 'LEAD_SOURCE_ID',
          operator: 'IN',
          value: this.selected_lead_sources.map((lead_source) => lead_source.id),
        });
      }

      if (this.selected_categories && this.selected_categories.length) {
        filters.push({
          column: 'CATEGORY_ID',
          operator: 'IN',
          value: this.selected_categories.map((category) => category.id),
        });
      }

      if (this.statuses.length) {
        filters.push({
          column: 'STATUS',
          operator: 'IN',
          value: this.statuses.map(({ value }) => value),
        });
      }

      return { AND: filters };
    },
    whereHas() {
      if (this.users.length == 0) {
        return null;
      }

      return {
        AND: [
          {
            column: 'USER_ID',
            operator: 'IN',
            value: this.users.map((user) => user.id),
          },
        ],
      };
    },
  },
  watch: {
    where() {
      this.$emit('update:where', this.where);
    },
    whereHas() {
      this.$emit('update:whereHas', this.whereHas);
    },
  },
  methods: {
    canManageProjects() {
      return this.$store.getters.havePermission('manage projects');
    },
    getFilteredOwners(text) {
      // First all items that start with the search text, then all
      // at another place

      text = text.toLowerCase();
      let startMatch = this.owners.filter((option) => {
        return (
          option.first_name
            .toString()
            .toLowerCase()
            .indexOf(text) === 0 ||
          option.last_name
            .toString()
            .toLowerCase()
            .indexOf(text) === 0
        );
      });

      let middleMatch = this.owners.filter((option) => {
        return (
          (option.first_name + ' ' + option.last_name)
            .toString()
            .toLowerCase()
            .indexOf(text) >= 0
        );
      });

      this.filtered_owners = this.unique(startMatch.concat(middleMatch));
    },
    getFilteredUsers(text) {
      // First all items that start with the search text, then all
      // items that match at another place

      text = text.toLowerCase();
      let startMatch = this.all_users.filter((option) => {
        return (
          option.first_name
            .toString()
            .toLowerCase()
            .indexOf(text) === 0
        );
      });

      let middleMatch = this.all_users.filter((option) => {
        return (
          option.first_name
            .toString()
            .toLowerCase()
            .indexOf(text) > 0
        );
      });

      this.filtered_users = this.unique(startMatch.concat(middleMatch));
    },
    getFilteredLeadSources(text) {
      this.filtered_lead_sources = this.lead_sources.filter((option) => {
        return (
          option.type
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    getFilteredCategories(text) {
      // First all categories where name starts with string, then middle match

      text = text.toLowerCase();
      let startMatch = this.project_categories.filter((option) => {
        return (
          option.title
            .toString()
            .toLowerCase()
            .indexOf(text) === 0
        );
      });

      let middleMatch = this.project_categories.filter((option) => {
        return (
          option.title
            .toString()
            .toLowerCase()
            .indexOf(text) > 0
        );
      });

      this.filtered_categories = this.unique(startMatch.concat(middleMatch));
    },
    unique(arr) {
      // Remove duplicates based on id
      let flags = {};
      return arr.filter(function(entry) {
        if (flags[entry.id]) {
          return false;
        }

        flags[entry.id] = true;
        return true;
      });
    },
  },
  apollo: {
    all_users: {
      query: GET_ALL_USERS,
      update(data) {
        return data.all_users.sort((a, b) => (a.first_name < b.first_name ? -1 : 1));
      },
    },
    lead_sources: {
      query: GET_LEAD_SOURCES,
    },
    owners: {
      query: GET_OWNERS,
    },
    project_categories: {
      query: GET_PROJECT_CATEGORIES,
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  color: #2b7eed;
  text-decoration: underline;
  cursor: pointer;
  float: right;

  &:hover {
    text-decoration: none;
  }
}
</style>

<template>
  <status
    :value="value"
    :options="[
      { value: true, color: '#E3342F', label: 'Blocked' },
      { value: false, color: '#38C172', label: 'Active' },
    ]"
  />
</template>

<script>
import Status from '@/components/Status';

export default {
  components: {
    Status,
  },

  props: {
    value: {
      required: true,
    },
  },
};
</script>

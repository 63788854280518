<template>
  <div class="layout has-sidebar">
    <div class="layout-header">
      <h1>Metrics</h1>
    </div>
    <div class="layout-content">
      <span>
        <line-chart-stat
          :labels="data.chartProjectHoursDaily.labels"
          :datasets="data.chartProjectHoursDaily.datasets"
        />
      </span>
    </div>
    <div class="layout-sidebar">
      <div class="dashboard-slider">
        <b-field label="Chart interval">
          <b-radio-button v-model="chartInterval" native-value="daily" type="is-primary is-light">
            <span>Daily</span>
          </b-radio-button>
          <b-radio-button v-model="chartInterval" native-value="weekly" type="is-primary is-light">
            <span>Weekly</span>
          </b-radio-button>
          <b-radio-button v-model="chartInterval" native-value="monthly" type="is-primary is-light">
            <span>Monthly</span>
          </b-radio-button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import LineChartStat from '@/components/stats/LineChartStat';
import { GET_ACTIVE_PROJECTS } from '@/graphql/queries/dashboard/active_projects';

export default {
  name: 'MetricsDashboard',
  components: {
    LineChartStat,
  },
  data() {
    return {
      chartInterval: 'weekly',
    };
  },
  computed: {
    userId() {
      return this.$store.getters.me.id;
    },
  },
  methods: {
    isAdmin() {
      return this.$store.getters.me.is_admin;
    },
  },
  apollo: {
    metrics: {
      query: GET_ACTIVE_PROJECTS,
      result({ data }) {
        this.active_projects = data.me.participations;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-content {
  display: grid;
  grid-template-areas: 'graph-left graph-left' 'graph-right graph-right';
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  max-width: 100%;

  @media (min-width: 1023px) {
    grid-template-areas: 'graph-left graph-right';
  }
  // This should be temporary
  // TODO: change to grid layout when real data is available
  .graph {
    &-left {
      min-width: 0;
      width: 100%;
      grid-area: graph-left;
    }
    &-right {
      min-width: 0;
      width: 100%;
      grid-area: graph-right;
    }
  }
  .active-projects {
    min-width: 0;
    width: 100%;
    grid-area: active-projects;
  }
}
.layout-sidebar {
  .dashboard-slider {
    grid-area: dashboard-slider;
    margin: 0 0 2rem 0;
    align-self: center;

    .b-slider {
      margin: 0;
    }

    @media (min-width: 1023px) {
      font-size: 4rem;
      line-height: 3.5rem;
    }
  }
}

.has-margin-top {
  margin-top: 2rem;
}
</style>

<template>
  <div id="app" :class="{ collapsed: collapsed, login: !this.isAuthenticated() }">
    <div id="nav" v-if="this.isAuthenticated() && !this.$apollo.queries.me.loading">
      <navigation @show-search="showSearch()"></navigation>
    </div>
    <main id="main">
      <transition name="slide-fade">
        <router-view v-if="!this.$apollo.queries.me.loading"></router-view>
      </transition>
    </main>

    <div
      id="menu-toggle"
      v-if="this.isAuthenticated() && !this.$apollo.queries.me.loading"
      @click="collapsed = !collapsed"
    >
      <img class="logo" src="/images/logo.svg" alt="DCC Tools Logo" />
      <div>Tools</div>
      <svg
        class="hamburger"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </div>

    <transition name="intro-fade-out" :duration="400">
      <div v-if="this.$apollo.queries.me.loading" id="intro">
        <div class="intro-spinner">
          <img class="intro-logo" src="/images/logo.svg" alt="DCC Tools is loading" />
        </div>
      </div>
    </transition>

    <div class="notices is-bottom">
      <b-notification
        type="is-danger"
        :active.sync="isOffline"
        :closable="false"
        position="is-bottom-right"
      >
        Seems like we lost connection. Check your internet. Changes won't be saved!
      </b-notification>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import SearchModal from '@/components/SearchModal.vue';
import { isAuthenticated } from '@/vue-apollo.js';
import { GET_ME } from '@/graphql/queries/me.ts';

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      collapsed: true,
      isOffline: false,
    };
  },

  async mounted() {
    this.networkChanged();
    window.addEventListener('online', this.networkChanged.bind(this));
    window.addEventListener('offline', this.networkChanged.bind(this));
    window.addEventListener('keydown', this.keyTrigger);

    if (
      !this.isAuthenticated() &&
      this.$route.name !== 'login' &&
      this.$route.name !== 'authorize'
    ) {
      this.$router.push({ name: 'login' });
    }
  },

  methods: {
    keyTrigger(event) {
      // Ignore typing in inputs, textarea etc.
      if (/^(?:input|textarea|select|button)$/i.test(event.target.tagName)) {
        return;
      }

      // Trigger the search on '/.
      if (event.code === 'Slash') {
        event.preventDefault();
        this.showSearch();
      }
    },
    showSearch() {
      this.$buefy.modal.open({
        parent: this,
        component: SearchModal,
        trapFocus: true,
      });
    },
    isAuthenticated() {
      return isAuthenticated();
    },
    networkChanged() {
      this.isOffline = !navigator.onLine;
    },
  },
  apollo: {
    me: {
      query: GET_ME,
      result({ data }) {
        this.$store.commit('logged_in_user', data.me);
      },
      skip() {
        return !this.isAuthenticated() || this.$route.name === 'login';
      },
    },
  },
  watch: {
    $route() {
      this.collapsed = true;
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;

  #nav {
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #main {
    min-height: 100%;
    overflow: hidden;
  }

  @media (max-width: 769px) {
    #nav {
      width: 100%;
      height: 100%;
      padding-top: 60px;
      transition: transform 0.2s;
      transform: translateX(0%);
    }

    #main {
      width: 100%;
      padding-top: 60px;
      transition: transform 0.2s;
      transform: translateX(100%);
    }

    #menu-toggle {
      width: 100%;
      height: 60px;
      padding: 0 1rem;
      position: fixed;
      top: 0;
      left: 0;
      background: #243143;
      user-select: none;

      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .logo {
        height: 48px;
        margin-right: 1rem;
        flex-grow: 0;
      }

      .hamburger {
        height: 36px;
        flex-grow: 0;
      }

      div {
        font-size: 1.5rem;
        flex-grow: 1;
      }
    }

    &.collapsed {
      #nav {
        transform: translateX(-100%);
      }

      #main {
        transform: translateX(0);
      }
    }
  }

  @media (min-width: 770px) {
    #nav {
      width: 250px;
    }

    #main {
      width: 100%;
      padding-left: 250px;
    }

    #menu-toggle {
      display: none;
    }
  }

  #intro {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: #182230;
    display: flex;
    justify-content: center;
    align-items: center;

    .intro {
      $spinner-size: 120px;
      $spinner-max-size: 80vw;

      &-spinner {
        width: $spinner-size;
        height: $spinner-size;
        max-width: $spinner-max-size;
        max-height: $spinner-max-size;
        position: relative;

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border: 2px solid #243143;
          border-top-color: rgba(255, 255, 255, 0);
          border-radius: 100%;
          animation: intro-spinner-rotate 0.6s linear infinite;
        }

        @keyframes intro-spinner-rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }

      &-logo {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center;
        padding: 20%;
      }
    }
  }

  &.login {
    #main {
      padding-left: 0;
    }
  }
}

.intro-fade-out-leave-active {
  transition: opacity 0.2s 0.2s;
}

.intro-fade-out-leave-to {
  opacity: 0;
}
</style>
